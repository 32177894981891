import React,{useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {slice, concat} from 'lodash';

import './yt-bannersection.scss'
import YtLogo from '../../icons/yootrends.svg'
import BannerAdvert from '../../icons/banner2.jpg'
import axios from 'axios'
import $ from 'jquery'
import API_BASE from '../../apiBase';
import GoogleAds from '../googleads/googleads'

const YtBanner = () => {
    const [ ShowWords, setShowWords ] = useState( false );
    const [tow, setTow] = useState([])
    const [showAllMonths, setShowAllMonths] = useState(false)
    const [archiveArray, setArchiveArray] = useState([])
    const [trendByMonth, setTrendByMonth] = useState([])
    const [currentVisible, setCurrentVisible] = useState('')
    const [pageNumber, setPageNumber] = useState(1)
    const [numberOfPages,setNumberOfPages] = useState(1)
    const [loadMoreMonths, setLoadMoreMonths] = useState(true)
    const [list,setList] = useState(slice(archiveArray, 0, 5));
    const [index,setIndex] = useState(0);
    const [archiveLoaded, setArchiveLoaded] = useState(true)

    const getTrendByMonth = (monthName, elementId) =>{
        if (elementId !== currentVisible){
            setTrendByMonth([])
        } else if (elementId === currentVisible){
            setTrendByMonth([])
        }
        axios.get(`${API_BASE}/trends/archives/?month=${monthName}&page=${pageNumber}`)
        .then(response => {
            if (response.data.pages > 1) {
                setShowAllMonths(true)
                setNumberOfPages(response.data.pages)
            }
            setTrendByMonth(trendByMonth => trendByMonth.concat(response.data.trends))
        }).catch(err => console.log(err))
    }

    const toggleShowWords = () =>
    {
        setShowWords( prevState => !prevState );
    }; 

    const loadMore = () =>{
        if (pageNumber < numberOfPages){
            setPageNumber(prevState => prevState + 1)
        } else {
            setShowAllMonths(false)
        }
      }
    
    const showMoreMonths = () =>{
        const newIndex = index + 2;
        const newShowMore = index < (archiveArray.length - index);
        const newList = concat(list, slice(archiveArray, index, newIndex));
        setIndex(newIndex);
        setList(newList);
        // console.log(archiveArray)
        setLoadMoreMonths(newShowMore);
    }
  
    useEffect(() => {
        axios.get(`${API_BASE}/trendOfTheWeek`)
        .then(response => {
            const responseData = response.data
            setTow(responseData.trend)
        }).catch(err => console.log(err))

        axios.get(`${API_BASE}/trends/archivesMeta`)
        .then(response => {
            setArchiveArray(response.data.archives)
        }).catch(err => console.log(err))
    }, [])

    useEffect(() => {
        if (archiveArray.length !== 0 && archiveLoaded){
            showMoreMonths()
            setArchiveLoaded(false)
        } else return 
    }, [archiveArray])

    const showWord = (elementId, monthName) => {
        if (currentVisible !== elementId){
            $(document).ready(function(e) {
                if (document.getElementById(`${currentVisible}lvl`) === null){
                    setCurrentVisible(elementId)
                } else if (document.getElementById(`${currentVisible}lvl`).style.display === 'block'){
                    document.getElementById(`${currentVisible}lvl`).style.display = 'none';
                    setTrendByMonth([])
                }
            })
        }
        getTrendByMonth(monthName, elementId)
        $(document).ready(function(e) {
            const display = document.getElementById(`${elementId}lvl`).style.display;
            if (display === 'none' || display === null || !display){
                document.getElementById(`${elementId}lvl`).style.display = 'block';
            } else if (display === 'block'){
                document.getElementById(`${elementId}lvl`).style.display = 'none';
            }
            setCurrentVisible(elementId)
        })
    }

    return (
        <>
            <div className="yootrends-logo">
                <img className="ytlogo" alt="logo" src={YtLogo} />
                <div className="ytlogo-caption">Keep up with new trends</div>
            </div>
            <div className="inner-flex">
            <div className="t-o-w">
                <div className="tow-header">Trend of the week</div>
                <div className="tow-word"><Link to={tow ? `/yootrends/trend/${tow.slug}` : '/yootrends'}>{tow ? tow.title : ('Null')}</Link></div>
                {/* <div className="yt-definition-title">Definition</div>
                <div className="yt-definition-def">{tow ? tow.title : ('Null')}</div> */}
            </div>
            <div className="yt-monthly">
                <div className="yt-monthly-header">Trends by month</div>
            {
                list.map((date, index) => 
                    <>
                    <div className="yt-month" key={index}>
                        <div className="yt-month-head" onClick={toggleShowWords} style={ { color: ShowWords ? '#ffffff' : '#C6C6C6' } }>
                            <span className="yt-month-folder">
                                <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                    <path style={ { fill: ShowWords ? '#ffffff' : '#C6C6C6' } } d="M12.6137 1.43848H4.36066C4.105 1.43848 3.89774 1.18291 3.89774 0.867642V0.582012C3.89774 0.260582 3.68642 0 3.42576 0H0.943965C0.422637 0 0 0.521125 0 1.16402V10.9585L1.21119 3.69223C1.25544 3.42679 1.44489 3.23631 1.66464 3.23631H13.5577V2.6025C13.5577 1.9596 13.135 1.43848 12.6137 1.43848Z" fill="#C6C6C6"/>
                                    <path style={ { fill: ShowWords ? '#ffffff' : '#C6C6C6' } } d="M15.0555 4.37891H2.04125L0.219725 15.3065C0.159973 15.665 0.382042 16.0009 0.678894 16.0009H14.2217C14.7029 16.0009 15.107 15.5546 15.1599 14.9649L15.9938 5.67095C16.0556 4.98157 15.618 4.37891 15.0555 4.37891Z" fill="#C6C6C6"/>
                                </svg> 
                            </span>
                                <span className="yt-month-header" id={`${date}${index}`} onClick={() => showWord(`${date}${index}`, date)}>{date}</span>
                                <div className="yt-month-words" id={`${date}${index}lvl`} >
                                        {
                                            trendByMonth.map((trend) => 
                                            <div className="yt-month-ellipse" key={trend.slug}>
                                                <svg className="ellipse-svg" width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="4.5" cy="4.5" r="4.5" fill="white"/>
                                                </svg>
                                                <span className="yt-month-word"><Link to={`/yootrends/trend/${trend.slug}`}>{trend.title}</Link></span>
                                            </div>
                                            )
                                        }
                                        {showAllMonths && <button className="loadmore-buttton" onClick={loadMore}> Load More </button>}
                                </div>
                            </div>
                            </div>
                        </>
                    )
                }
                {loadMoreMonths && <button className="loadmore-buttton" onClick={showMoreMonths}> Load More </button>}
                </div>
                <div className="">
                    <img src={BannerAdvert} alt="advert banner" className="advert-space" />
                    <GoogleAds/>
                </div>
                <div className="banner-footer">
                    < Link to="/termsandconditions">Terms and Conditions</Link> | <Link to="/help">Help</Link> | <Link to="/privacypolicy">Privacy Policy</Link>
                </div>
            </div>
        </>
    )
}

export default YtBanner