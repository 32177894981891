import React, { useState,useEffect, useCallback } from 'react'
import Table from 'react-bootstrap/Table'
import './adminintbar.scss'
import {ViewMoreButton} from '../../pages/adminpage/button' 
import './wordupdate.scss'
import Pagination from "./pagenation"
import TrendUpdateModal from './trendsmodal'
import Modal from 'react-bootstrap/Modal'
import './pagenation.scss'

const TrendPageContent = ({ isLoading,  fetchData, numberOfPages, setFetchPageNumber, contentHeaderArray, contentObject, pageNumber, goToPrevious, goToNext  }) => {
    const [trendModal, setTrendModal] = useState(false)
    const [newTrendModal, setNewTrendModal] = useState(false)
    const [readOnly, setReadOnly] = useState(true)

    let total = numberOfPages;
    const [active, setActive] = useState(1);
    const [pages, setPages] = useState([]);

    useEffect(() => {
        if (total <= 7) {
        let arr = [];
        for (let i = 1; i <= total; i++) {
            arr.push(i);
        }
        setPages(arr);
        return;
        }
        if (active <= 4) {
        setPages([1, 2, 3, 4, 5, "...", total]);
        }
        if (active > 4 && active < total - 3) {
        setPages([1, "...", active - 1, active, active + 1, "...", total]);
        }
        if (active > total - 4) {
        setPages([1, "...", total - 4, total - 3, total - 2, total - 1, total]);
        }
    }, [active, total]);

    const handleClick = useCallback(e => {
        if (e.target.value === 0) {
        return;
        }
        setFetchPageNumber(e.target.value)
    }, [setFetchPageNumber]);

    const [TrendDetailsObject, setTrendDetailsObject] = useState({    
        id:             '',
        title:          '',
        definition:     '',
        origin:         '',
        usage:          [],
        similarTrends:  [],
        tweets:         [],
        alsoSee:        [],
        memes:          [],
        timelineStart:  '',
        timelineEnd:    ''
    })

    const handleAddTrendPopUp = () => {
        setReadOnly(false)
        setTrendDetailsObject({
            id:             '',
            title:          '',
            definition:     '',
            origin:         '',
            usage:          [],
            similarTrends:  [],
            tweets:         [],
            alsoSee:        [],
            memes:          [],
            timelineStart:  '',
            timelineEnd:    ''
        })
        setNewTrendModal(true)
    }

    const handleUpdateTrendPopUp = (id, title, definition, origin, usage, tweets, similarTrends, alsoSee, memes, timelineStart, timelineEnd) => {
        setReadOnly(true)
        setTrendDetailsObject({
            id:             id,
            title:          title,
            definition:     definition,
            origin:         origin,
            usage:          usage,
            tweets:         tweets,
            similarTrends:  similarTrends,
            alsoSee:        alsoSee,
            memes:          memes,
            timelineStart:  timelineStart,
            timelineEnd:    timelineEnd
        })
        setTrendModal(true)
    }

    function TrendModal (props) {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="trendupdate-modal"
            backdropClassName="backdrop-modal-content"
          >
            <TrendUpdateModal CloseModal={() => setTrendModal(false)} TrendDetailsObject={TrendDetailsObject} readOnly={readOnly} popUpTitle="Update Trend" button3="UPDATE TREND" button2="DELETE TREND" />
          </Modal>
        );
    }

    function NewTrendModal (props) {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="trendupdate-modal"
            backdropClassName="backdrop-modal-content"
          >
            <TrendUpdateModal CloseModal={() => setNewTrendModal(false)} fetchData={fetchData} TrendDetailsObject={TrendDetailsObject} readOnly={readOnly} popUpTitle="Create Trend" button1="CREATE NEW TREND" />
          </Modal>
        );
    }
            return (
                <>
                    <div className="words-table">
                <ViewMoreButton style={{marginBottom: 15+'px'}} onClick={() => handleAddTrendPopUp()} name="CREATE NEW TREND" button1="CREATE TREND"/>
                        <Table>
                            <thead>
                                <tr>
                                    {
                                        contentHeaderArray.map((header, index) => (
                                            <th key={index}>{header}</th>
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                    {contentObject.map((word, index) => (
                                        <tr key={word.id}>
                                            <td>{word.title}</td>
                                            <td>{word.writerName}</td>
                                            <td>{(new Date(word.createdAt)).toLocaleDateString()}</td>
                                            <td>{(<><ViewMoreButton onClick={() => handleUpdateTrendPopUp(word.id, word.title, word.definition, word.origin, word.usage, word.tweets, word.similarTrends, word.alsoSee, word.memes, word.timelineStart, word.timelineEnd)} button3="UPDATE TREND" name="UPDATE TREND"/></>)}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                        <Pagination
                            handleClick={handleClick}
                            pages={pages}
                            active={active}
                        />
                        </div>
                    <TrendModal
                    show={trendModal}
                    onHide={() => setTrendModal(false)}
                    />
                    <NewTrendModal
                    show={newTrendModal}
                    onHide={() => setNewTrendModal(false)}
                    />
                </>

            )
}

export default TrendPageContent