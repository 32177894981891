import React, { useState, useEffect, } from 'react'

import {Link, useHistory} from 'react-router-dom'
import './editprofileform.scss'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import BackButton from '../../icons/right-chevron (1).png'
import CloseModal from '../../icons/close.png'
import Check from '../../icons/purplecheck.svg'
import { newAxios, ErrorAlert, SuccessAlert } from '../../App'
import API_BASE from '../../apiBase'


const EditProfileForm = (props) => { 
    const history = useHistory();
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState(false);
    const [ deleteAccountSuccess, setDeleteAccountSuccess ] = useState( false );
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [isChangingPassword, setIsChangingPassword] = useState(false);
    const [disableUsernameButton,setDisableUsernameButton] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    useEffect(() => {
      const user = JSON.parse(localStorage.getItem('user'));
      setEmail(user.email)
      setUsername(user.username)
    }, [])
    useEffect(() => {
      if(username.trim() === JSON.parse(localStorage.getItem('user')).username){
        setDisableUsernameButton(true)
      } else setDisableUsernameButton(false)
    }, [username])
    const validate = () => {
      let valid = (/^[a-zA-Z0-9]*$/).test(username.trim());
      if(!valid) {
        ErrorAlert('Username can only contain alphanumeric characters')
        return;
      }
      if(username.trim().length < 3) {
        ErrorAlert('Username must be 3 characters or more')
        return;
      }
      setEditModalShow(true);
    }
    const updateUsername = () => {
      setDisableUsernameButton(true)
      newAxios.put(`${API_BASE}/auth/username`, {
        newUsername : username
      })
      .then(response => {
        SuccessAlert(response.data.message)
        localStorage.setItem('user', JSON.stringify(response.data.newUser))
      })
      .catch(err => {
        console.log(err)
        ErrorAlert(err.response.data.error)
      })
      .finally(() => {
        setEditModalShow(false)
        setDisableUsernameButton(false)
      })
    }
    const changePassword = () => {
      if(!newPassword.trim() || !currentPassword.trim() || !confirmPassword.trim()) {
        ErrorAlert('Fill all fields');
        return
      }
      if(newPassword.length < 7){
        ErrorAlert('Password must be 7 characters')
        return
      }
      if(newPassword.trim() !== confirmPassword.trim()) {
        ErrorAlert('Passwords do not match');
        return
      }
      setIsChangingPassword(true);
      newAxios.put(`${API_BASE}/auth/password`, {
        oldPassword : currentPassword,
        newPassword : newPassword
      })
      .then(response => {
        SuccessAlert(response.data.message)
        setNewPassword('')
        setCurrentPassword('')
        setConfirmPassword('')
      })
      .catch(err => {
        console.log(err)
        ErrorAlert(err.response.data.error)
      })
      .finally(() => {
        setIsChangingPassword(false)
      })
    }
    const deleteAsync  = () => {
        newAxios.delete(`${API_BASE}/auth/deleteAccount`)
        .then(response => {
          SuccessAlert(response.data.message);
          setDeleteModalShow(false);
          setDeleteAccountSuccess(true);
          localStorage.removeItem('user');
          localStorage.removeItem('token');
          history.push('/yoodict')
        })
        .catch(err => {
          console.log(err)
          ErrorAlert(err.response.data.error)
        })
    }
    
    function DeleteAccountSuccess(props) {
        return (
          <Modal
            {...props}
            size="md"
            
          >
            <Modal.Header className="editProfileModals">
            <div className="nav-back">
                <Link to="/"><img src={BackButton} alt="back" className="back-button" />BACK TO DICTIONARY </Link>
            </div>
            </Modal.Header>
            <Modal.Body  className="editProfileModalsBody">
                <img alt="check" src={Check} className="checkicon" />
              <h3 style={{marginBottom: 5+'%'}}>ACCOUNT SUCCESSFULLY DELETED</h3>
            </Modal.Body>
          </Modal>
        );
      }

    function DeleteAccountModal(props) {
        return (
          <Modal
            {...props}
            size="lg"
            
          >
            <Modal.Header className="editProfileModals">
              <div className="close-modal-button"><img alt="close-modal" onClick={() => setDeleteModalShow(false)} src={CloseModal} /></div>
            </Modal.Header>
            <Modal.Body  className="editProfileModalsBody">
              <h3>ARE YOU SURE YOU WAN TO DELETE YOUR ACCOUNT ?</h3>
              <div className="modelConfirmation-buttons">
                <Button id="modalButtonStyle" type="" onClick={deleteAsync}>
                    <span className="modelConfirmation-yes">YES</span>
                </Button>
                <Button id="modalButtonStyle" type="" onClick={() => setDeleteModalShow(false)}>
                    <span className="modelConfirmation-no">NO</span>
                </Button>
            </div>
            </Modal.Body>
          </Modal>
        );
      }
      function EditAccountModal(props) {
          return (
            <Modal
              {...props}
              size="lg"
              
            >
              <Modal.Header className="editProfileModals">
                <div className="close-modal-button"><img alt="close" onClick={() => setEditModalShow(false)} src={CloseModal} /></div>
              </Modal.Header>
              <Modal.Body  className="editProfileModalsBody">
                <h3>Are you sure you want to change your username</h3>
                <div className="modelConfirmation-buttons">
                  <Button disabled={disableUsernameButton} onClick={updateUsername} id="modalButtonStyle" type="">
                      <span className="modelConfirmation-yes">YES</span>
                  </Button>
                  <Button id="modalButtonStyle" type="" onClick={() => setEditModalShow(false)}>
                      <span className="modelConfirmation-no">NO</span>
                  </Button>
              </div>
              </Modal.Body>
            </Modal>
          );
        }
    return (
        <>
            <div className="editprofile-form">
            <Form id="editprofileForm">
            <div className="nav-back">
                <Link to="/"><img alt="back" src={BackButton} className="back-button" />BACK TO DICTIONARY </Link>
            </div>
            <div className="form-header editp">Edit Profile</div>
                <Form.Group className="editp" controlId="formBasicEmail">
                    <Form.Label>Userame</Form.Label>
                    <Form.Control value={username} onChange={e => setUsername(e.target.value)}  size="lg" type="text" placeholder="" />
                </Form.Group>
                <Form.Group className="editp" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control value={email} disabled size="lg" type="email" placeholder="" />
                </Form.Group>
                <Button disabled={disableUsernameButton} id="editprofileButton" onClick={validate} type="">
                    UPDATE USERNAME
                </Button>
                <br />
                <br />
                <br />
                <Form.Text className="deleteaccountheader editp">
                    Change Password
                </Form.Text>
                <Form.Group className="editp" controlId="formBasicEmail">
                    <Form.Label>Current Password</Form.Label>
                    <Form.Control value={currentPassword} onChange={e => setCurrentPassword(e.target.value)} size="lg" type="password" placeholder="" />
                </Form.Group>
                <Form.Group className="editp" controlId="formBasicEmail">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control value={newPassword} onChange={e => setNewPassword(e.target.value)} size="lg" type="password" placeholder="" />
                </Form.Group>
                <Form.Group className="editp" controlId="formBasicEmail">
                    <Form.Label>Confirm New Password</Form.Label>
                    <Form.Control value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} size="lg" type="password" placeholder="" />
                </Form.Group>
                <Button disabled={isChangingPassword} onClick={changePassword} id="editprofileButton" type="button">
                    CHANGE PASSWORD
                </Button>
                <Form.Text className="deleteaccountheader editp">
                    Delete Account
                </Form.Text>
                <Button id="deleteAccountButton" onClick={() => setDeleteModalShow(true)} type="">
                    DELETE
                </Button>
            </Form>
            </div>
            
            <EditAccountModal
            show={editModalShow}
            onHide={() => setEditModalShow(false)}
            />
            
            <DeleteAccountModal
            show={deleteModalShow}
            onHide={() => setDeleteModalShow(false)}
            />

            <DeleteAccountSuccess 
            show={deleteAccountSuccess}
            onHide={() => setDeleteAccountSuccess(false)}
            />
        </>
    )
}

export default EditProfileForm;