import React, {useState} from 'react'
import {Link, Redirect, withRouter, useLocation} from 'react-router-dom'
import {AuthContext} from '../../App'

import './login.scss'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'


const LoginForm = (props) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isPasswordShown, setIsPasswordShown] = useState(false)

    const AppContext = React.useContext(AuthContext)

    const clearInputs = () => {
        document.getElementById("loginForm").reset();
    }

    var URL = useLocation();

    const { from } = props.location.state || { from: { pathname: '/yoodict' } }

    if (AppContext.isLoggedIn === true) {
      return <Redirect to={from} />
    }

    const showPassword = () => {
        setIsPasswordShown(prevState => !prevState)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        AppContext.login(email, password);
    }

    return (
        <>
            <div className="login-form">
            <Form id="loginForm" onSubmit={(e) => handleSubmit(e)}>
            <div className="form-header">LOG IN</div>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control autoComplete="off" size="lg" required type="email" onChange={e => {setEmail(e.target.value)}} placeholder="" />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control autoComplete="off" size="lg" required type={isPasswordShown ? "text" : "password"} onChange={e => {setPassword(e.target.value)}} placeholder="" />
                <Form.Group controlId="formShowPassword">
                    <Form.Check.Input type="checkbox" onClick={showPassword}/>
                        <Form.Check.Label>
                            Show Password
                        </Form.Check.Label>
                </Form.Group>
                </Form.Group>
                <Button id="loginButton" type="submit" block>
                    LOGIN
                </Button>
                <Form.Text className="text-muted">
                    Not Registered? <Link to="/signup">Sign Up</Link><br/>
                    <Link to="/forgotpassword">Forgot Password?</Link>
                </Form.Text>
            </Form>
            </div>
        </>
    )
}

export default withRouter(LoginForm);