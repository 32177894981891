import React from 'react'
import {Link} from 'react-router-dom'

import YdtLogo from './ydt.png'
import YtdLogo from './ytd.png'
import InstagramIcon from './instagram.png'
import FacebookIcon from './facebook.png'
import TwitterIcon from './twitter.png'
import MetaDecorator from '../../MetaDecorator'


import './new-landing-page.css'

const NewLandingPage = () => {
    return (
        <>
        <MetaDecorator title="Urbanyooba" description="Stay connected to your roots | Mobile-first user-generated repository for memes, trends and buzzwords"/>
        <div className="wrapper">
            <div className="social-header">
                <a href="https://www.instagram.com/urbanyooba" target="_blank"><img className="nlp-social-icons" src={InstagramIcon}/></a>
                <a href="https://www.twitter.com/urbanyooba" target="_blank"><img className="nlp-social-icons" src={TwitterIcon}/></a>
                <a href="https://www.facebook.com/urbanyooba" target="_blank"><img className="nlp-social-icons" src={FacebookIcon}/></a>
            </div>
            <div className="nlp-header">urbanyooba</div>
            <div className="nlp-sub-header">Stay connected to your roots...</div>
            <div className="nlp-sections">
                <div className="sections-wrapper">
                    <div className="nlp-yoodict sections">
                        <img src={YdtLogo}/>
                        <p className="nlp-section-header">Yoodict</p>
                        <p className="nlp-section-text">The Buzzwords Dictonary</p>
                        <Link className="findmore-button" to="/yoodict">FIND OUT MORE</Link>
                    </div>
                </div>
                <div className="sections-wrapper">
                    <div className="nlp-yootrends sections">
                        <img src={YtdLogo}/>
                        <p className="nlp-section-header">Yootrends</p>
                        <p className="nlp-section-text">Trends as they happen</p>
                        <Link className="findmore-button" to="/yootrends">FIND OUT MORE</Link>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default NewLandingPage