import React, {useState} from "react";
import "./taginput.scss";
import PlusIcon from '../../icons/plusicon.svg'
import { ToastContainer, toast as Toastify } from 'react-toastify';

const TagInput = (props) => {
  const [word, setWord] = useState('');

  const removeTags = (indexToRemove) => {
    props.setTags([...props.tags.filter((_, index) => index !== indexToRemove)]);
  };

  const addTags = (event) => {
    if (word !== "" && !word.includes('\n')) {
      props.setTags([...props.tags, word]);
      const tag = document.getElementById(props.tagBoxId);
      tag.value = '';
    } else if (word.includes('\n')){
    Toastify.error('Please delete empty lines', {
      position: "bottom-center",
      hideProgressBar: false,
      autoClose: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    }
  };

  const handleKeyDown = (event) => {
    addTags(event);
  };

  return (
    <div className="tags-input">
      <ul id="tags">
        {props.tags.map((tag, index) => (
          <li key={index} className="tag">
            <span className="tag-title">{tag}</span>
            <span className="tag-close-icon" onClick={() => removeTags(index)}>
              x
            </span>
          </li>
        ))}
      </ul>
      <textarea
        className="text-area"
        id={props.tagBoxId}
        type="text"
        placeholder="Press button to add tags"
        onChange={(event) => setWord(event.target.value)}
        // onClick={props.onClick}
      />
      <img alt="add tag button" className="AddTagIcon" onClick={(word) => handleKeyDown(word)} src={PlusIcon}/>
    </div>
  );
};

export default TagInput;

// const App = () => {
// 	return (
// 		<div className="App">
// 			<TagsInput selectedTags={selectedTags}  tags={['Nodejs', 'MongoDB']}/>
// 		</div>
// 	);
// };
