import React, {useState, useEffect} from 'react'

import './trendupdate.scss'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import {ViewMoreButton} from '../../pages/adminpage/button'
import {newAxios, SuccessAlert, ErrorAlert} from '../../App'
import TagInput from '../taginput/taginput'
import TrendSuggestTags from '../suggetstags/trendsuggesttag'
import API_BASE from '../../apiBase'
import $ from 'jquery'

const ImagePreview = ({file}) => {
    const [src, setSrc] = useState('')
    useEffect(() => {
        if(!file) return;
        const reader = new FileReader();
        reader.addEventListener('load', function(){
            setSrc(this.result)
        })
        reader.readAsDataURL(file);
    }, [file])
    return(
        <div className="image-preview">
            <img src={src} style={src ? { display : 'block'} : {}} alt="preview" className="image-preview__image"/>
            {!file && <span className="image-preview__default-text">Add meme</span>}
        </div>
    )
}

const TrendUpdateModal = ({CloseModal, fetchData, readOnly, TrendDetailsObject, popUpTitle, button1, button2, button3}) => {
    const [similarTags, setSimilarTags] = useState(TrendDetailsObject.similarTrends)
    const [alsoSeeTags, setAlsoSeeTags] = useState(TrendDetailsObject.alsoSee)
    const [usageTags, setUsageTags] = useState(TrendDetailsObject.usage)
    const [tweetTags, setTweetTags] = useState(TrendDetailsObject.tweets)
    const [memesArray, setMemesArray] = useState(TrendDetailsObject.memes)
    const [slug, setSlug] = useState([])
    const [previewFiles, setPreviewFiles] = useState([])
    const [timelineError,setTimelineError] = useState(false)
    
    var formNewData = new FormData();
    var formUpdateData = new FormData();

    const deleteTrend = () => {
        newAxios.delete(`${API_BASE}/trend/${TrendDetailsObject.id}`)
        .then(res => {
            SuccessAlert(res.data.message)
            fetchData()
            CloseModal()
        })
        .catch((err) => console.log(err))
    }

    const RemoveMeme = (imageLink, memeId) => {
        $(document).ready(function(e) {
            document.getElementById(memeId).style.display = 'none';
        })
        delete TrendDetailsObject.memes[imageLink]
        delete memesArray[imageLink]
        newAxios.put(`${API_BASE}/trend/${TrendDetailsObject.id}/removeMemes`, {memes: [imageLink]})
        .then(res => {
            SuccessAlert(res.data.message)
        })
        .catch((err) => console.log(err))
    }

    const [newTrendObject, setNewTrendObject] = useState({
        title:          TrendDetailsObject.title,
        definition:     TrendDetailsObject.definition, 
        origin:         TrendDetailsObject.origin,
        usage:          usageTags,
        tweets:         tweetTags,
        similarTrends:  similarTags,
        alsoSee:        alsoSeeTags,
        memes:          memesArray,
        timelineStart:  TrendDetailsObject.timelineStart,
        timelineEnd:    TrendDetailsObject.timelineEnd
    })

    const setNewTrendFormData = (title, definition, origin, timelineStart, timelineEnd, usage, tweets, similarTrends, alsoSee, memes) => {
        formNewData.append("title", title);
        formNewData.append("definition", definition);
        formNewData.append("origin", origin);
        formNewData.append("timelineStart", timelineStart);
        formNewData.append("timelineEnd", timelineEnd);
        if (usage.length > 0){
            for (let l = 0; l < usage.length;l++){
                formNewData.append('usage[]', usage[l]);
            }
        }
        if (tweets.length > 0){
            for (let l = 0; l < tweets.length;l++){
                formNewData.append('tweets[]', tweets[l]);
            }
        }
        if (similarTrends.length > 0){
            for (let l = 0; l < similarTrends.length;l++){
                formNewData.append('similarTrends[]', similarTrends[l]._id);
            }
        }
        if (alsoSee.length > 0){
            for (let l = 0; l < alsoSee.length;l++){
                formNewData.append('alsoSee[]', alsoSee[l]._id);
            }
        }
        if (memes.length > 0){
            for (let l = 0; l < memes.length;l++){
                formNewData.append('memes', memes[l]);
            }
        }
        handleAddTrend();
    }

    const setUpdateTrendFormData = (title, definition, origin, timelineStart, timelineEnd, usage, tweets, similarTrends, alsoSee, memes) => {
        if (title){formUpdateData.append("title", title);}
        if (definition){formUpdateData.append("definition", definition);}
        if (origin){formUpdateData.append("origin", origin);}
        if (timelineStart){formUpdateData.append("timelineStart", timelineStart);}
        if (timelineEnd){formUpdateData.append("timelineEnd", timelineEnd);}
        if (usage && usage.length > 0){
            for (let l = 0; l < usage.length;l++){
                formUpdateData.append('usage[]', usage[l]);
            }
        }
        if (tweets && tweets.length > 0){
            for (let l = 0; l < tweets.length;l++){
                formUpdateData.append('tweets[]', tweets[l]);
            }
        }
        if (similarTrends && similarTrends.length > 0){
            for (let l = 0; l < similarTrends.length;l++){
                formUpdateData.append('similarTrends[]', similarTrends[l]._id);
            }
        }
        if (alsoSee && alsoSee.length > 0){
            for (let l = 0; l < alsoSee.length;l++){
                formUpdateData.append('alsoSee[]', alsoSee[l]._id);
            }
        }
        if (memes && memes.length > 0){
            for (let l = 0; l < memes.length;l++){
                formUpdateData.append('memes', memes[l]);
            }
        }
        if (tweets.length === 0){
            formUpdateData.append('tweets[]', [])
        }
        if (alsoSee.length === 0){
            formUpdateData.append('alsoSee[]', [])
        }
        if (similarTrends.length === 0){
            formUpdateData.append('similarTrends[]', [])
        }
        // if (memes.length === 0){
        //     formUpdateData.append('memes', [])
        // }
        handleUpdateTrend();
    }
    
    const validateUpdateTrend = () => {
        if (
            newTrendObject.origin === "0"
        ) {
            ErrorAlert("Please select an origin");
            return
        } else if (
            newTrendObject.definition.trim() === ""
        ) {
            ErrorAlert("Please fill a definition");
            return
        } else if (
            newTrendObject.usage.length === 0
        ) {
            ErrorAlert("Please fill at least one usage");
            return
        } else if (
            newTrendObject.timelineStart.trim() === "" ||
            newTrendObject.timelineEnd.trim() === ""
        ) {
            ErrorAlert("Please fill timeline");
            return
        }
        else {
        const StartArray = newTrendObject.timelineStart.split(' ');
        const EndArray = newTrendObject.timelineEnd.split(' ');

        const StartMonth = StartArray[0].toLowerCase();
        const EndMonth = EndArray[0].toLowerCase();
        var months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
        var month1 = months.indexOf(StartMonth);
        var month2 = months.indexOf(EndMonth);

        if (StartArray[1] === NaN || StartArray[1] < 0){
            ErrorAlert('Review timeline start format')
            return
        } else if (EndArray[1] === NaN || EndArray[1] < 0){
            ErrorAlert('Review timeline end format')
            return
        } else if (StartArray[1] > EndArray[1]){
            ErrorAlert('Timeline Start cannot be greater than Timeline End')
            return
        } else if (StartArray[1] === EndArray[1] && month1 > month2){
            ErrorAlert('Timeline Start cannot be greater than Timeline End')
            return
        } else if (month1 === -1){
            ErrorAlert('Timeline Start month incorrect')
            return
        } else if (month2 === -1){
            ErrorAlert('Timeline End month incorrect')
            return
        }
        delete newTrendObject.title
        if (
            JSON.stringify(newTrendObject.origin) === JSON.stringify(TrendDetailsObject.origin)
        ){
            delete newTrendObject.origin
        } 
        if (
            JSON.stringify(newTrendObject.definition) === JSON.stringify(TrendDetailsObject.definition)
        ){
            delete newTrendObject.definition
        } 
        if (
            newTrendObject.similarTrends.length > 0 && JSON.stringify(newTrendObject.similarTrends) === JSON.stringify(TrendDetailsObject.similarTrends)
        ){
            delete newTrendObject.similarTrends
        } 
        if (
            newTrendObject.alsoSee.length > 0 && JSON.stringify(newTrendObject.alsoSee) === JSON.stringify(TrendDetailsObject.alsoSee)
        ){
            delete newTrendObject.alsoSee
        } 
        if (
            newTrendObject.memes.length > 0 && JSON.stringify(newTrendObject.memes) === JSON.stringify(TrendDetailsObject.memes)
        ){
            delete newTrendObject.memes
        }
        if (
            JSON.stringify(newTrendObject.usage) === JSON.stringify(TrendDetailsObject.usage)
        ){
            delete newTrendObject.usage
        }
        if (
            newTrendObject.tweets.length > 0 && JSON.stringify(newTrendObject.tweets) === JSON.stringify(TrendDetailsObject.tweets)
        ){
            delete newTrendObject.tweets
        }
        if (
            JSON.stringify(newTrendObject.timelineStart) === JSON.stringify(TrendDetailsObject.timelineStart)
        ){
            delete newTrendObject.timelineStart
        }
        if (
            JSON.stringify(newTrendObject.timelineEnd) === JSON.stringify(TrendDetailsObject.timelineEnd)
        ){
            delete newTrendObject.timelineEnd
        }
        setUpdateTrendFormData (
            newTrendObject.title, 
            newTrendObject.definition, 
            newTrendObject.origin, 
            newTrendObject.timelineStart, 
            newTrendObject.timelineEnd, 
            newTrendObject.usage, 
            newTrendObject.tweets, 
            newTrendObject.similarTrends, 
            newTrendObject.alsoSee, 
            newTrendObject.memes
        )}
    }
     
    const validateAddTrend = () => {
        if (
            newTrendObject.title.trim() === ""
        ) {
            ErrorAlert("Please fill Title");
            return
        }else if (
            newTrendObject.usage.length === 0
        ) {
            ErrorAlert("Please fill at least one usage");
            return
        }else if (
            newTrendObject.origin === ""
        ) {
            ErrorAlert("Please select origin");
            return
        }else if (
            newTrendObject.definition.trim() === ""
        ) {
            ErrorAlert("Please fill definition");
            return
        }else if (
            newTrendObject.timelineStart.trim() === "" ||
            newTrendObject.timelineEnd.trim() === ""
        ) {
            ErrorAlert("Please fill trend timeline");
            return
        } else {
            const StartArray = newTrendObject.timelineStart.split(' ');
            const EndArray = newTrendObject.timelineEnd.split(' ');
    
            const StartMonth = StartArray[0].toLowerCase();
            const EndMonth = EndArray[0].toLowerCase();
            var months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
            var month1 = months.indexOf(StartMonth);
            var month2 = months.indexOf(EndMonth);
    
            if (StartArray[1] === NaN || StartArray[1] < 0){
                ErrorAlert('Review timeline start format')
                return
            } else if (EndArray[1] === NaN || EndArray[1] < 0){
                ErrorAlert('Review timeline end format')
                return
            } else if (StartArray[1] > EndArray[1]){
                ErrorAlert('Timeline Start cannot be greater than Timeline End')
                return
            } else if (StartArray[1] === EndArray[1] && month1 > month2){
                ErrorAlert('Timeline Start cannot be greater than Timeline End')
                return
            }
        setNewTrendFormData (
            newTrendObject.title, 
            newTrendObject.definition, 
            newTrendObject.origin, 
            newTrendObject.timelineStart, 
            newTrendObject.timelineEnd, 
            newTrendObject.usage, 
            newTrendObject.tweets, 
            newTrendObject.similarTrends, 
            newTrendObject.alsoSee, 
            newTrendObject.memes
            )}
    }

    useEffect(() => {
      setNewTrendObject({
        ...newTrendObject,
        alsoSee: alsoSeeTags,
      });
  
    }, [alsoSeeTags]);

    useEffect(() => {
      setNewTrendObject({
        ...newTrendObject,
        tweets: tweetTags,
      });
  
    }, [tweetTags]);
  
    useEffect(() => {
      setNewTrendObject({
        ...newTrendObject,
        similarTrends: similarTags,
      });
    }, [similarTags]);
  
    useEffect(() => {
      setNewTrendObject({
        ...newTrendObject,
        usage: usageTags,
      });
    }, [usageTags]);

    const selectedSlug = (slug) => {
        setSlug(slug)
    }

    const handleUpdateTrend = () =>{
        newAxios.put(`${API_BASE}/trend/${TrendDetailsObject.id}`, formUpdateData)
        .then(res => {
            SuccessAlert(res.data.message)
             fetchData()
             CloseModal()
        })
        .catch((err) => {
          if(err.response.data.validationErrors) {
            err.response.data.validationErrors.map(error => {
              return ErrorAlert(error.error)
            })
          } else {
            ErrorAlert(err.response.data.error)}
          })
    }

    const handleAddTrend = () =>{
        newAxios.post(`${API_BASE}/trend`, formNewData)
        .then(res => {
            SuccessAlert(res.data.message)
             fetchData()
             CloseModal()
            }
        )
        .catch((err) => {
          if(err.response.data.validationErrors) {
            err.response.data.validationErrors.map(error => {
              return ErrorAlert(error.error)
            })
          } else {
            ErrorAlert(err.response.data.error)
          }
          })
    }

    const showImgPreview = () => {
        const inpFile = document.getElementById("inpFile")
        inpFile.addEventListener('change', function(){
            if(this.files.length === 0) {
                setPreviewFiles([])
                return;
            }
            setPreviewFiles([...this.files])
        })
    }
    
    return (
            <Form id="trendupdateForm" className="trendupdateForm">
            <div className="trendupdateform-header">{popUpTitle}</div>
            <div >
            <div className="trendupdate row1">
                <Form.Group md="6" as={Col} controlId="formBasicTitle">
                    <Form.Label>Title <span className="req">*</span></Form.Label>
                    <Form.Control defaultValue={newTrendObject.title} onChange={e => {newTrendObject.title = e.target.value}} readOnly={readOnly} required size="lg" type="text" placeholder=""/>
                </Form.Group>
                <Form.Group md="6" as={Col} controlId="formBasicOrigin">
                    <Form.Label>Origin of word <span className="req">*</span></Form.Label>
                    <Form.Control defaultValue={newTrendObject.origin} onChange={e => {newTrendObject.origin = e.target.value}} as="select" required size="lg" type="text" placeholder="">
                        <option value="0">Origin...</option>
                        <option value="Unknown">Unknown</option>
                        <option value="Hausa">Hausa</option>
                        <option value="Igbo">Igbo</option>
                        <option value="Pidgin">Pidgin</option>
                        <option value="Yoruba">Yoruba</option>
                    </Form.Control> 
                </Form.Group>
            </div>
            <div className="trendupdate row1">
                <Form.Group md="6" as={Col} controlId="formBasicDefinition">
                    <Form.Label>Definition <span className="req">*</span></Form.Label>
                    <Form.Control defaultValue={newTrendObject.definition} onChange={e => {newTrendObject.definition = e.target.value}} required size="lg" as="textarea" rows="5" placeholder=""/>
                </Form.Group>
                <div className="form-group col-md-6">
                    <Form.Group controlId="formBasicUsage">
                        <Form.Label>Usage <span className="req">*</span></Form.Label>
                        <TagInput tags={usageTags} tagBoxId='usagetag' setTags={setUsageTags}/>
                    </Form.Group>
                    <Form.Group controlId="formBasicTweets">
                        <Form.Label>Embed Tweets<span className="req"></span></Form.Label>
                        <TagInput tags={tweetTags} tagBoxId='tweetstag' setTags={setTweetTags}/>
                    </Form.Group>
                </div>
            </div>
            <div className="trendupdate bottom-row">
                <Form.Group as={Col} controlId="formBasicAlsoSee">
                    <Form.Label>Also see </Form.Label>
                    <TrendSuggestTags selectedSlug={selectedSlug} slug={slug} setTags={setAlsoSeeTags} tags={alsoSeeTags}/>
                </Form.Group>
                <Form.Group md="6" as={Col} controlId="formBasicSimilarWords">
                    <Form.Label>Similar Trends <span className="req"></span></Form.Label>
                    <TrendSuggestTags selectedSlug={selectedSlug} slug={slug} setTags={setSimilarTags} tags={similarTags}/>
                </Form.Group>
            </div>
            <div className="trendupdate bottom-row">
                <Form.Group md="6" as={Col} controlId="formBasicTimelineStart">
                    <Form.Label>Timeline Start <span className="req">*</span></Form.Label>
                    <Form.Control defaultValue={newTrendObject.timelineStart} className="timelineStart" onChange={e => {newTrendObject.timelineStart = e.target.value}} required size="lg" type="text" placeholder="MM YY e.g July 2020" />
                   </Form.Group>
                <Form.Group md="6" as={Col} controlId="formBasicEnd">
                    <Form.Label>Timeline End <span className="req">*</span></Form.Label>
                    <Form.Control defaultValue={newTrendObject.timelineEnd} className="timelineEnd" onChange={e => {newTrendObject.timelineEnd = e.target.value}} required size="lg" type="text" placeholder="MM YY e.g July 2020" />
                </Form.Group>
                </div>
            </div>
            <div className="trendupdate row2">
                <Form.Group as={Col} controlId="formBasicMemes">
                    <Form.Label>Memes </Form.Label><br/>
                    <input onClick={() => showImgPreview()} style={{display: 'flex'}} size="lg" accept="image/*" multiple type="file" id="inpFile" onChange={(e) => newTrendObject.memes = e.target.files} name="inpFile"/>
                    <div className="edit-meme-container">
                    {
                        !newTrendObject.memes.length > 0 ? 
                        <></> : 
                        newTrendObject.memes.map((src, i) => 
                        <div className="existing-memes"  key={src}>
                            <div className="delete-meme" id={`${src}${i}`} onClick={() => RemoveMeme(src, `${src}${i}`)}>Remove
                            <div className="image-preview">
                                <img src={src} style={src ? { display : 'block'} : {}} alt="preview" className="image-preview__image"/>
                            </div>
                            </div>
                        </div>
                        )
                    }
                    </div>
                    {
                        previewFiles.length === 0 ? 
                        <ImagePreview /> : 
                        previewFiles.map((f, i) => <ImagePreview file={f} key={i} />)
                    }
                </Form.Group>
            </div>
            <div className="dual-buttons">
                {button3 ? <ViewMoreButton onClick={validateUpdateTrend} name={button3}/> : <></>}
                {button2 ? <ViewMoreButton onClick={deleteTrend} name={button2}/> : <></>}
                {button1 ? <ViewMoreButton onClick={validateAddTrend} name={button1}/> : <></>}
                <Button id="cancelWordButton" onClick={CloseModal} >
                    <span className="cancel-button">CANCEL</span>
                </Button>
            </div>
            </Form>
    )
}

export default TrendUpdateModal