import React, {useState, useEffect, useRef} from 'react'

import './viewprofile.scss'

import BannerSection from '../../components/banner-section/bannersection'
import ProfileInfo from '../../components/profileinfo/profileinfo'
import InfoBanner from '../../components/infobanner/infobanner'
import NavBar from '../../components/navbar/navbar'
import axios from 'axios'
import API_BASE from '../../apiBase'
import MetaDecorator from '../../MetaDecorator'


const ProfilePage = (props) => {
    const [ ShowAlert, setShowAlert ] = React.useState( false );
    const [wordDefined, setWordDefinedByUser] = useState([])
    const [cardNumber, setCardNumber] = useState(1)
    const [userData, setUserData] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    let isRendered = useRef(false);

    useEffect(() => { 
        isRendered = true;
        async function getUserFromDB() {
            const fullResponse = await axios({
                method: 'GET',
                url: API_BASE + '/words/byUser',
                params: {user: props.match.params.username}
            })
            const responseData = await fullResponse.data
            setUserData(responseData.user)
            console.log(responseData.user)
            if (responseData.words.length > 0){
                setWordDefinedByUser(responseData.words.map(word => ({title: word.word, content: word.meaning})))
            } else {
                setWordDefinedByUser([{title: 'No words defined', content: 'No words defined'}])
                setCardNumber(0)
            }
            setIsLoading(false)
        }

        getUserFromDB();

        return () => {isRendered = false}
    }, [])

    const toggleShowAlert = () =>
    {
        setShowAlert( prevState => !prevState );
    };

    const dormantFunction = () => {
        return
    }
    return (
        <>
        <MetaDecorator title="Profile" description="Yoodict user profile"/>
        <NavBar altUpperDisplay={dormantFunction} onSearch={dormantFunction} onClick={dormantFunction}/>
        <InfoBanner header={''}/>
            <div className="ydt-fluid">
            <div className="left-pack">
                <ProfileInfo wordDefined={wordDefined} cardNumber={cardNumber} userData={userData} isLoading={isLoading}/>
            </div>
            <div className="profile-banner-section">
                <BannerSection/>
            </div>
            </div>
        </>
    )
}

export default ProfilePage;