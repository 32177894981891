import React, { useState, useEffect } from 'react'
import {Link} from 'react-router-dom'

import './worddefs.scss'

import Card from 'react-bootstrap/Card'
import ShareIcon from '../../icons/share.svg'
import WhatsappIcon from '../../icons/whatsapp.svg'
import VerificationBadge from '../../icons/verifycheck.svg'
import FacebookIcon from '../../icons/facebook.svg'
import TwitterIcon from '../../icons/twitter.svg'
import { newAxios, SuccessAlert } from '../../App'
import API_BASE from '../../apiBase'

const WordDefs = ({id, trend, slug, word, definition, examples, postedBy, createdAt, upvoteCount, downvoteCount, similarWords, upvotes, downvotes}) => {
    const [upvoters, setUpvoters] = useState(upvotes);
    const [downvoters, setDownvoters] = useState(downvotes);
    const [upvote, setUpVote] = useState(upvoteCount)
    const [downvote, setDownVote] = useState(downvoteCount)
    const [upVoteState, setUpVoteState] = useState(false)
    const [downVoteState, setDownVoteState] = useState(false)
    const [disableButtons, setDisableButtons] = useState(false)
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    useEffect(() => {
        if(!user) {
            const wordsVoted = localStorage.getItem('postsLiked') ? JSON.parse(localStorage.getItem('postsLiked')) : null;
            if(!wordsVoted) return;
            const wordExists = wordsVoted.find(w => w.id === id);
            if(!wordExists) return;
            if(wordExists.status === 'up') setUpVoteState(true);
            else if(wordExists.status === 'down') setDownVoteState(true);
        }else {
            if(upvoters.includes(user.id)) {
                setUpVoteState(true);
                setDownVoteState(false)
            } 
            else if(downvoters.includes(user.id)) {
                setDownVoteState(true);
                setUpVoteState(false)
            } else {
                setUpVoteState(false);
                setDownVoteState(false)
            }
        }
    }, [upvoters, downvoters, user, id])
        function voteUp(postId) {
            setDisableButtons(true);
            if(user) {
                return newAxios.put(`${API_BASE}/word/upvote`, {
                    word : postId
                })
                .then(response => {
                    // SuccessAlert(response.data.message)
                    setUpvoters(response.data.updatedWord.upvotes)
                    setUpVote(response.data.updatedWord.upvoteCount)
                    setDownvoters(response.data.updatedWord.downvotes)
                    setDownVote(response.data.updatedWord.downvoteCount)
                })
                .catch(err => console.log(err))
                .finally(() => setDisableButtons(false))
            }
            if (localStorage.getItem('postsLiked') == null){
                localStorage.setItem('postsLiked', '[]')
            }

            var oldPostsLiked = JSON.parse(localStorage.getItem('postsLiked'));

            var found = oldPostsLiked.find(post => post.id === postId);
            
            if (!found){
                newAxios.put(`${API_BASE}/word/upvote`, {
                    word : postId
                })
                .then(response => {
                    // SuccessAlert(response.data.success);
                    oldPostsLiked.push({id: postId,status: 'up'});
                    localStorage.setItem("postsLiked", JSON.stringify(oldPostsLiked));
                    setUpVoteState(true)
                    setUpVote(prevState => prevState+1)
                    document.getElementById(`uv${postId}`).style.fill = "#07bc0c";
                    localStorage.setItem('postsLiked', JSON.stringify(oldPostsLiked))
                })
                .catch(err => console.log(err.data))
                .finally(() => setDisableButtons(false))
            }
            else if (found.status === 'down'){
                newAxios.put(`${API_BASE}/word/upvote`, {
                    word : postId,
                    changingVote : true
                })
                .then(response => {
                    // SuccessAlert(response.data.message)
                    setUpVoteState(true)
                    setUpVote(prevState => prevState+1)
                    setDownVoteState(false)
                    setDownVote(prevState => prevState-1)
                    let filtered = oldPostsLiked.filter(p => p.id !== found.id);
                    oldPostsLiked = filtered
                    oldPostsLiked.push({id: postId,status: 'up'});
                    document.getElementById(`uv${postId}`).style.fill = "#07bc0c";
                    document.getElementById(`dv${postId}`).style.fill = "#BBC0C4";
                    localStorage.setItem('postsLiked', JSON.stringify(oldPostsLiked))
                })
                .catch(err => console.log(err))
                .finally(() => setDisableButtons(false))
            } else if (found.status === 'up'){
                newAxios.put(`${API_BASE}/word/upvote`, {
                    word : postId,
                    removingVote : true
                })
                .then(response => {
                    // SuccessAlert(response.data.message)
                    let filtered = oldPostsLiked.filter(p => p.id !== found.id);
                    oldPostsLiked = filtered;
                    setUpVoteState(true)
                    setUpVote(prevState => prevState-1)
                    localStorage.setItem('postsLiked', JSON.stringify(oldPostsLiked))
                })
                .catch(err => console.log(err))
                .finally(() => setDisableButtons(false))
            }
        }

        function voteDown (postId)  {
            setDisableButtons(true)
            if(user) {
                return newAxios.put(`${API_BASE}/word/downvote`, {
                    word : postId
                })
                .then(response => {
                    // SuccessAlert(response.data.message)
                    setDownvoters(response.data.updatedWord.downvotes)
                    setDownVote(response.data.updatedWord.downvoteCount)
                    setUpvoters(response.data.updatedWord.upvotes)
                    setUpVote(response.data.updatedWord.upvoteCount)
                })
                .catch(err => console.log(err))
                .finally(() => setDisableButtons(false))
            }
            if (localStorage.getItem('postsLiked') == null){
                localStorage.setItem('postsLiked', '[]')
            }

            var oldPostsLiked = JSON.parse(localStorage.getItem('postsLiked'));
            var found = oldPostsLiked.find(post => post.id === postId);
            
            
            if (!found){
                newAxios.put(`${API_BASE}/word/downvote`, {
                    word : postId
                })
                .then(response => {
                    // SuccessAlert(response.data.message);
                    oldPostsLiked.push({id: postId,status: 'down'});
                    localStorage.setItem("postsLiked", JSON.stringify(oldPostsLiked));
                    setDownVoteState(true)
                    setDownVote(prevState => prevState+1)
                    document.getElementById(`dv${postId}`).style.fill = "#e74c3c";
                    localStorage.setItem('postsLiked', JSON.stringify(oldPostsLiked))
                })
                .catch(err => console.log(err))
                .finally(() => setDisableButtons(false))
            }
            else if (found.status === 'up'){
                newAxios.put(`${API_BASE}/word/downvote`, {
                    word : postId,
                    changingVote : true
                })
                .then(response => {
                    // SuccessAlert(response.data.message);
                    setDownVoteState(true)
                    setDownVote(prevState => prevState+1)
                    setUpVoteState(false)
                    setUpVote(prevState => prevState-1)
                    var filtered = oldPostsLiked.filter(value => value.id !== found.id);
                    oldPostsLiked = filtered
                    oldPostsLiked.push({id: postId,status: 'down'});
                    document.getElementById(`dv${postId}`).style.fill = "#e74c3c";
                    document.getElementById(`uv${postId}`).style.fill = "#BBC0C4";
                    localStorage.setItem('postsLiked', JSON.stringify(oldPostsLiked))
                })
                .catch(err => console.log(err))
                .finally(() => setDisableButtons(false))
            } else if (found.status === 'down'){
                newAxios.put(`${API_BASE}/word/downvote`, {
                    word : postId,
                    removingVote : true
                })
                .then(response => {
                    // SuccessAlert(response.data.message);
                    let filtered = oldPostsLiked.filter(p => p.id !== found.id);
                    oldPostsLiked = filtered;
                    setDownVoteState(false)
                    setDownVote(prevState => prevState-1);
                    localStorage.setItem('postsLiked', JSON.stringify(oldPostsLiked))
                })
                .catch(err => console.log(err))
                .finally(() => setDisableButtons(false))
            }
          }

        const postUrl = `${window.location.origin.toString()}/yoodict/word/${slug}`;
        const postTitle = encodeURI(`Word: ${word} 

Meaning: ${definition}

Check it out at: ${postUrl}`)

        
    return (
        <>
            <Card id="defcard">
                <Card.Body>
                    <Card.Text>
                        <span className="def-wrapper">
                            {/* <span className="def-number">1</span> */}
                            <span className="def-wrapper2">
                                <span className="word-def">{definition}</span><br></br>
                                <span className="word-def-example-title">Example</span>
                                {
                                    examples.map((item, i) => <><br/><span className="word-def-example" key={i}>{item}.</span></>)
                                }
                                <span className="def-wrapper3">
                                    <span className="word-def-user">
                                        By <Link to={!postedBy.deleted ? `/profilepage/${postedBy.username}` : "/yoodict"}>
                                        {postedBy.username} 
                                        <img style={{display: postedBy.verified === true ? 'inline-block' : 'none'}} src={VerificationBadge} className="verification-icon" alt="verification badge"/>  
                                        </Link> {(new Date(createdAt)).toLocaleDateString()} 
                                    </span>
                                    <span className="word-def-more-info" style={{display: trend === false ? 'none' : 'block'}}>
                                        <Link to={trend === false ? `/yootrends` : `/yootrends/trend/${trend.slug}`}>Find out more?</Link>
                                    </span>
                                </span>
                                <span className="def-wrapper4">
                                    <span className="ratings"> 
                                        <button className="left-button" disabled={disableButtons} onClick={() => voteUp(id)}> 
                                            <svg  className="up-vote" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path id={`uv${id}`} d="M12 0L6 12L0 0L12 0Z" fill={upVoteState ? "#07bc0c" : "#BBC0C4"}/>
                                            </svg>
                                        </button> <b className="ratings-divider">{upvote}</b>
                                        <button className="right-button" disabled={disableButtons} onClick={() => voteDown(id)}>
                                            <svg  className="down-vote" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path id={`dv${id}`} d="M12 0L6 12L0 0L12 0Z" fill={downVoteState ? "#e74c3c" : "#BBC0C4"}/>
                                            </svg>
                                        </button> <b>{downvote}</b>
                                    </span>
                                    <span className="share-section">
                                        {/* <img alt='share' src={ShareIcon}    className="share-icons"/> */}
                                        <a  target="_blank" href={`https://wa.me/?text=${postTitle}&url=${postUrl}`} ><img alt='wa' src={WhatsappIcon} id="whatsapp" className="share-icons whatsapp"/></a>
                                        <a  target="_blank" href={`https://twitter.com/share?url=${postUrl}&text=${postTitle}`}><img src={TwitterIcon} alt='twitter'  id="twitter" className="share-icons twitter"/></a>
                                        <a  target="_blank" href={`https://www.facebook.com/sharer.php?u=${postUrl}`}><img alt='facebook' src={FacebookIcon} id="facebook" className="share-icons facebook"/></a>
                                    </span>
                                </span>
                            </span>
                        </span>
                        <span className="similar-def">
                            {similarWords.length > 0 ? <div>Similar: {similarWords.map((item, index) => <Link key={`${item.slug}${index}`} to={`/yoodict/word/${item.slug}`}>{item.word} </Link>)}</div> : <></>}
                        </span>
                    </Card.Text>
                </Card.Body>
            </Card>

        </>
    );
}

export default WordDefs;