import React, {useState, useEffect, useContext, useRef} from 'react';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {Link, useLocation, Redirect, useHistory} from 'react-router-dom'
import Hamburger from '../../icons/menu.png'
import Logo from '../../icons/yoodict.svg'
import SearchIcon from '../../icons/search.png'
import CancelIcon from '../../icons/close.png'
import {AuthContext} from '../../App'
import './navbar.scss' 
import useWordSearch from './useWordSearch'
import API_BASE from '../../apiBase';
import GoogleAds from '../googleads/googleads'

const NavBar = ({upperDisplay, altUpperDisplay, onSearch}) => {
    const [searchMode, setSearchMode] = useState(false)
    const [query, setQuery] = useState('')
    const [pageNumber,setPageNumber] = useState(1)
    const wrapperRef = useRef(null) 
    const newword = ' '
    
    const AppContext = useContext(AuthContext)
    const userDets = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {username: '', role: ''}

    var URL = useLocation().pathname;
    var history = useHistory();

    const toggleSearchMode = () =>
    {
        setSearchMode( prevState => !prevState );
    };

    function handleSearch(e) {
        if (URL === '/yoodict' || URL === '/yoodict/'){
            setQuery(e.target.value)
            altUpperDisplay()
        }
    }
    
    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            if (URL === '/yoodict' || URL === '/yoodict/'){
                setPageNumber(1)
                handleSearchFetchUrlChange(query)
            } else if (URL.includes('/yoodict/alphabet')){
                handleSearchFetchUrlChange(event.target.value)
            }else return <Redirect to="/yoodict"/>
        }
    };

    const setSearchField = (text) => {
        setQuery(text);
        handleSearchFetchUrlChange(text)
        altUpperDisplay()
    }

    const handleRedirect = () =>{
        if (URL !== '/yoodict' || !URL.includes('/yoodict/alphabet')){
            history.push('/yoodict')
        }
    }

    function handleSearchFetchUrlChange(word) {
        // Here, we invoke the callback with the new value
        if(!word.trim()) return;
        onSearch(`${API_BASE}/words/search?q=${word}`);
    }

    const {
        words, 
        hasMore, 
        loading, 
        error} = useWordSearch(query, pageNumber)

    const Logout = () => {
        AppContext.logout()
    }
    
    const popover = (
        <Popover id="popover-basic">
          {/* <Popover.Title as="h3"></Popover.Title> */}
          <Popover.Content>
            <div style={{marginBottom: 10+'px', display : localStorage.getItem('token') ? 'none' : 'block', textAlign: 'center'}}><Link to="/login">Login  </Link></div>
            <div onClick={Logout} style={{marginBottom: 10+'px', cursor: 'pointer', display : localStorage.getItem('token') ? 'block' : 'none', textAlign: 'center'}}>Logout  </div>
            <div style={{marginBottom: 10+'px', display : localStorage.getItem('token') ? 'block' : 'none', textAlign: 'center'}}><Link to="/editprofile">Settings  </Link></div>
            <div style={{marginBottom: 10+'px', display : localStorage.getItem('token') ? 'block' : 'none', textAlign: 'center'}}><Link to={`/profilepage/${userDets.username}`}>Profile  </Link></div>
            <div style={{marginBottom: 10+'px', textAlign: 'center'}}><Link to="/help">Help  </Link></div>
          </Popover.Content>
        </Popover>
      );

    const handleClickOutside = (e) => {
        // const {current: wrap} = wrapperRef;
        if(wrapperRef.current && !wrapperRef.current.contains(e.target)){
            if (upperDisplay){
                altUpperDisplay()
            }
        }
    }

    useEffect(() => {
        document.addEventListener("click", handleClickOutside)

        return () => {
        document.removeEventListener("click", handleClickOutside)
        }
    },[handleClickOutside])
    
    return (
        <>
            <div className="minsearch">
                <Navbar bg="white" expand="lg" style={ { display: searchMode ? 'flex' : 'none' } }>
                <Form inline style={{width: 100+'%', flexFlow: 'inherit', flexWrap: 'nowrap'}}>
                    <FormControl ref={wrapperRef} autoComplete="off" type="text" value={query} onChange={(e) => handleSearch(e)} onKeyDown={(e) => handleKeyDown(e)} onFocus={(e) => handleRedirect(e)} placeholder="Search for a word ..." id="minimized-form-control-navbar" className="mr-sm-2 searchbarfull2" />
                    {upperDisplay && (
                        <div className="autoContainer">
                            { words.map(word => {return( 
                                <div onClick={() => setSearchField(word.word)} className="option" key={word.word}>
                                    <span>{word.word}</span>
                                </div>)
                            })}
                            <div>{loading && 'Loading...'}</div>
                            <div>{!loading && !hasMore && <>Word not found. <Link style={{textDecoration: 'underline'}} to={`/newword/${newword}`}>Define it.</Link></>}</div>
                        </div>
                    )}
                    <span onClick={toggleSearchMode}>
                        <img src={CancelIcon} alt="cancel-minisearch" className="cancel-icon"/>
                    </span>
                </Form>
                </Navbar>
            </div>
            <Navbar bg="white" expand="lg" style={ { display: searchMode ? 'none' : 'flex' } } id="NavigationBar">
                <Navbar.Brand href="">
                    <Link to="/yoodict">
                        <img className='logo' alt="yoodict-logo" id="yoodictLogo" src={Logo} />
                    </Link>
                </Navbar.Brand>
                    <Nav className="ml-auto">
                        <img className="search-icon" alt="search-icon" src={SearchIcon} onClick={toggleSearchMode}/>
                    </Nav>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav"  className="justify-content-end">
                    <FormControl ref={wrapperRef} autoComplete="off" type="text" value={query} onChange={(e) => handleSearch(e)} onKeyDown={(e) => handleKeyDown(e)} onFocus={(e) => handleRedirect(e)} placeholder="Search for a word ..." id="form-control-navbar" className="mr-sm-2 searchbarfull" />
                    {upperDisplay && (
                        <div className="autoContainer">
                            { words.map(word => {return( 
                                <div onClick={() => setSearchField(word.word)} className="option" key={word.word}>
                                    <span>{word.word}</span>
                                </div>)
                            })}
                            <div>{loading && 'Loading...'}</div>
                            <div>{!loading && !hasMore && <>Word not found. <Link style={{textDecoration: 'underline'}} to={`/newword/${newword}`}>Define it.</Link></>}</div>
                        </div>
                    )}
                    <Nav>
                    <Nav.Link style={{ display : localStorage.getItem('token') ? 'none' : 'block', paddingRight: 0}}>
                        <Link className='nav-login' to="/login">Login</Link>
                    </Nav.Link>
                    <Nav.Link style={{ display : userDets.role === 'admin' || userDets.role === 'superadmin' ? 'block' : 'none' }}>
                        <Link className='nav-login' to="/admin/pending">Admin</Link>
                    </Nav.Link>
                    <Nav.Link style={{ display : localStorage.getItem('token') ? 'block' : 'none', paddingRight: 0, paddingLeft: 0}}>
                        <Link className='nav-login' id="hiddenNavs" to="/editprofile">Settings</Link>
                    </Nav.Link>
                    <Nav.Link style={{ display : localStorage.getItem('token') ? 'block' : 'none', paddingRight: 0, paddingLeft: 0}}>
                        <Link className='nav-login' id="hiddenNavs" to={`/profilepage/${userDets.username}`}>Profile</Link>
                    </Nav.Link>
                    <Nav.Link style={{ display : localStorage.getItem('token') ? 'block' : 'none', paddingRight: 0, paddingLeft: 0}}>
                        <Link className='nav-login' id="hiddenNavs" onClick={AppContext.logout}>Logout</Link>
                    </Nav.Link>
                    <Nav.Link>
                        <Link className='nav-login' id="hiddenNavs" to="/help">Help</Link>
                    </Nav.Link>
                    <Nav.Link>
                        <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={popover} >
                            <img src={Hamburger} alt="menu-icon" className="hamburger" style={{width: 25+'px',marginTop: 5+'px'}} />
                        </OverlayTrigger>
                    </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <GoogleAds/>
        </>
    );
}

export default NavBar;