import React from "react";
import "./pagenation.scss";
import $ from 'jquery'

const Pagination = ({ pages, handleClick, active }) => {

  $(document).ready(function(e) {
    // Get the container element
    var btnContainer = document.getElementById("myDiv");

    // Get all buttons with class="btn" inside the container
    var btns = btnContainer.getElementsByClassName("btn");

    // Loop through the buttons and add the active class to the current/clicked button
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("mousedown", function() {
        var current = document.getElementsByClassName("active-page");

        // If there's no active class
        if (current.length > 0) {
          current[0].className = current[0].className.replace(" active-page", "");
        }

        // Add the active class to the current/clicked button
        this.className += " active-page";
        console.log('active')
      });
    }
  })
  
  return (
    <>
      <div className="pagination" id="myDiv">
        {pages.map((item, index) => (
          <li key={index} value={item} className="btn" onClick={handleClick}>
            {item}
          </li>
        ))}
      </div>
    </>
  );
};

export default Pagination;
