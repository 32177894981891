import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import './trendscard.scss'
import {slice, concat} from 'lodash';
import Card from 'react-bootstrap/Card';
import $ from 'jquery'

const TrendsCard = ({usage, tweets, similarTrends, alsoSee, memes, title, definition, origin, timelineStart, timelineEnd, writerId, writerName, createdAt, updatedAt, slug}) => {
    
    // $(document).ready(function(){
    //     if (tweets.length > 0){
    //         for (let x = 0; x < tweets.length; x++){
    //             var tweetHtml = $.parseHTML( tweets[x] )
    //             document.getElementById('tweet-section').append( tweetHtml ); 
    //         }
    //     }
    // })   
    
    useEffect(() => {
    if (window.twttr) {
        window.twttr.widgets.load();
      }
    }, []);

    return (
    <div className="yoodict">
        <Card className="trends-card">
        <Card.Body>
            <Card.Title className="trends-section">
                <div className="trends-word">{title}<sup>origin | {origin}</sup></div>
                <div className="trend-timeline">{timelineStart} - {timelineEnd}</div>
                <div className="trend-definition">
                    <div className="trend-definition-title">Definition</div>
                    <div className="trend-definition-def">
                        {definition}
                    </div>
                </div>
                <div className="trend-usage">
                    <div className="trend-usage-title">Usage</div>
                        {
                            usage.map((item, i) => <div key={i} className="trend-usage-example">{item}</div>)
                        }
                </div>
                    {
                        tweets.length > 0 ?
                        <>
                        {
                            tweets.map((item) => 
                            <div id="tweet-section">
                                <div dangerouslySetInnerHTML={{__html: item}} /> 
                            </div>
                        )}
                        </>
                        : <></>
                    }
                <div className="more-trends" style={{display: alsoSee.length > 0 ? 'block' : 'none'}}> 
                    {
                        alsoSee.length > 0 ?
                        <span className="also-see">Also see: 
                        {alsoSee.map((item) => <Link key={item.slug} to={`/yootrends/trend/${item.slug}`}> { item.title} </Link>)}
                        </span>
                        : <></>
                    }
                    <></>
                </div>
                <div className="meme-section">
                    {
                        memes.length > 0 ? 
                        <>
                        <div className="meme-section-header">Memes</div>
                        {memes.map((imgsrc) => <img key={imgsrc} src={imgsrc} alt="memes" className="memes" />)}
                        </>
                        :<></>
                    }
                    {
                        similarTrends.length > 0 ?
                        <div className="similar-trends">Similar:
                        {similarTrends.map((item) => <div key={item.slug} className="similar-chips"><Link to={`/yootrends/trend/${item.slug}`}> { item.title}</Link></div>)}
                        </div>
                        : <></>
                    }
                </div>
            </Card.Title>
        </Card.Body>
            <Card.Footer>
                <div className="trends-footer">
                Written by <Link>{writerName} {(new Date(createdAt)).toLocaleDateString()}</Link>
                </div>
            </Card.Footer>
        </Card>
    </div>
  );
}

export default TrendsCard;