import React, {useState} from 'react'
import {Switch, Route, useHistory, Redirect} from 'react-router-dom'
import axios from 'axios'
import API_BASE from './apiBase'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import HomepageAlphabetical from './pages/homepage/homepage-alpha'
import Homepage from './pages/homepage/homepage'; 
import LoginPage from './pages/LoginPage/loginpage'
import SignUpPage from './pages/SignUpPage/signuppage'
import PrivacyPolicyPage from './pages/PrivacyPolicy/privacy'
import TandC from './pages/TandC/TandC'
import HelpPage from './pages/helppage/helppage'
import NewWordPage from './pages/newword/newword'
import ForgotPassword from './pages/forgotpassword/forgotpassword'
import ResetPassword from './pages/resetpassword/resetpassword'
import EditProfilePage from './pages/profile/editprofile'
import ProfilePage from './pages/profile/viewprofile'
import YooTrendsPage from './pages/yootrends/yootrends'
import YooTrendsPageSlug from './pages/yootrends-slug/yootrends-slug'
import NewLandingPage from './pages/NewLandingPage/new-landing-page'
import AdminPage from './pages/adminpage/adminpage' 
import ApprovedPage from './pages/adminpage/approvedpage'
import UnapprovedPage from './pages/adminpage/unapprovedpage'
import AddWordofTheWeekPage from './pages/adminpage/wow'
import AddTrendWordofTheWeekPage from './pages/adminpage/tow'
import AddNewAdmin from './pages/adminpage/addadmin'
import TrendPage from './pages/adminpage/trendspage'
import PrivateRoute from './privateroute'
import AdminRoute from './adminroute'
import HomepageSlug from './pages/homepage-slug/homepage-slug'
import VerifiedRoute from './verifiedroute'
import { ToastContainer, toast as Toastify } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import verifyMail from './pages/verifymail/verifymail';
import NotFound from './NotFound'
import MetaDecorator from './MetaDecorator'
import UserVerify from './pages/adminpage/userverify'
import UsersPage from './pages/adminpage/users'
import { SpinnerContainer, SpinnerOverlay } from './components/spinner/spinner.styles';
import { useEffect } from 'react';

export const AuthContext = React.createContext();

export const newAxios = axios.create();
newAxios.interceptors.request.use((config)=>{
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
})

const clearWaitingQueue = () => {
  Toastify.dismiss();
}

export const ErrorAlert = (message) => {
  clearWaitingQueue();
  Toastify.error(message, {
  position: "bottom-center",
  hideProgressBar: false,
  autoClose: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
})};
  
export const SuccessAlert = (message) => {
  clearWaitingQueue();
  Toastify.success(message, {
  position: "bottom-center",
  hideProgressBar: false,
  autoClose: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
})};

const App = (props) => {
  const [authTokens, setAuthTokens] = useState(localStorage.getItem("token") || "");
  const [user, setUser] = useState((localStorage.getItem("user")) || {})
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [fetchingDetails, setFetchingDetails] = useState(true);

  const history = useHistory();

  useEffect(() => {
  const getData = async () => {
    await newAxios.get(`${API_BASE}/auth/profile`)
        .then(response => {
            const token  = response.data.token;
            const user  = response.data.user;
            localStorage.setItem("token", token)
            localStorage.setItem("user", JSON.stringify(user))
            setUser(user)
            setAuthTokens(token)
            setLoggedIn(true)
        })
        .catch((err) => {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          setAuthTokens('')
          setUser({}) 
          setLoggedIn(false)
          history.push(history.location.pathname)
          })
          .finally(() => setFetchingDetails(false))
  }
  getData();
  }, [])

  const postSignUp = (email, username, password) => {
    newAxios.post(`${API_BASE}/auth/register`, {username: username, email: email, password: password})
        .then(response => {
            const token  = response.data.token;
            const user  = response.data.user;
            localStorage.setItem("token", token)
            localStorage.setItem("user", JSON.stringify(user))
            SuccessAlert(response.data.message)
            setUser(user)
            setAuthTokens(token)
            setLoggedIn(true)
        })
        .catch((err) => {
            if(err.response){
              if (err.response.status !== 422){
                ErrorAlert(err.response.data.error)
              }
            }
          })
  }

  const postLogin = (email, password) => {
    newAxios.post(`${API_BASE}/auth/login`, {user: email, password: password})
        .then(response => {
            SuccessAlert(response.data.message)
            const token = response.data.token;
            const user = response.data.user;
            localStorage.setItem("token", token)
            localStorage.setItem("user", JSON.stringify(user))
            setUser(user)
            setAuthTokens(token)
            setLoggedIn(true)
        })
        .catch((err) => {
            if(err.response){
              console.log(err.response.data)
              if (err.response.status === 401 || err.response.status === 400){
                // setErrorMessge(err.response.data.error)
                ErrorAlert(err.response.data.error)
              }
            }
          })
  }

  const passwordReset = (email, resetLinkBase) => {
    newAxios.post(`${API_BASE}/auth/password/reset`, {user: email, resetLinkBase: resetLinkBase})
        .then(response => {
            console.log(response.data.message)
            SuccessAlert(response.data.message)
        })
        .catch((err) => {
            if(err.response){
              console.log(err.response.data.error)
              ErrorAlert(err.response.data.error)
            }
          })
  }

  const newPassword = (newPassword, resetToken) => {
    newAxios.put(`${API_BASE}/auth/password/reset`, {newPassword: newPassword,resetToken: resetToken})
        .then(response => {
            SuccessAlert(response.data.message)
        })
        .catch((err) => {
            if(err.response){
              ErrorAlert(err.response.data.error)
            }
          })
  }

  const sendHelpMessage = (data) => {
    return newAxios.post(`${API_BASE}/help`, data)
  }
  
  const addNewWord = (newWordObject) => {
    return newAxios.post(`${API_BASE}/word`, {
      word:               newWordObject.word,
      meaning:            newWordObject.meaning,
      similarWords:       newWordObject.similarWords, 
      origin:             newWordObject.origin, 
      examples:           newWordObject.examples,
      literalTranslation: newWordObject.literalTranslation,
      phonetics:          newWordObject.phonetics
    })
  }

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    setAuthTokens('')
    setUser({}) 
    setLoggedIn(false)
    history.push("/login")
  }
  if(fetchingDetails)return (
  <div style={{paddingTop : '45vh'}}>
    <SpinnerOverlay>
        <SpinnerContainer/>
    </SpinnerOverlay> 
  </div>)
  return (
    <AuthContext.Provider value={{ 
      signup: postSignUp,
      login: postLogin,
      logout: logout,
      passwordreset: passwordReset,
      setNewPassword: newPassword,
      authTokens,
      user,
      addNewWord: addNewWord,
      sendHelpMessage : sendHelpMessage, 
      isLoggedIn
      }}>
      <div className="App">
      <MetaDecorator title="Urbanyooba" description="A slang dictionary for buzzwords and latest trends."/>
        <Switch>
          <Route exact path="/" component={NewLandingPage} />
          <Route exact path="/yoodict" component={Homepage} />
          <Route exact path="/yoodict/alphabet/:alphabet"  render={(props) => <HomepageAlphabetical {...props} key={props.match.params.alphabet}/>}/>
          <Route exact path="/yoodict/word/:slug" render={(props) => <HomepageSlug {...props} key={props.match.params.slug}/>}/>
          <Route path="/login" render={(props) => <LoginPage {...props}/>} />
          <Route path="/signup" component={SignUpPage}/>
          <Route path="/privacypolicy" component={PrivacyPolicyPage}/>
          <Route path="/termsandconditions" component={TandC} />
          <Route path="/help" component={HelpPage} />
          <Route path="/mail/verify" component={verifyMail} />
          <PrivateRoute path="/newword/:word" component={NewWordPage} />
          <PrivateRoute path="/newword" component={NewWordPage} />
          <Route path="/forgotpassword" component={ForgotPassword}/>
          <Route path="/passwordreset" component={ResetPassword} />
          <PrivateRoute path="/editprofile" component={EditProfilePage} />
          <Route path="/profilepage/:username"  component={ProfilePage} />
          <Route exact path="/yootrends" component={YooTrendsPage} />
          <Route exact path="/yootrends/trend/:slug" render={(props) => <YooTrendsPageSlug {...props} key={props.match.params.slug}/>} />
          <Route exact path="/admin" render={(props) => <Redirect {...props} to="/admin/pending"/>}/>
          <AdminRoute path="/admin/pending" component={AdminPage} />
          <AdminRoute path="/admin/approved" component={ApprovedPage}/>
          <AdminRoute path="/admin/unapproved" component={UnapprovedPage}/>
          <AdminRoute path="/admin/addwordofweek" component={AddWordofTheWeekPage}/>
          <AdminRoute path="/admin/addtrendofweek" component={AddTrendWordofTheWeekPage}/>
          <AdminRoute path="/admin/addnewadmin" component={AddNewAdmin} />
          <AdminRoute path="/admin/createtrend" component={TrendPage} />
          <AdminRoute path="/admin/userverify" component={UserVerify}/>
          <AdminRoute path="/admin/users" component={UsersPage}/>
          <Route component={NotFound} />
        </Switch>
        <ToastContainer limit={1}/>
      </div>
    </AuthContext.Provider>
  );
}

export default App;
