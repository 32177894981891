import React, { useEffect, useState, useContext } from 'react'

import './adminpage.scss'
import YoodictLogo from '../../icons/yoodict.svg'
import AdminNavBar from '../../components/adminnavbar/adminnavbar'
import FormControl from 'react-bootstrap/FormControl'
import AddIcon from '../../icons/plusicon.svg'
import SpecButton from './button'
import { newAxios, ErrorAlert, SuccessAlert } from '../../App'
import API_BASE from '../../apiBase'
import {Link, NavLink} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import PurpleHamburger from '../../icons/purplehamburger.svg'
import {AuthContext} from '../../App'
import CloseModal from "../../icons/close.svg";

const AddNewAdmin = () => {
    const name = 'ADD'
    const [modalShow, setModalShow] = useState(false);
    const [admins, setAdmins] = useState([]);
    const [username, setUsername] = useState('');
    const user = JSON.parse(localStorage.getItem('user'))
    const AppContext = useContext(AuthContext)
    const userDets = JSON.parse(localStorage.getItem('user'))
    const [adminToDelete, setAdminToDelete] = useState('')

    useEffect(() => {
        getAdmins()
    }, [])

    const getAdmins = () => {
        newAxios.get(`${API_BASE}/auth/admins`)
        .then(response => {
            setAdmins(response.data.admins)
        })
        .catch(err => console.log(err))
    }

    const addAdmin = () => {
        if(!username.trim()) return;
        newAxios.put(`${API_BASE}/auth/admin`, {
            username
        })
        .then(response => {
            SuccessAlert(response.data.message)
            getAdmins()
        })
        .catch(err => {
            // console.log(err.response.data.error)
            ErrorAlert(err.response.data.error)
        })
    }

    const deleteAdmin = () => {
        if(!adminToDelete.trim()) return;
        console.log(adminToDelete)
        newAxios.delete(`${API_BASE}/auth/admin?username=${adminToDelete}`)
        .then(response => {
            SuccessAlert(response.data.message)
            getAdmins()
            setModalShow(false)
        })
        .catch(err => {
            ErrorAlert(err.response.data.error)
        })
        
    }
    
    const confirmSubmit = (username) => {
        setModalShow(true);
        setAdminToDelete(username)
    };

    const cancelDelete = () => {
        setModalShow(false);
        setAdminToDelete('')
    }

    const [showSidebar, setShowSidebar] = useState(false)
    function NavbarModal(props) {
        return (
          <Modal
            {...props}
            size="lg"
            dialogClassName="nav-modal"
          >
            {/* <Modal.Body  className=""> */}
            <div className="admin-navbar-minimized">
                <div className="top-section">
                    <div className="admin-logo">A</div>
                    <div className="admin-welcome">Welcome Admin</div>
                </div>
                <div className="section-header">DASHBOARD</div>
                    <div className="upper-section" id="upper-section">
                        <div id="1" className="admin-nav" ><NavLink activeClassName="active" to="/admin/pending">PENDING WORDS</NavLink></div>
                        <div id="2" className="admin-nav" ><NavLink activeClassName="active" to="/admin/approved">APPROVED</NavLink></div>
                        <div id="3" className="admin-nav" ><NavLink activeClassName="active" to="/admin/unapproved">UNAPPROVED</NavLink></div>
                    </div>
                    <div className="middle-section" id="middle-section">
                        <div id="7" className="admin-nav"><NavLink activeClassName="active" to="/admin/createtrend">TRENDS</NavLink></div>
                        <div id="4" className="admin-nav"><NavLink activeClassName="active" to="/admin/addwordofweek">WORD OF THE WEEk</NavLink></div>
                        <div id="5" className="admin-nav"><NavLink activeClassName="active" to="/admin/addtrendofweek">TREND OF THE WEEK</NavLink></div>
                        <div id="6" className="admin-nav"><NavLink activeClassName="active" to="/admin/addnewadmin">ADD AN ADMIN</NavLink></div>
                        <div id="8" className="admin-nav"><NavLink activeClassName="active" to="/admin/userverify">VERIFY USER</NavLink></div>
                        <div id="9" className="admin-nav"><NavLink activeClassName="active" to="/admin/users">USERS</NavLink></div>
                    </div>
                    <div className="lower-section">
                        <div className="admin-name">{userDets.username}</div>
                        <div className="button-container"><Button  onClick={AppContext.logout} className="admin-logout">Logout</Button></div>
                    </div>
            </div>
          </Modal>
        );
      }
      
    function ConfirmationModal(props) {
        return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header>
            <div className="close-modal-button">
                <img alt="close modal icon" onClick={() => cancelDelete()} src={CloseModal} />
            </div>
            </Modal.Header>
            <Modal.Body>
            <h4>Are you sure you want to remove admin?</h4>
            <div className="confirmation-buttons">
                <Button id="sumbitYes" type="" onClick={() => deleteAdmin()}>
                <span className="submit-yes">YES</span>
                </Button>
                <Button id="sumbitNo" type="" onClick={() => cancelDelete()}>
                <span className="submit-no">NO</span>
                </Button>
            </div>
            </Modal.Body>
        </Modal>
        );
    }

    return (
        <>
            <div className="admin-panel">
                <AdminNavBar className="admin-navpanel" id={6}/>
                <div className="navint-nav">
                    <div className="navint-right">
                    <Link to="/yoodict"><img className="navint-logo" alt="yoodict-logo" src={YoodictLogo} /></Link>
                        <span className="navint-header">Admin Dashboard</span>
                    <img onClick={() => setShowSidebar(true)} alt="admin-minimized-menu-icon" className="res-nav-toggle" src={PurpleHamburger} />
                    </div>
                    <div className="navint-headers-type2">
                        <span className="header-wordname">Add Admin</span><br/>
                    </div>
                    <div className="addnewadmin">
                        <div style={{width: 'max-content', margin: 'auto'}}>
                        <div  className="addadmin-row3">
                            <span className="add-admin">Add an admin </span><img className="add-icon" alt="Yoodict" src={AddIcon} />
                            <FormControl onChange={e => setUsername(e.target.value)} type="text" id="addadmin-mail" placeholder="Enter username to add" />
                            <SpecButton onClick={() => addAdmin()} name={name}/>
                        </div>
                        <div className="addadmin-row1">
                            <span className="admin-count">No of Admins - {admins.length}</span>
                        </div>
                        <div className="addadmin-row2">
                            <span className="admins">
                                {admins.filter(ad => ad._id !== user.id).map(a => {
                                    return (<span style={{display : 'block'}} key={a._id}>{a.username} 
                                    <span style={{display: userDets.role === 'superadmin' ? 'inline-block' : 'none'}} onClick={() => confirmSubmit(a.username)} class="delete-admin">(delete)</span> ,</span>)
                                })}
                                and you.
                            </span>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmationModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        <NavbarModal pageName="" show={showSidebar} onHide={() => setShowSidebar(false)}/>
        </>
    )
}

export default AddNewAdmin 