import React from 'react'

import './helppage.scss'

import HelpBanner from '../../components/helpbanner/helpbanner'
import HelpForm from '../../components/helpform/helpform'
import Footer from '../../components/footer/footer'
import NavBar from '../../components/navbar/navbar'

const HelpPage = () => {

    const dormantFunction = () => {
        return
    }
    
    const header=""
    return (
        <>
            <NavBar altUpperDisplay={dormantFunction} onSearch={dormantFunction} onClick={dormantFunction}/>
            <HelpBanner header={header}/>
            <HelpForm/>
            <Footer/>
        </>
    )
}

export default HelpPage