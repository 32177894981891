import React, {useState} from 'react'

import {Link, Redirect, withRouter, useLocation} from 'react-router-dom'
import './signup.scss'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {AuthContext, ErrorAlert} from '../../App'
import { toast as Toastify } from 'react-toastify';


const SignUpForm = (props) => {

    const [email, setEmail] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [isPasswordShown, setIsPasswordShown] = useState(false)
    
    const AppContext = React.useContext(AuthContext)

    const clearInputs = () => {
        document.getElementById("signupForm").reset();
    }

    const clearWaitingQueue = () => {
      Toastify.dismiss();
    }

    var URL = useLocation();

    const { from } = props.location.state || { from: { pathname: '/yoodict' } }

    if (AppContext.isLoggedIn === true) {
      return <Redirect to={from} />
    }

    const showPassword = () => {
        setIsPasswordShown(prevState => !prevState)
    }

    const validateForm = () => {
        let valid = (/^[a-zA-Z0-9]*$/).test(username.trim());
        if(!valid) {
          ErrorAlert('Username can only contain alphanumeric characters')
          return;
        }
        if ((username.trim().length < 3) && (password.length < 7)){
            ErrorAlert('Username must be at least 3 characters and Password must be at least 7 characters')
            return
          }
        else if (username.trim().length < 3){
            ErrorAlert('Username must be at least 3 characters')
            return
          } 
        else if (password.length < 7){
            ErrorAlert('Password must be at least 7 characters')
            return
          } 
          else {
            AppContext.signup( email, username, password);
          }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        validateForm();
    }
    return (
        <>
            <div className="signup-form">
            <Form id="signupForm" onSubmit={(e) => handleSubmit(e)}>
            <div className="form-header">SIGN UP</div>
            
                <Form.Group controlId="formBasicUsername">
                    <Form.Label>Username</Form.Label>
                    <Form.Control size="lg" type="text" onChange={e => {setUsername(e.target.value)}} required placeholder="" />
                    <Form.Text className="text-muted-left">
                        Username must be minimum of 3 characters
                    </Form.Text>
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label> 
                    <Form.Control size="lg" type="email" onChange={e => {setEmail(e.target.value)}} required placeholder="" />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control size="lg" type={isPasswordShown ? "text" : "password"} onChange={e => {setPassword(e.target.value)}} required placeholder="" />
                    <Form.Group controlId="formShowPassword" style={{marginBottom: 0}}>
                    <Form.Check.Input type="checkbox" onClick={showPassword}/>
                        <Form.Check.Label>
                            Show Password
                        </Form.Check.Label>
                    </Form.Group>
                    <Form.Text className="text-muted-left">
                        Password must be minimum of 7 characters
                    </Form.Text>
                </Form.Group>
                <Form.Group controlId="formBasicCheckAgree">
                    <Form.Check.Input type="checkbox" required/>
                        <Form.Check.Label>
                            By creating an account, it means you agree to our {<Link to="/termsandconditions">Terms of Service</Link>} and {<Link to="/privacypolicy">Privacy Policy</Link>}.
                        </Form.Check.Label>
                </Form.Group>
                <Button id="signupButton" type="submit" block>
                    SIGN UP
                </Button>
                <Form.Text className="text-muted-two">
                    Already Registered? <Link to="/login">Log in</Link>
                </Form.Text>
            </Form>
            </div>
        </>
    )
}

export default withRouter(SignUpForm);