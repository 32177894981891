import React, { useState, useEffect, useCallback } from 'react'
import Table from 'react-bootstrap/Table'
import './adminintbar.scss'
import Pagination from "./pagenation"
import {ViewMoreButton} from '../../pages/adminpage/button' 
import './wordupdate.scss'
import WordUpdateModal from './wordupdate'
import Modal from 'react-bootstrap/Modal'
import {newAxios, SuccessAlert, ErrorAlert} from '../../App'
import TrendSuggestTags from '../suggetstags/trendsuggesttag'

const ApprovedContent = ({ isLoading, fetchData, numberOfPages, setFetchPageNumber, contentHeaderArray, contentObject }) => {
    const [modalShow, setModalShow] = useState(false);
    const [trendModal, setTrendModal] = useState(false)
    const [tags, setTags] = useState([])
    const [slug, setSlug] = useState([])
    const [presetWord, setPresetWord] = useState('')

    let total = numberOfPages;
    const [active, setActive] = useState(1);
    const [pages, setPages] = useState([]);

    useEffect(() => {
        if (total <= 7) {
        let arr = [];
        for (let i = 1; i <= total; i++) {
            arr.push(i);
        }
        setPages(arr);
        return;
        }
        if (active <= 4) {
        setPages([1, 2, 3, 4, 5, "...", total]);
        }
        if (active > 4 && active < total - 3) {
        setPages([1, "...", active - 1, active, active + 1, "...", total]);
        }
        if (active > total - 4) {
        setPages([1, "...", total - 4, total - 3, total - 2, total - 1, total]);
        }
    }, [active, total]);

    const handleClick = useCallback(e => {
        if (e.target.value === 0) {
        return;
        }
        setActive(e.target.value);
        setFetchPageNumber(e.target.value)
    }, [setFetchPageNumber]);
    
    const selectedSlug = (slug) => {
        setSlug(slug)
    }

    const addNewTrend = (word) => {
        if (tags.length > 1){
            return ErrorAlert("Choose one trend only")
        } else if (tags.length < 1) {
            return ErrorAlert("choose a trend")
        }
        newAxios.put("https://yoodict.herokuapp.com/api/word/addTrend", {trend: tags[0]._id, word: word.id})
        .then(response => {
            SuccessAlert(response.data.message)
        }).catch(err => console.log(err))
    }

    const closeModal = () => {
        setModalShow(false)
    }

    const [WordDetailsObject, setWordDetailsObject] = useState({    
        word:     '',
        meaning:  '',
        origin:   '',
        examples: [],
        similarWords: [],
        literalTranslation: '',
        phonetics: ''
    })
    
    const handlePopUp = (id, word, meaning, origin, examples, similarWords, literalTranslation, phonetics) => {
        setWordDetailsObject({    
            id: id,
            word: word,
            meaning: meaning,
            origin: origin,
            examples: examples,
            similarWords: similarWords,
            literalTranslation: literalTranslation,
            phonetics: phonetics
        })
        
        setModalShow(true)
    }

    const handleUpdateTrendPopUp = (word) => {
        setTrendModal(true)
        setPresetWord(word)
    }

    function WordObjectModal(props) {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="wordupdate-modal"
            backdropClassName="backdrop-modal-content"
          >
            <WordUpdateModal CloseModal={closeModal} fetchData={fetchData} WordDetailsObject={WordDetailsObject} popUpTitle="Approved Word" button3="UPDATE" button2="UNAPPROVE" button4="DELETE" />
          </Modal>
        );
      }

    function TrendModal (props) {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="trendupdate-modal"
            backdropClassName="backdrop-modal-content"
          >
                <div className="addword-panel">
                    <div className="trendsearch-container">
                    <div id="addtrend-search">
                    <TrendSuggestTags selectedSlug={selectedSlug} slug={slug} setTags={setTags} tags={tags} />
                    </div>
                    <span className="update-button" onClick={() => addNewTrend(presetWord)}>Update</span>
                    </div>
                </div>
            </Modal>
        );
    }
            return (
                <>
                    <div className="words-table">
                        <Table>
                            <thead>
                                <tr>
                                    {
                                        contentHeaderArray.map((header, index) => (
                                            <th key={index}>{header}</th>
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                    {contentObject.map((word, index) => (
                                        <tr key={word.id}>
                                            <td>{word.postedByEmail}</td>
                                            <td className="split-view">{word.word} <ViewMoreButton onClick={() => handlePopUp(word.id, word.word, word.meaning, word.origin, word.examples, word.similarWords, word.literalTranslation, word.phonetics)} name="UPDATE" /></td>
                                            <td>{word.upvoteCount}</td>
                                            <td>{word.downvoteCount}</td>
                                            <td>{word.trend ? (<>ADDED <ViewMoreButton onClick={() => handleUpdateTrendPopUp(word)} name="UPDATE"/></>) : (<>NONE <ViewMoreButton onClick={() => handleUpdateTrendPopUp(word)} name="ADD TREND"/></>)}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                        <Pagination
                            handleClick={handleClick}
                            pages={pages}
                            active={active}
                        />
                      </div>
                    <WordObjectModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    />
                    <TrendModal
                    show={trendModal}
                    onHide={() => setTrendModal(false)}
                    />
                </>

            )
}

export default ApprovedContent