import React, { useEffect, useState, useContext } from 'react'

import './adminpage.scss'
import YoodictLogo from '../../icons/yoodict.svg'
import AdminNavBar from '../../components/adminnavbar/adminnavbar'
import FormControl from 'react-bootstrap/FormControl'
import AddIcon from '../../icons/plusicon.svg'
import SpecButton from './button'
import { newAxios, ErrorAlert, SuccessAlert } from '../../App'
import API_BASE from '../../apiBase'
import {Link, NavLink} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import PurpleHamburger from '../../icons/purplehamburger.svg'
import {AuthContext} from '../../App'


const UserVerify = () => {
    const name = 'VERIFY'
    const [verifiedUsers, setVerifiedUsers] = useState([]);
    const [username, setUsername] = useState('');
    const user = JSON.parse(localStorage.getItem('user'))
    const [pageNumber, setPageNumber] = useState(1)
    const [verifiedUrl, setVerifiedUrl] = useState(`auth/users/verified?page=${pageNumber}`)
    const AppContext = useContext(AuthContext)
    const userDets = JSON.parse(localStorage.getItem('user'))
    
    useEffect(() => {
        getVerifiedUsers()
    }, [])

    const getVerifiedUsers = () => {
        newAxios.get(`${API_BASE}/${verifiedUrl}`)
        .then(response => {
            // console.log(response.data.users)
            setVerifiedUsers(response.data.users)
        })
        .catch(err => console.log(err))
    }
    const verifyUser = () => {
        if(!username.trim()) return;
        newAxios.put(`${API_BASE}/auth/user/verify`, {
            username
        })
        .then(response => {
            SuccessAlert(response.data.message)
            getVerifiedUsers()
        })
        .catch(err => {
            console.log(err.response.data)
            ErrorAlert(err.response.data.error)
        })
        .finally(
            () => {document.getElementById('verification-input').value = ""}
        )
    }
    const unVerifyUser = () => {
        if(!username.trim()) return;
        newAxios.put(`${API_BASE}/auth/user/unverify`, {
            username
        })
        .then(response => {
            SuccessAlert(response.data.message)
            getVerifiedUsers()
        })
        .catch(err => {
            console.log(err.response.data)
            ErrorAlert(err.response.data.error)
        })
        .finally(
            () => {document.getElementById('verification-input2').value = ""}
        )
    }
    
    const [showSidebar, setShowSidebar] = useState(false)
    function NavbarModal(props) {
        return (
          <Modal
            {...props}
            size="lg"
            dialogClassName="nav-modal"
          >
            {/* <Modal.Body  className=""> */}
            <div className="admin-navbar-minimized">
                <div className="top-section">
                    <div className="admin-logo">A</div>
                    <div className="admin-welcome">Welcome Admin</div>
                </div>
                <div className="section-header">DASHBOARD</div>
                    <div className="upper-section" id="upper-section">
                        <div id="1" className="admin-nav" ><NavLink activeClassName="active" to="/admin/pending">PENDING WORDS</NavLink></div>
                        <div id="2" className="admin-nav" ><NavLink activeClassName="active" to="/admin/approved">APPROVED</NavLink></div>
                        <div id="3" className="admin-nav" ><NavLink activeClassName="active" to="/admin/unapproved">UNAPPROVED</NavLink></div>
                    </div>
                    <div className="middle-section" id="middle-section">
                        <div id="7" className="admin-nav"><NavLink activeClassName="active" to="/admin/createtrend">TRENDS</NavLink></div>
                        <div id="4" className="admin-nav"><NavLink activeClassName="active" to="/admin/addwordofweek">WORD OF THE WEEk</NavLink></div>
                        <div id="5" className="admin-nav"><NavLink activeClassName="active" to="/admin/addtrendofweek">TREND OF THE WEEK</NavLink></div>
                        <div id="6" className="admin-nav"><NavLink activeClassName="active" to="/admin/addnewadmin">ADD AN ADMIN</NavLink></div>
                        <div id="8" className="admin-nav"><NavLink activeClassName="active" to="/admin/userverify">VERIFY USER</NavLink></div>
                        <div id="9" className="admin-nav"><NavLink activeClassName="active" to="/admin/users">USERS</NavLink></div>
                    </div>
                    <div className="lower-section">
                        <div className="admin-name">{userDets.username}</div>
                        <div className="button-container"><Button  onClick={AppContext.logout} className="admin-logout">Logout</Button></div>
                    </div>
            </div>
          </Modal>
        );
      }

    return (
        <>
            <div className="admin-panel">
                <AdminNavBar className="admin-navpanel" id={6}/>
                <div className="navint-nav">
                    <div className="navint-right">
                    <Link to="/yoodict"><img className="navint-logo" alt="yoodict-logo" src={YoodictLogo} /></Link>
                        <span className="navint-header">Admin Dashboard</span>
                    <img onClick={() => setShowSidebar(true)} alt="admin-minimized-menu-icon" className="res-nav-toggle" src={PurpleHamburger} />
                    </div>
                    <div className="navint-headers-type2">
                        <span className="header-wordname">Verify User</span><br/>
                    </div>
                    <div className="addnewadmin">
                        <div style={{width: 'max-content', margin: 'auto'}} className="holding-class">
                        <div  className="verifyuser-row3">
                            <span className="add-admin">Verify User </span><img className="add-icon" alt="Yoodict" src={AddIcon} />
                            <FormControl onChange={e => setUsername(e.target.value)} type="text" id="verification-input" placeholder="Enter username to verify" />
                            <SpecButton onClick={() => verifyUser()} name={name}/>
                        </div>
                        <div  className="verifyuser-row3">
                            <span className="add-admin">Unverify User </span>
                            {/* <img className="add-icon" alt="Yoodict" src={AddIcon} /> */}
                            <FormControl onChange={e => setUsername(e.target.value)} type="text" id="verification-input2" placeholder="Enter username to unverify" />
                            <SpecButton onClick={() => unVerifyUser()} name="UNVERIFY"/>
                        </div>
                        <div className="verifyuser-row1">
                            <span className="admin-count">Verified Users</span>
                        </div>
                        <div className="verifyuser-row2">
                            <span className="admins">
                                {verifiedUsers.map(a => {
                                    return (<span style={{display : 'block'}} key={a._id}>{a.username}</span>)
                                })}
                            </span>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        <NavbarModal pageName="" show={showSidebar} onHide={() => setShowSidebar(false)}/>
        </>
    )
}

export default UserVerify 