import React from 'react'
import {Link} from 'react-router-dom'

import './profileinfo.scss'
import Card from 'react-bootstrap/Card'
import BackButton from '../../icons/right-chevron (1).png'
import WordsDef from './wordsdef'
import VerificationBadge from '../../icons/verifycheck.svg'
import {SpinnerContainer, SpinnerOverlay} from '../spinner/spinner.styles' 

const ProfileInfo = ({userData, cardNumber, wordDefined, isLoading}) => {

    return (
        <>
            <React.Fragment>
                {!isLoading ? (
                    
                    <>
                        <div>
                        <Card className="profile-section addbottomborder">
                        <Card.Body style={{paddingBottom: 0+'rem'}}>
                            <Card.Title className="psection-head">
                                <div className="psection-row-1">
                                    <div className="psection-icon">
                                        <h4>{userData.username[0]}</h4>
                                    </div>
                                <div className="name-sect">
                                    <div className="psection-username">
                                        {userData.username}
                                        <img style={{display: userData.verified === true ? 'inline-block' : 'none'}} src={VerificationBadge} className="profile-verification-icon" alt="verification badge"/>  
                                    </div>
                                    <div className="psection-joindate">Joined {(new Date(userData.joined)).toLocaleDateString()}</div>
                                </div>
                                </div>
                                <div className="row-3">
                                    <div className="nav-back-pf">
                                        <Link to="/yoodict"><img src={BackButton} alt="back to yoodict" className="back-button-pf" />BACK TO DICTIONARY </Link>
                                    </div>
                                </div>
                            </Card.Title>
                            <div className="psection-wd">Words Defined</div>
                        </Card.Body>
                        </Card>
                    </div>
                    <WordsDef wordsDefined={wordDefined} cardNumber={cardNumber}/>
                </>
                )
                : (
                    <SpinnerOverlay>
                    <SpinnerContainer/>
                    </SpinnerOverlay> 
                )}
            </React.Fragment>
             
        </>
    )
}

export default ProfileInfo;