import React, {useEffect, useState} from 'react';

import {Link} from 'react-router-dom'
import './bannersection.scss'
import BannerIcon from '../../icons/yoodict.png'
import BannerAdvert from '../../icons/banner2.jpg'
// import EditIcon from '../../icons/edit word.png' 
import axios from 'axios'
import API_BASE from '../../apiBase';
import {SpinnerContainer, SpinnerOverlay} from '../spinner/spinner.styles'

import GoogleAds from '../googleads/googleads'

const BannerSection = (props) => {
  const [wow, setWow] = useState('')

  useEffect(() => {
    async function fetchData() {
        const fullResponse = await axios.get(`${API_BASE}/wordOfTheWeek`)
        const responseData = await fullResponse.data
        setWow(responseData.word)
    }

    fetchData();
  }, [])
  return (
    <> 
      <React.Fragment>
        { !props.isLoading ? 
           (<>
           <div className="banner-img">
              <img src={BannerIcon} alt="yoodict-banner-icon" className="banner-icon" />
              <div className="banner-title">The buzzwords dictionary</div>
            </div>
            <Link to='/newword' className="wow-link">
              <div className="add-word">
                  Add a new word
              </div>
            </Link>
            {/* w-o-w is an abbrevation for word of the week */}
            <div className="w-o-w">
                <p className="wow-title">Word of the week</p>
                <p className="wow-word"><Link to={wow ? `/yoodict/word/${wow.slug}` : '/yoodict'}>{wow ? wow.word : 'Null'}</Link></p>
                <p className="definition-title">Definition</p>
                <p className="definition-def">{wow ? wow.meaning : 'Null'}</p>
            </div>
            <div className="">
              <img src={BannerAdvert} alt="banner-advert" className="advert-space" />
              <GoogleAds/>
            </div>
            <div className="banner-footer">
                < Link to="/termsandconditions">Terms and Conditions</Link> | <Link to="/help">Help</Link> | <Link to="/privacypolicy">Privacy Policy</Link>
            </div> 
            </>) : 
          ( 
            <SpinnerOverlay>
                <SpinnerContainer/>
            </SpinnerOverlay>
            )
        }
      </React.Fragment>
    </>
  );
}

export default BannerSection;