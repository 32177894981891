import React, { useEffect, useRef } from "react";

const GoogleAds = () => {
  const instance = useRef(null);

  useEffect(() => {
    const tawk = document.createElement("script");
	//Use below syntax to add attributes to the script tag
    tawk.type = "text/javascript";
    tawk.async = true;
    tawk['data-ad-client'] = "ca-pub-6247024763460858";
    tawk.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    tawk.innerHTML = ``;

    instance.current.appendChild(tawk); 
    
  }, []);

  return (
      <div ref={instance}></div>
  );
};

export default GoogleAds;