import React, { useEffect, useState, useContext, useCallback } from 'react'

import './adminpage.scss'
import YoodictLogo from '../../icons/yoodict.svg'
import AdminNavBar from '../../components/adminnavbar/adminnavbar'
import FormControl from 'react-bootstrap/FormControl'
import AddIcon from '../../icons/plusicon.svg'
import SpecButton from './button'
import { newAxios, ErrorAlert, SuccessAlert } from '../../App'
import API_BASE from '../../apiBase'
import {Link, NavLink} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import VerificationBadge from '../../icons/verifycheck.svg'
import Button from 'react-bootstrap/Button'
import PurpleHamburger from '../../icons/purplehamburger.svg'
import {AuthContext} from '../../App'
import Pagination from '../../components/adminintbar/pagenation'
import {SpinnerContainer, SpinnerOverlay} from '../../components/spinner/spinner.styles'
import CloseModal from "../../icons/close.svg";

const UsersPage = () => {
    const name = 'ADD'
    const [modalShow, setModalShow] = useState(false);
    const [users, setUsers] = useState([]);
    const [fetchPageNumber, setFetchPageNumber] = useState(1)
    const [numberOfPages, setNumberOfPages] = useState(1)
    const [isLoading, setIsLoading] = useState(true)
    const [username, setUsername] = useState('');
    const user = JSON.parse(localStorage.getItem('user'))
    const AppContext = useContext(AuthContext)
    const userDets = JSON.parse(localStorage.getItem('user'))        
    const [searchResults, setSearchResults] = useState([]);
    const [fetchUrl, setFetchUrl] = useState(`${API_BASE}/auth/users?page=${fetchPageNumber}`)
    const [query, setQuery] = useState('')
    const [userToDelete, setUserToDelete] = useState('')
    const [userCount, setUserCount] = useState()
    const [verifiedUserCount, setVerifiedUserCount] = useState()

    useEffect(() => {
        handleSearchFetchUrlChange(query)
    }, [query])   

    useEffect(() => {
        getUsers();
    }, [fetchUrl, fetchPageNumber])

    useEffect(() => {
        getUsersCount();
    }, [])
    
    let total = numberOfPages;
    const [active, setActive] = useState(1);
    const [pages, setPages] = useState([]);
    
    useEffect(() => {
        if (total <= 7) {
        let arr = [];
        for (let i = 1; i <= total; i++) {
            arr.push(i);
        }
        setPages(arr);
        return;
        }
        if (active <= 4) {
        setPages([1, 2, 3, 4, 5, "...", total]);
        }
        if (active > 4 && active < total - 3) {
        setPages([1, "...", active - 1, active, active + 1, "...", total]);
        }
        if (active > total - 4) {
        setPages([1, "...", total - 4, total - 3, total - 2, total - 1, total]);
        }
    }, [active, total]);
    
    const confirmSubmit = (username) => {
        setModalShow(true)
        setUserToDelete(username)
    };

    const cancelDelete = () => {
        setModalShow(false)
        setUserToDelete('')
    }

    const handleClick = useCallback(e => {
        if (e.target.value === 0) {
        return;
        }
        setActive(e.target.value);
        setFetchPageNumber(e.target.value)
        setFetchUrl(`${API_BASE}/auth/users?page=${e.target.value}`)
    }, [setFetchPageNumber]);

    const getUsers = () => {
        newAxios.get(fetchUrl)
        .then(response => {
            setUsers(response.data.users)
            setSearchResults(response.data.users)
            setNumberOfPages(response.data.pages)
            setIsLoading(false)
        })
        .catch(err => console.log(err))
    }

    const getUsersCount = () => {
        newAxios.get(`${API_BASE}/auth/users/count`)
        .then(response => {
            setUserCount(response.data.totalRegistered)
            setVerifiedUserCount(response.data.totalEmailVerified)
        })
        .catch(err => console.log(err))
    }

    const deleteUser = (userId) => {
        if (userId !== null){
            newAxios.delete(`${API_BASE}/auth/user?username=${userId}`)
            .then(response => {
                SuccessAlert(response.data.message)
                getUsers()
                getUsersCount()
                setModalShow(false)
            })
            .catch(err => {
                ErrorAlert(err.response.data.error)
            })
        }
    }

    function handleSearchFetchUrlChange(word) {
        // Here, we invoke the callback with the new value
        if(!word.trim()) {
            setFetchUrl(`${API_BASE}/auth/users?page=${fetchPageNumber}`)
        } else {
            setFetchUrl(`${API_BASE}/auth/users/search?username=${word}`);
        }
      
    }
    
    function handleSearch(e) {            
        setQuery(e.target.value)
    }
    
    const [showSidebar, setShowSidebar] = useState(false)
    function NavbarModal(props) {
        return (
          <Modal
            {...props}
            size="lg"
            dialogClassName="nav-modal"
          >
            {/* <Modal.Body  className=""> */}
            <div className="admin-navbar-minimized">
                <div className="top-section">
                    <div className="admin-logo">A</div>
                    <div className="admin-welcome">Welcome Admin</div>
                </div>
                <div className="section-header">DASHBOARD</div>
                    <div className="upper-section" id="upper-section">
                        <div id="1" className="admin-nav" ><NavLink activeClassName="active" to="/admin/pending">PENDING WORDS</NavLink></div>
                        <div id="2" className="admin-nav" ><NavLink activeClassName="active" to="/admin/approved">APPROVED</NavLink></div>
                        <div id="3" className="admin-nav" ><NavLink activeClassName="active" to="/admin/unapproved">UNAPPROVED</NavLink></div>
                    </div>
                    <div className="middle-section" id="middle-section">
                        <div id="7" className="admin-nav"><NavLink activeClassName="active" to="/admin/createtrend">TRENDS</NavLink></div>
                        <div id="4" className="admin-nav"><NavLink activeClassName="active" to="/admin/addwordofweek">WORD OF THE WEEk</NavLink></div>
                        <div id="5" className="admin-nav"><NavLink activeClassName="active" to="/admin/addtrendofweek">TREND OF THE WEEK</NavLink></div>
                        <div id="6" className="admin-nav"><NavLink activeClassName="active" to="/admin/addnewadmin">ADD AN ADMIN</NavLink></div>
                        <div id="8" className="admin-nav"><NavLink activeClassName="active" to="/admin/userverify">VERIFY USER</NavLink></div>
                        <div id="9" className="admin-nav"><NavLink activeClassName="active" to="/admin/users">USERS</NavLink></div>
                    </div>
                    <div className="lower-section">
                        <div className="admin-name">{userDets.username}</div>
                        <div className="button-container">
                            <Button onClick={AppContext.logout} className="admin-logout">Logout</Button>
                        </div>
                    </div>
            </div>
          </Modal>
        );
      }

      
    function ConfirmationModal(props) {
        return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header>
            <div className="close-modal-button">
                <img alt="close modal icon" onClick={() => cancelDelete()} src={CloseModal} />
            </div>
            </Modal.Header>
            <Modal.Body>
            <h4>Are you sure you want to delete user?</h4>
            <div className="confirmation-buttons">
                <Button id="sumbitYes" type="" onClick={() => deleteUser(userToDelete)}>
                <span className="submit-yes">YES</span>
                </Button>
                <Button id="sumbitNo" type="" onClick={() => cancelDelete()}>
                <span className="submit-no">NO</span>
                </Button>
            </div>
            </Modal.Body>
        </Modal>
        );
    }

    return (
        <>
                    <div className="admin-panel">
                        <AdminNavBar className="admin-navpanel" id={6}/>
                        <div className="navint-nav">
                            <div className="navint-right">
                            <Link to="/yoodict"><img className="navint-logo" alt="yoodict-logo" src={YoodictLogo} /></Link>
                                <span className="navint-header">Admin Dashboard</span>
                            <img onClick={() => setShowSidebar(true)} alt="admin-minimized-menu-icon" className="res-nav-toggle" src={PurpleHamburger} />
                            </div>
                            <div className="navint-headers-type2">
                                <span className="header-wordname">All Users</span><br/>
                            </div>
                        {!isLoading ?  
                            <>
                            <div className="addnewadmin">
                                <div style={{width: 60+"%", margin: 'auto'}}>
                                <div  className="addadmin-row3">
                                    <FormControl type="text" id="search-user" placeholder="Search" value={query} onChange={(e) => handleSearch(e)}/>
                                    {/* <SpecButton onClick={() => addAdmin()} name={name}/> */}
                                </div>
                                <div className="addadmin-row1">
                                    <span className="admin-count">Number of users - {userCount}</span><br/>
                                    <span className="admin-count">Users with verified email - {verifiedUserCount}</span>
                                </div>
                                <div className="addadmin-row2">
                                    <span className="admins">
                                        {searchResults.filter(ad => ad._id !== user.id).map(a => {
                                            return (
                                            <span style={{display : 'block'}} key={a._id}>
                                                {a.username} 
                                            <img style={{display: a.verified === true ? 'inline-block' : 'none'}} src={VerificationBadge} className="verification-icon" alt="verification badge"/>
                                            <span style={{display: userDets.role === 'superadmin' ? 'inline-block' : 'none'}} onClick={() => confirmSubmit(a.username)} class="delete-admin"> (delete)</span></span>)
                                        })}
                                    </span>
                                </div>
                                </div>
                            <Pagination
                                    handleClick={handleClick}
                                    pages={pages}
                                    active={active}
                                />
                            </div>
                            </>
                        : (
                            <SpinnerOverlay>
                                <SpinnerContainer/>
                                </SpinnerOverlay>
                            )
                        }
                        </div>
                    </div>
                <ConfirmationModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
                <NavbarModal pageName="" show={showSidebar} onHide={() => setShowSidebar(false)}/>
        </>
    )
}

export default UsersPage