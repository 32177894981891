import React, {useState, useContext} from 'react'

import './resetform.scss'
import ResetPasswordAlert from './resetalert'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {AuthContext} from '../../App'
import { toast as Toastify } from 'react-toastify';


const ResetPasswordForm = () => {
    const AppContext = useContext(AuthContext)

    const [ ShowAlert, setShowAlert ] = useState( false );
    const [ password, setPassword] = useState('')
    const [ passwordConfirmation, setPasswordConfirmation] = useState('')

    const resetToken = 'abcs=defg'

    const toggleShowAlert = () =>
    {
        setShowAlert( prevState => !prevState );
    };
    
    const ErrorAlert = (message) => Toastify.error(message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });

    const handleSubmit = (event) => { 
        event.preventDefault();
        if (password === passwordConfirmation){
            AppContext.setNewPassword(password, resetToken);
            setPassword('')
            setPasswordConfirmation('')
            toggleShowAlert();
        } else ErrorAlert('Passwords do not match')
    }

    return (
        <>
            <div style={ { display: ShowAlert ? 'none' : 'flex' } } className="reset-form">
            <Form id="resetForm"  onSubmit={(e) => handleSubmit(e)}>
            <div className="form-header">Password Reset</div>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Enter a new password</Form.Label>
                    <Form.Control size="lg" required onChange={e => {setPassword(e.target.value)}} type="password" placeholder="" />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Confirm password</Form.Label>
                    <Form.Control size="lg" required onChange={e => {setPasswordConfirmation(e.target.value)}} type="password" placeholder="" />
                </Form.Group>
                <Button id="resetButton" type="submit" block>
                    CONFIRM
                </Button>
                <Form.Text className="text-muted">
                </Form.Text>
            </Form>
            </div>
            <div style={ { display: ShowAlert ? 'flex' : 'none' } } className="reset-alert">
                <ResetPasswordAlert/>
            </div>
        </>
    )
}

export default ResetPasswordForm;