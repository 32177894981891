import React, {useEffect, useState, useRef, useCallback } from 'react'
import {Link} from 'react-router-dom'
import './yootrends.scss'
import TrendsCard from '../../components/trendscard/trendscard'
import YtBanner from '../../components/yt-bannersection/yt-bannersection'
import InfoBanner from '../../components/infobanner/infobanner'
import YtNavBar from '../../components/navbar/yt-navbar'
import {SpinnerOverlay, SpinnerContainer} from '../../components/spinner/spinner.styles'
import axios from 'axios'
import BackButton from '../../icons/right-chevron (1).png'
import API_BASE from '../../apiBase'
import MetaDecorator from '../../MetaDecorator'


const YooTrendsPage = () => {
    const [fetchUrl, setFetchUrl] = useState(`${API_BASE}/trends`)
    const [fetchedWords, setFetchedWords] = useState([])
    const [upperLevelDisplay, setUpperLevelDisplay] = useState(false)
    const [searchFetchUrl, setSearchFetchUrl] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [hasMore, setHasMore] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)

    const observer = useRef()
    const lastBookElementRef = useCallback(node => {
      if(isLoading) return 
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(entries => {
        if(entries[0].isIntersecting && hasMore){
          setPageNumber(prevState => prevState+1)
        }
      })
      if(node) observer.current.observe(node)
    }, [isLoading, hasMore]);

    useEffect(() => {
        function fetchData(urlToGet) {
          axios.get(urlToGet, {params: {page: pageNumber}}).then(response => {
            const responseData = response.data
            console.log(responseData)
            if (responseData.trends.length > 0){
                setFetchedWords(fetchedWords => fetchedWords.concat(responseData.trends.map(word => ({
                  id:                 word._id,
                  usage:              word.usage,
                  similarTrends:      word.similarTrends,
                  alsoSee:            word.alsoSee,
                  memes:              word.memes,
                  title:              word.title,
                  definition:         word.definition,
                  tweets:             word.tweets ? word.tweets : [],
                  origin:             word.origin,
                  timelineStart:      word.timelineStart,
                  timelineEnd:        word.timelineEnd,
                  writerId:           word.writer ? word.writer._id : '',
                  writerName:         word.writer ? word.writer.username : '',
                  createdAt:          word.createdAt,
                  updatedAt:          word.updatedAt,
                  slug:               word.slug
                }))))
                setIsLoading(false)
            } 
            setHasMore(response.data.trends.length > 0)
        }).catch(err => {
          console.log(err)
          setIsLoading(false)
        })
  
        }
  
        fetchData(fetchUrl);
  
        }, [fetchUrl, pageNumber])
  
  useEffect(() => {
    function fetchData(urlToGet) {
      axios.get(urlToGet).then(response => {
        const responseData = response.data
        if (responseData.trends.length > 0){
            setFetchedWords(responseData.trends.map(word => ({
                id:                 word._id,
                usage:              word.usage,
                similarTrends:      word.similarTrends,
                alsoSee:            word.alsoSee,
                memes:              word.memes,
                title:              word.title,
                tweets:             word.tweets ? word.tweets : [],
                definition:         word.definition,
                origin:             word.origin,
                timelineStart:      word.timelineStart,
                timelineEnd:        word.timelineEnd,
                writerId:           word.writer ? word.writer._id : '',
                writerName:         word.writer ? word.writer.username : '',
                createdAt:          word.createdAt,
                updatedAt:          word.updatedAt,
                slug:               word.slug
            })))
            } 
            setHasMore(response.data.trends.length > 0)
        }).catch(err => console.log(err))
  
        setIsLoading(false)
    }
  
    fetchData(searchFetchUrl);
  
    }, [searchFetchUrl])

  function handleFetchUrlChange(newFetchUrl) {
    setIsLoading(true)
    setFetchUrl(newFetchUrl);
  }

  function handleSearchFetchUrlChange(newFetchUrl) {
    setSearchFetchUrl(newFetchUrl);
  }

  function alternateUpperDisplay(){
    setUpperLevelDisplay(!upperLevelDisplay)
  }

    return (
        <>
        <MetaDecorator title="Yootrends - Trends dictionary" description="Yootrends is a trends dictionary providing you with detailed insight on buzzwords"/>
        <YtNavBar  upperDisplay={upperLevelDisplay} altUpperDisplay={alternateUpperDisplay} onSearch={handleSearchFetchUrlChange} onClick={handleFetchUrlChange} />
        <InfoBanner header={''}/>
        <div className="yt-page">
        <div className="yt-left-pack">
        <div className="nav-back">
            <Link to="/yoodict"><img alt="" src={BackButton} className="back-button" />BACK TO DICTIONARY </Link>
        </div>
            <React.Fragment>
            {!isLoading ? 
            (
                fetchedWords.map( (item, index) => 
                {if (fetchedWords.length === index+1){
                return <div key={item.id} ref={lastBookElementRef}>
                <TrendsCard 
                      isLoading={isLoading}
                      key={item.slug} 
                      id={item.id}
                      usage={item.usage}
                      tweets={item.tweets}
                      similarTrends={item.similarTrends}
                      alsoSee={item.alsoSee}
                      memes={item.memes}
                      title={item.title}
                      definition={item.definition}
                      origin={item.origin}
                      timelineStart={item.timelineStart}
                      timelineEnd={item.timelineEnd}
                      writerId={item.writerId}
                      writerName={item.writerName}
                      createdAt={item.createdAt}
                      updatedAt={item.updatedAt}
                      slug={item.slug}
                    />
                    </div>
                  } else {
                    return <TrendsCard 
                          isLoading={isLoading}
                          key={item.slug} 
                          id={item.id}
                          usage={item.usage}
                          tweets={item.tweets}
                          similarTrends={item.similarTrends}
                          alsoSee={item.alsoSee}
                          memes={item.memes}
                          title={item.title}
                          definition={item.definition}
                          origin={item.origin}
                          timelineStart={item.timelineStart}
                          timelineEnd={item.timelineEnd}
                          writerId={item.writerId}
                          writerName={item.writerName}
                          createdAt={item.createdAt}
                          updatedAt={item.updatedAt}
                          slug={item.slug}
                        />
                  }}
                )
              ) : (
                  <SpinnerOverlay>
                      <SpinnerContainer/>
                  </SpinnerOverlay> 
              )}
          </React.Fragment>
          {/* <div className="load-more">Load More <img src={DownIcon}/></div> */}
          </div>
            <div className="yt-banner-section"><YtBanner/></div>
        </div>
        </>
    )
}

export default YooTrendsPage