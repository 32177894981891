import React, {useState} from 'react'

import './infocontent.scss'
import RightArrow from '../../icons/right-chevron.png'
import DownArrow from '../../icons/down-chevron.png'

const InfoContent = ( { title, content } ) => {
    const [ ShowDropDown, setShowDropDown ] = useState( false );

    const toggleShowDropDown = () =>
    {
        setShowDropDown( prevState => !prevState );
    };

    return (
        <div className="dropDown">
            <div className="content-header" onClick={ toggleShowDropDown }> 
                <h4 className="title-text">{ title }</h4>
                <img alt="arrow-right" style={ { display: ShowDropDown ? 'none' : 'block'} } className="right-arrow" src={RightArrow} />
                <img alt="arrow-left" style={ { display: ShowDropDown ? 'block' : 'none'} } className="right-arrow" src={DownArrow} />
            </div> 
            <div style={ { display: ShowDropDown ? 'block' : 'none' } } className="content">
                <p>{content}</p>
            </div>
        </div>

    );
}

export default InfoContent;