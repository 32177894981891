import React, {useState, useEffect} from 'react'

import './adminpage.scss'
import AdminNavBar from '../../components/adminnavbar/adminnavbar'
import AdminIntBar from '../../components/adminintbar/adminintbar'
import { toast as Toastify } from 'react-toastify';
import { newAxios } from '../../App'
import API_BASE from '../../apiBase'

const AdminPage = () => {
    const [wordCount, setWordCount] = useState([])
    const [contentObject, setContentObject] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [numberOfPages, setnumberOfPages] = useState(1)
    const [pageNumber, setPageNumber] = useState(1)

    const pageName = 'Pending Words' 
    const headerObject = [
        {
            name: 'New Words',
            number: wordCount.pendingNew
        },
        {
            name: 'Total Number of Words',
            number: (wordCount.approved+wordCount.pending+wordCount.rejected) 
        }]
    const contentHeaderArray = ['Number', 'Date Added', 'Name', 'Email', 'Word']

    const setFetchPageNumber = (number) => {
        setPageNumber(number)
    }

    const InfoAlert = (message) => Toastify(message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });

    async function fetchData() {
        const fullResponse = await newAxios(`${API_BASE}/words/pending?page=${pageNumber}`)
        const wordCount = await newAxios(`${API_BASE}/wordCounts`)
        const wordCountData = wordCount.data
        setWordCount(wordCountData.data)
        const responseData = await fullResponse.data

        if (responseData.words.length > 0){
            setnumberOfPages(responseData.pages)
            setContentObject(responseData.words.map(word => ({
                id:                 word._id,
                createdAt:          word.createdAt,
                downvoteCount:      word.downvoteCount,
                downvotes:          word.downvotes,
                examples:           word.examples, 
                literalTranslation: word.literalTranslation,
                meaning:            word.meaning,
                origin:             word.origin,
                phonetics:          word.phonetics,
                postedByName:       word.postedBy.username,
                postedByEmail:       word.postedBy.email,
                similarWords:       word.similarWords,
                slug:               word.slug,
                status:             word.status,
                updatedAt:          word.updatedAt,
                upvoteCount:        word.upvoteCount,
                upvotes:            word.upvotes,
                views:              word.views,
                word:               word.word,
                trend:              word.trend ? word.trend : ''
            })))
        } else {
            InfoAlert('No Pending words');
        }

        setIsLoading(false)
    }
    
  useEffect(() => {

    fetchData();

    }, [pageNumber])

    return (
        <>
            <div className="admin-panel">
                <AdminNavBar className="admin-navpanel" id={1} />
                <AdminIntBar 
                    pageName={pageName} 
                    headerObject={headerObject} 
                    contentHeaderArray={contentHeaderArray} 
                    contentObject={contentObject}
                    setFetchPageNumber={(number) => setFetchPageNumber(number)} 
                    pageNumber={pageNumber}
                    isLoading={isLoading}
                    numberOfPages={numberOfPages}
                    fetchData={fetchData}
                />
            </div>
        </>
    )
}

export default AdminPage 