import React from 'react';
import {Link} from 'react-router-dom'

import './wordcard.scss'
import WordDefs from './worddefs'
import Card from 'react-bootstrap/Card';

const WordCard = ({trend, slug ,id, definition, word, origin, literalTranslation, phonetics, examples, postedBy, createdAt, upvoteCount, downvoteCount, similarWords, upvotes, downvotes}) => {
  return (
    <>
        <div style={{marginBottom: 20+'px'}}>
            <Card className="wordcardhead addbottomborder">
            <Card.Body>
                <Card.Title className="word-card-head">
                    <section className="row-1">
                        <section className="word"><span style={{display: 'inline-block'}}>{word}</span><sup>origin | {origin}</sup></section>
                        
                        <section className="add-definition">
                            <Link to={`/newword/${word}`}>Add a definition</Link>
                        </section>
                    </section>
                    <section>
                        { phonetics ? (<span className="word-literal-translation">/{phonetics}/</span>) : (<></>)}
                        { literalTranslation ? (<span className="word-lit-translation">Literal translation: <i>{literalTranslation}</i></span>) : (<></>)}
                    </section>
                </Card.Title>
            </Card.Body>
            </Card>
            <WordDefs id={id} slug={slug} trend={trend} word={word} definition={definition} examples={examples} postedBy={postedBy} createdAt={createdAt} upvoteCount={upvoteCount} downvoteCount={downvoteCount} similarWords={similarWords} upvotes={upvotes} downvotes={downvotes}/>
        </div>
    </>
  );
}

export default WordCard;