import React, { useState, useContext } from 'react'
import './adminintbar.scss'
import '../adminnavbar/adminnavbar.scss'
import YoodictLogo from '../../icons/yoodict.svg'
import '../adminnavbar/adminnavbar.scss'

import Modal from 'react-bootstrap/Modal'
import {NavLink, Link} from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import PurpleHamburger from '../../icons/purplehamburger.svg'
import {AuthContext} from '../../App'

import PendingContent from './PendingContent.js'
import {SpinnerContainer, SpinnerOverlay} from '../../components/spinner/spinner.styles'

const AdminIntBar = ({isLoading, fetchData, pageNumber, numberOfPages, setFetchPageNumber, pageName, headerObject, contentHeaderArray, contentObject}) => {
    const AppContext = useContext(AuthContext)
    const userDets = JSON.parse(localStorage.getItem('user'))
    const [sortState, setSortState] = useState('Date')
    
    const [showSidebar, setShowSidebar] = useState(false)
    function NavbarModal(props) {
        return (
          <Modal
            {...props}
            size="lg"
            dialogClassName="nav-modal"
          >
            {/* <Modal.Body  className=""> */}
            <div className="admin-navbar-minimized">
                <div className="top-section">
                    <div className="admin-logo">A</div>
                    <div className="admin-welcome">Welcome Admin</div>
                </div>
                <div className="section-header">DASHBOARD</div>
                    <div className="upper-section" id="upper-section">
                        <div id="1" className="admin-nav" ><NavLink activeClassName="active" to="/admin/pending">PENDING WORDS</NavLink></div>
                        <div id="2" className="admin-nav" ><NavLink activeClassName="active" to="/admin/approved">APPROVED</NavLink></div>
                        <div id="3" className="admin-nav" ><NavLink activeClassName="active" to="/admin/unapproved">UNAPPROVED</NavLink></div>
                    </div>
                    <div className="middle-section" id="middle-section">
                        <div id="7" className="admin-nav"><NavLink activeClassName="active" to="/admin/createtrend">TRENDS</NavLink></div>
                        <div id="4" className="admin-nav"><NavLink activeClassName="active" to="/admin/addwordofweek">WORD OF THE WEEk</NavLink></div>
                        <div id="5" className="admin-nav"><NavLink activeClassName="active" to="/admin/addtrendofweek">TREND OF THE WEEK</NavLink></div>
                        <div id="6" className="admin-nav"><NavLink activeClassName="active" to="/admin/addnewadmin">ADD AN ADMIN</NavLink></div>
                        <div id="8" className="admin-nav"><NavLink activeClassName="active" to="/admin/userverify">VERIFY USER</NavLink></div>
                        <div id="9" className="admin-nav"><NavLink activeClassName="active" to="/admin/users">USERS</NavLink></div>
                    </div>
                    <div className="lower-section">
                        <div className="admin-name">{userDets.username}</div>
                        <div className="button-container"><Button  onClick={AppContext.logout} className="admin-logout">Logout</Button></div>
                    </div>
            </div>
          </Modal>
        );
      }
    return (
        <>
        <div className="navint-nav">
            <div className="navint-right">
                <Link to="/yoodict"><img className="navint-logo" alt="yoodict-logo" src={YoodictLogo} /></Link>
                <span className="navint-header">Admin Dashboard</span>
                <img onClick={() => setShowSidebar(true)} alt="admin-minimized-menu-icon" className="res-nav-toggle" src={PurpleHamburger} />
            </div>
            <div className="navint-link-name">
                <span>{pageName}</span>
            </div>
            <div className="navint-headers">
                    { 
                        headerObject.map((item, index) => (
                            <div className="headers-holder" key={index}>
                                <span className="header-wordname">{ item.name }</span><br/>
                                <span className="header-number">{ item.number }</span>
                            </div>
                        ))
                    }  
            </div>
            <div className="sortby-section">
                <span className="sortby">Sort by : </span>
                <span>
                    <div className="dropdown">
                    <select style={{marginLeft: 5+'px'}} onClick={(e) => setSortState(e.target.value)}>
                        <option className="dropdown-content" value="Date">Date</option>
                        <option className="dropdown-content" value="Alphabetically">Alphabetically</option>
                        <option className="dropdown-content" value="Reverse">Reverse</option>
                        <option className="dropdown-content" value="UpVote">UpVote</option>
                        <option className="dropdown-content" value="DownVote">DownVote</option>
                    </select>
                    </div>
                </span>
            </div>
            {!isLoading ?  
                (
                    <PendingContent isLoading={isLoading} fetchData={fetchData} numberOfPages={numberOfPages} setFetchPageNumber={setFetchPageNumber} pageName={pageName} sortState={sortState} contentObject={contentObject} pageNumber={pageNumber} contentHeaderArray={contentHeaderArray} />
                )
             : (
                <SpinnerOverlay>
                    <SpinnerContainer/>
                    </SpinnerOverlay>
                )
            }
        </div>
        <NavbarModal pageName={pageName} show={showSidebar} onHide={() => setShowSidebar(false)}/>
        </>
    )
}

export default AdminIntBar 