import React from 'react'

import {Link} from 'react-router-dom'
import './resetform.scss'
import Form from 'react-bootstrap/Form'

const ResetPasswordAlert = () => {
    return (
        <>
            <div className="reset-form">
            <Form id="resetForm">
            <div className="form-header" >Password reset was successful !</div>
                
                <Form.Text className="text-muted">
                    <Link to="/login">Login</Link><br/>
                </Form.Text>
            </Form>
            </div>
        </>
    )
}

export default ResetPasswordAlert;