import React from 'react'

import './infobanner.scss'

import GoogleAd from '../googleads/googleads'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'

const InfoBanner = ({header}) => {
    return (
        <>
            <GoogleAd/>
            <Jumbotron fluid className="banner">
                <Container>
                    <div className="header">{header}</div>
                </Container>
            </Jumbotron>
        </>
    )
}

export default InfoBanner