import React from 'react'
import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'

const MetaDecorator = ({title, description}) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="description" content={description}/>
        </Helmet>
    )
}

MetaDecorator.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
}

export default MetaDecorator