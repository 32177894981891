import React, { useState, useEffect, useContext} from 'react'

import './adminpage.scss'
import YoodictLogo from '../../icons/yoodict.svg'
import AdminNavBar from '../../components/adminnavbar/adminnavbar'
import TrendSuggestTags from '../../components/suggetstags/trendsuggesttag'
import {newAxios, ErrorAlert, SuccessAlert} from '../../App'
import API_BASE from '../../apiBase'
import axios from 'axios'
import {Link, NavLink} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import PurpleHamburger from '../../icons/purplehamburger.svg'
import {AuthContext} from '../../App'


const AddTrendWordofTheWeekPage = () => {
    const [tags, setTags] = useState([])
    const [tow, setTow] = useState([])
    const [slug, setSlug] = useState([])
    const AppContext = useContext(AuthContext)
    const userDets = JSON.parse(localStorage.getItem('user'))

    const selectedSlug = (slug) => {
        setSlug(slug)
    }

    const addNewTow = () => {
        if (tags.length > 1){
            return ErrorAlert("Choose one trend only")
        } else if (tags.length < 1) {
            return ErrorAlert("choose a trend")
        }
        newAxios.post(`${API_BASE}/trendOfTheWeek`, {trend: tags[0]._id})
        .then(response => {
            SuccessAlert(response.data.message)
            getTow()
        }).catch(err => console.log(err))
    }

    const getTow = () => {
        axios.get(`${API_BASE}/trendOfTheWeek`)
        .then(response => {
            setTow(response.data.trend)
        })
        .catch(err => ErrorAlert("Trend of the week not found"))
    }

    useEffect(() => {
        getTow()
    }, [])

    const [showSidebar, setShowSidebar] = useState(false)
    function NavbarModal(props) {
        return (
          <Modal
            {...props}
            size="lg"
            dialogClassName="nav-modal"
          >
            {/* <Modal.Body  className=""> */}
            <div className="admin-navbar-minimized">
                <div className="top-section">
                    <div className="admin-logo">A</div>
                    <div className="admin-welcome">Welcome Admin</div>
                </div>
                <div className="section-header">DASHBOARD</div>
                    <div className="upper-section" id="upper-section">
                        <div id="1" className="admin-nav" ><NavLink activeClassName="active" to="/admin/pending">PENDING WORDS</NavLink></div>
                        <div id="2" className="admin-nav" ><NavLink activeClassName="active" to="/admin/approved">APPROVED</NavLink></div>
                        <div id="3" className="admin-nav" ><NavLink activeClassName="active" to="/admin/unapproved">UNAPPROVED</NavLink></div>
                    </div>
                    <div className="middle-section" id="middle-section">
                        <div id="7" className="admin-nav"><NavLink activeClassName="active" to="/admin/createtrend">TRENDS</NavLink></div>
                        <div id="4" className="admin-nav"><NavLink activeClassName="active" to="/admin/addwordofweek">WORD OF THE WEEk</NavLink></div>
                        <div id="5" className="admin-nav"><NavLink activeClassName="active" to="/admin/addtrendofweek">TREND OF THE WEEK</NavLink></div>
                        <div id="6" className="admin-nav"><NavLink activeClassName="active" to="/admin/addnewadmin">ADD AN ADMIN</NavLink></div>
                        <div id="8" className="admin-nav"><NavLink activeClassName="active" to="/admin/userverify">VERIFY USER</NavLink></div>
                        <div id="9" className="admin-nav"><NavLink activeClassName="active" to="/admin/users">USERS</NavLink></div>
                    </div>
                    <div className="lower-section">
                        <div className="admin-name">{userDets.username}</div>
                        <div className="button-container"><Button  onClick={AppContext.logout} className="admin-logout">Logout</Button></div>
                    </div>
            </div>
          </Modal>
        );
      }

    return (
        <>
            <div className="admin-panel">
                <AdminNavBar  className="admin-navpanel" id={5}/>
                <div className="navint-nav">
                    <div className="navint-right">
                <Link to="/yoodict"><img className="navint-logo" alt="yoodict-logo" src={YoodictLogo} /></Link>
                        <span className="navint-header">Admin Dashboard</span>
                    <img onClick={() => setShowSidebar(true)} alt="admin-minimized-menu-icon" className="res-nav-toggle" src={PurpleHamburger} />
                    </div>
                    <div className="navint-headers-type2">
                        <span className="header-wordname">Trend of the Week</span><br/>
                    </div>
                    <div className="addword-panel">
                        <div className="trendsearch-container">
                        <div id="addtrend-search">
                        <TrendSuggestTags selectedSlug={selectedSlug} slug={slug} setTags={setTags} tags={tags} />
                        </div>
                        <span className="update-button" onClick={() => addNewTow()}>Update</span>
                        </div>
                        <div className="current-words">
                            <span className="currentword-title">Currently:</span>
                            <span className="currentword-word"> {tow ? tow.title : 'Null'}</span>
                        </div>
                    </div>
                </div>
            </div>
        <NavbarModal pageName="" show={showSidebar} onHide={() => setShowSidebar(false)}/>
        </>
    )
}

export default AddTrendWordofTheWeekPage 