import React, { useState,useEffect, useCallback} from 'react'
import Table from 'react-bootstrap/Table'
import './adminintbar.scss'
import {ViewMoreButton} from '../../pages/adminpage/button' 
import './wordupdate.scss'
import Pagination from "./pagenation"
import WordUpdateModal from './wordupdate'
import Modal from 'react-bootstrap/Modal'

const UnapprovedContent = ({ isLoading, fetchData, numberOfPages, setFetchPageNumber, contentHeaderArray, contentObject, pageNumber, goToPrevious, goToNext }) => {
    const [modalShow, setModalShow] = useState(false);

    let total = numberOfPages;
    const [active, setActive] = useState(1);
    const [pages, setPages] = useState([]);

    useEffect(() => {
        if (total <= 7) {
        let arr = [];
        for (let i = 1; i <= total; i++) {
            arr.push(i);
        }
        setPages(arr);
        return;
        }
        if (active <= 4) {
        setPages([1, 2, 3, 4, 5, "...", total]);
        }
        if (active > 4 && active < total - 3) {
        setPages([1, "...", active - 1, active, active + 1, "...", total]);
        }
        if (active > total - 4) {
        setPages([1, "...", total - 4, total - 3, total - 2, total - 1, total]);
        }
    }, [active, total]);

    const handleClick = useCallback(e => {
        if (e.target.value === 0) {
        return;
        }
        setActive(e.target.value);
        setFetchPageNumber(e.target.value)
    }, [setFetchPageNumber]);

    const closeModal = () => {
        setModalShow(false)
    }

    const [WordDetailsObject, setWordDetailsObject] = useState({    
        word:     '',
        meaning:  '',
        origin:   '',
        examples: '',
        similarWords: '',
        literalTranslation: '',
        phonetics: ''
    })
    
    const handlePopUp = (id, word, meaning, origin, examples, similarWords, literalTranslation, phonetics) => {
        setWordDetailsObject({    
            id: id,
            word: word,
            meaning: meaning,
            origin: origin,
            examples: examples,
            similarWords: similarWords,
            literalTranslation: literalTranslation,
            phonetics: phonetics
        })
        
        setModalShow(true)
    }

    function WordObjectModal(props) {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="wordupdate-modal"
          >
            <WordUpdateModal fetchData={fetchData} CloseModal={closeModal} WordDetailsObject={WordDetailsObject} popUpTitle="Unapproved Word" button3="UPDATE" button1="APPROVE" button4="DELETE"/>
          </Modal>
        );
      }
            return (
                <>
                    <div className="words-table">
                        <Table responsive>
                            <thead>
                                <tr>
                                    {
                                        contentHeaderArray.map((header, index) => (
                                            <th key={index}>{header}</th>
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                    {contentObject.map((word, index) => (
                                        <tr key={word.id}>
                                            <td>{index+1}</td>
                                            <td>{(new Date(word.createdAt)).toLocaleDateString()}</td>
                                            <td style={{wordBreak: word.postedByName.length > 15 ? 'unset' : 'break-all'}}>{word.postedByName}</td>
                                            <td style={{wordBreak: word.postedByEmail.length > 15 ? 'unset' : 'break-all'}}>{word.postedByEmail}</td>
                                            <td className="split-view">{word.word} <ViewMoreButton onClick={() => handlePopUp(word.id, word.word, word.meaning, word.origin, word.examples, word.similarWords, word.literalTranslation, word.phonetics)} name="UPDATE" /></td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                        <Pagination
                            handleClick={handleClick}
                            pages={pages}
                            active={active}
                        />
                        </div>
            <WordObjectModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            />
                </>

            )
}

export default UnapprovedContent