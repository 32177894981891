import React, {useState, useEffect}  from 'react'

import './adminpage.scss'
import AdminNavBar from '../../components/adminnavbar/adminnavbar'
import AdminIntBar from '../../components/adminintbar/adminintbar'
import { toast as Toastify } from 'react-toastify';
import { newAxios } from '../../App'

const TrendPage = () => {
    const [wordCount, setWordCount] = useState([])
    const [contentObject, setContentObject] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [pageNumber, setPageNumber] = useState(1)
    const [numberOfPages, setnumberOfPages] = useState(1)
    const pageName = 'Trends Page' 
    const headerObject = [
        {
            name: 'Recently Added',
            number: wordCount.recentTrends
        },
        {
            name: 'Added Trends',
            number:  wordCount.trends
        }]
    const contentHeaderArray = ['Trend', 'Writer', 'Date', 'Update']

    const setFetchPageNumber = (number) => {
        setPageNumber(number)
    }
           
    useEffect(() => {
        function fetchData() {
            newAxios.get('https://yoodict.herokuapp.com/api/wordCounts')
            .then(response => {
                const wordCount = response
                const wordCountData = wordCount.data
                setWordCount(wordCountData.data)
            }).then(err => console.log(err))
        }

        fetchData();

    }, [])

    async function fetchData () {
        newAxios.get(`https://yoodict.herokuapp.com/api/trends?page=${pageNumber}`)
        .then(response => 
            {const responseData = response.data
            if (responseData.trends.length > 0){
                setnumberOfPages(responseData.pages)
                setContentObject(responseData.trends.map(word => ({
                    id:                 word._id,
                    usage:              word.usage,
                    similarTrends:      word.similarTrends,
                    alsoSee:            word.alsoSee,
                    memes:              word.memes,
                    title:              word.title,
                    definition:         word.definition,
                    origin:             word.origin,
                    timelineStart:      word.timelineStart,
                    timelineEnd:        word.timelineEnd,
                    writerId:           word.writer ? word.writer._id : '',
                    tweets:             word.tweets ? word.tweets : [],
                    writerName:         word.writer ? word.writer.username : '',
                    createdAt:          word.createdAt,
                    updatedAt:          word.updatedAt,
                    slug:               word.slug
                })))
            }
            setIsLoading(false)
        }).catch(err => {
            console.log(err)    
            setIsLoading(false)
        })
    }

    useEffect(() => {
        fetchData()
    }, [pageNumber])

    return (
        <>
            <div className="admin-panel">
                <AdminNavBar className="admin-navpanel" id={7}/>
                <AdminIntBar 
                    pageName={pageName} 
                    headerObject={headerObject} 
                    contentHeaderArray={contentHeaderArray}
                    contentObject={contentObject}
                    setFetchPageNumber={(number) => setFetchPageNumber(number)} 
                    pageNumber={pageNumber} 
                    isLoading={isLoading}
                    numberOfPages={numberOfPages}
                    fetchData={fetchData}
                />
            </div>
        </>
    )
}

export default TrendPage 