import React, { useState, useContext, useEffect } from "react";

import "./newwordform.scss";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Jumbotron from "react-bootstrap/Jumbotron";
import CloseModal from "../../icons/close.svg";
import { AuthContext, ErrorAlert, SuccessAlert } from "../../App";
import TagInput from "../taginput/taginput";
import SuggestTags from "../suggetstags/suggesttags";
import Axios from "axios";
import API_BASE from "../../apiBase";
import { Router, useHistory } from "react-router-dom";

const NewWordForm = (props) => {
  const AppContext = useContext(AuthContext);
  const [modalShow, setModalShow] = useState(false);
  const [similarTags, setSimilarTags] = useState([]);
  const [exampleTags, setExampleTags] = useState([]);
  const [slug, setSlug] = useState([]);
  const [verified, setVerified] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'));

  const [newWordObject, setNewWordObject] = useState({
    word: props.props.match.params.word,
    meaning: "",
    origin: "",
    examples: exampleTags,
    similarWords: similarTags,
    literalTranslation: "",
    phonetics: "",
  });
  
  const history = useHistory();

  useEffect(() => {
    if(user.emailVerified) {
      setVerified(true);
      return
    }
    Axios.post(`${API_BASE}/auth/mail/check`, {
      email : user.email
    })
    .then(response => {
      console.log(response.data.message)
      user.emailVerified = true;
      localStorage.setItem('user', JSON.stringify(user))
      setVerified(true);
    })
    .catch(err => {
      console.log(err.response.data.error)
      ErrorAlert(err.response.data.error)
      history.push('/yoodict')
    })
  }, [])

  useEffect(() => {
    setNewWordObject({
      ...newWordObject,
      examples: exampleTags,
    });

  }, [exampleTags]);

  useEffect(() => {
    setNewWordObject({
      ...newWordObject,
      similarWords: similarTags,
    });

    // similarTags && setI((prevstate) => prevstate + 1);
  }, [similarTags]);

  const selectedSlug = (slug) => {
    setSlug(slug);
  };

  const confirmSubmit = (event) => {
    event.preventDefault();
    if(handleValidation()) setModalShow(true);
  };

  function clearForm() {
    document.getElementById("newwordForm").reset();
    history.push('/yoodict')
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    AppContext.addNewWord(newWordObject)
    .then(response => {
      SuccessAlert(response.data.message)
      setTimeout(() => history.push("/yoodict"), 2000)
    })
    .catch((err) => {
      if(err.response.data.validationErrors) {
        err.response.data.validationErrors.map(error => {
          return ErrorAlert(error.error)
        })
      } else {
        ErrorAlert(err.response.data.error)
      }
    })
    .finally(() => setModalShow(false))
  };
  const handleValidation = () => {
    if (
      newWordObject.meaning.trim() === ""
    ) {
      ErrorAlert("Please fill word meaning");
      return false;
    } else if (
      newWordObject.word.trim() === ""
    ) {
      ErrorAlert("Please fill word name");
      return false;
    } else if (
      newWordObject.examples.length === 0 
    ) {
      ErrorAlert("Please fill at least one example");
      return false;
    } else if (
      newWordObject.origin === ""
    ) {
      ErrorAlert("Please select word origin");
      return false;
    }
    if (
        newWordObject.literalTranslation.trim().length === 0
    ){
        delete newWordObject.literalTranslation
    }
    if (
        newWordObject.phonetics.trim().length === 0
    ){
        delete newWordObject.phonetics
    }
    if (
        newWordObject.similarWords.length === 0
    ){
        delete newWordObject.similarWords
    }
    return true;
    setModalShow(false);
  };

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <div className="close-modal-button">
            <img alt="close modal icon" onClick={() => setModalShow(false)} src={CloseModal} />
          </div>
        </Modal.Header>
        <Modal.Body>
          <h4>Are you sure you want to submit?</h4>
          <div className="confirmation-buttons">
            <Button id="sumbitYes" type="" onClick={(e) => handleSubmit(e)}>
              <span className="submit-yes">YES</span>
            </Button>
            <Button id="sumbitNo" type="" onClick={() => setModalShow(false)}>
              <span className="submit-no">NO</span>
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  if(!verified) return <div className="emptyPage"></div>
  return (
    <div>
      <Jumbotron fluid className="help-banner"></Jumbotron>
      <div className="newword-form">
        <Form id="newwordForm">
          <div className="newwordform-header">Create your word</div>
          <div className="newword row1">
            <Form.Group as={Col} md="8" controlId="formBasicWord">
              <Form.Label>
                Enter word or short phrase <span className="req">*</span>
              </Form.Label>
              <Form.Control
                onChange={(e) => {
                  newWordObject.word = e.target.value;
                }}
                required
                defaultValue={props.props.match.params.word ?  props.props.match.params.word : ''}
                size="lg"
                type="text"
                placeholder="e.g Duduke, E be thingz"
              />
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="formBasicOrigin">
              <Form.Label>
                Origin of word <span className="req">*</span>
              </Form.Label>
              <Form.Control
                onChange={(e) => {
                  newWordObject.origin = e.target.value;
                }}
                as="select"
                required
                size="lg"
                type="text"
                placeholder=""
              >
                <option value="0">Origin...</option>
                <option value="Unknown">Unknown</option>
                <option value="Hausa">Hausa</option>
                <option value="Igbo">Igbo</option>
                <option value="Pidgin">Pidgin</option>
                <option value="Yoruba">Yoruba</option>
              </Form.Control>
            </Form.Group>
          </div>
          <div className="newword row2">
            <Form.Group as={Col} md="8" controlId="formBasicMeaning">
              <Form.Label>
                Meaning <span className="req">*</span>
              </Form.Label>
              <Form.Control
                required
                onChange={(e) => {
                  newWordObject.meaning = e.target.value;
                }}
                as="textarea"
                rows="5"
              />
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="formBasicExample">
              <Form.Label>
                Short example <span className="req">*</span>
              </Form.Label>
              <TagInput
                tags={exampleTags}
                setTags={setExampleTags}
                tagBoxId='newword'
              />
              <Form.Label>
                Similar words <span className="req"></span>
              </Form.Label>
              <SuggestTags
                slug={slug}
                selectedSlug={selectedSlug}
                tags={similarTags}
                setTags={setSimilarTags}
              />
            </Form.Group>
          </div>
          <div className="newword row3">
            <Form.Group as={Col} md="6" controlId="formBasicLitTrans">
              <Form.Label>Literal Translation</Form.Label>
              <Form.Control
                onChange={(e) => {
                  newWordObject.literalTranslation = e.target.value;
                }}
                size="lg"
                type="text"
                placeholder=""
              />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formBasicPhonics">
              <Form.Label>Phonetics Translation</Form.Label>
              <Form.Control
                onChange={(e) => {
                  newWordObject.phonetics = e.target.value;
                }}
                size="lg"
                type="text"
                placeholder="e.g Du-du-keh"
              />
            </Form.Group>
          </div>
          <div className="dual-buttons">
            <Button id="cancelWordButton" onClick={clearForm}>
              <span className="cancel-button">CANCEL</span>
            </Button>
            <Button
              id="submitWordButton"
              type=""
              onClick={(e) => confirmSubmit(e)}
            >
              <span className="submit-button">SUBMIT</span>
            </Button>
          </div>
        </Form>
      </div>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default NewWordForm;
