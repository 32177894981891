import React, {useState, useRef, useEffect} from 'react'
import useTrendSearch from '../navbar/useTrendSearch'
import './suggesttags.scss'

const TrendSuggestTags = (props) => {
    const [query, setQuery] = useState('')
    const [pageNumber,setPageNumber] = useState(1)
    const [display, setDisplay] = useState(false)
    const [slug, setSlug] = useState(props.slug);

    const {
        trends, 
        hasMore, 
        loading, 
        error} = useTrendSearch(query, pageNumber)

    const setSearchField = (text) => {
        setQuery(text.title)  
        handleKeyDown(text.title, text._id, text.slug) 
    }

    const removeTags = indexToRemove => {
        props.setTags([...props.tags.filter((_, index) => index !== indexToRemove)]);
    };
    const addTags = (input, uuid, slugg) => {
        props.setTags([...props.tags, { _id: uuid, title: input }]);
        setSlug([...slug, slugg]);
        props.selectedSlug([...slug, slugg]);
    };
    const handleKeyDown = (text, uid, slg) => {
            addTags(text, uid, slg)
            setQuery('')
    };

    useEffect(() => {
        props.setTags(props.tags)
        document.addEventListener("click", handleClickOutside)

        return () => {
        document.removeEventListener("click", handleClickOutside)
        }
    },[])

    const handleClickOutside = (e) => {
        // const {current: wrap} = wrapperRef;
        if(wrapperRef.current && !wrapperRef.current.contains(e.target)){
        // e.preventDefault();
        setDisplay(false)
        }
    }

    const wrapperRef = useRef(null);

        return (
        <div ref={wrapperRef} className="search-autocomplete">
            <div className="tags-input">
                <ul id="tags">
                    {props.tags.map((tag, index) => (
                        <li key={index} className="tag">
                            <span className='tag-title'>{tag.title}</span>
                            <span className='tag-close-icon'
                                onClick={() => removeTags(index)}
                            >
                                x
                            </span>
                        </li>
                    ))}
                </ul>
                <input
                    type="text"
                    placeholder="Press enter to add tags"
                    onClick={() => setDisplay(true)}
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                />
            </div>
                {display && (
                        <div className="suggestions">
                            { trends.map(trend => {return( 
                                <div onClick={() => setSearchField(trend)} tabIndex="0" className="option" key={trend._id}>
                                    <span>{trend.title}</span>
                                </div>)
                            })}
                            <div>{loading && 'Loading...'}</div>
                        </div>
                    )}
            </div>
        )
}

export default TrendSuggestTags