import React, { useState, useContext } from 'react'

import {Link} from 'react-router-dom'
import './helpform.scss'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import LightCheck from '../../icons/lpurplecheck.svg'
import {AuthContext} from '../../App'

const HelpForm = () => {
    const [modalShow, setModalShow] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('')
    const AppContext = useContext(AuthContext)

    const clearInputs = () => {
        document.getElementById("helpForm").reset();
    }

    const onSubmit = (e) => {
      e.preventDefault()
      AppContext.sendHelpMessage({ name, email, message })
      .then(response => {
        setModalShow(true);
        clearInputs()
      })
      .catch(err => {
        if(err.response) {
          //Use toast to display errors, using alert for now
          if(err.response.data.validationErrors) {
            err.response.data.validationErrors.map(e => alert(e.error))
          } else {
            alert(err.response.data.error)
          }
        }})
    }
    function MyVerticallyCenteredModal(props) {
        return (
          <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            
          >
            <Modal.Header>
            </Modal.Header>
            <Modal.Body>
              <div className="lpurple-check"><img alt="verification-check" src={LightCheck} /></div>
              <h4>Message Sent</h4>
              <p>
                  <Link to="/yoodict">GO TO DICTIONARY</Link>
              </p>
            </Modal.Body>
          </Modal>
        );
      }
    
    return (
        <>
            <div className="help-form">
            <Form onSubmit={onSubmit} id="helpForm">
            <div className="helpform-header">Send us a message</div>

                <Form.Group controlId="formBasicName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control required onChange={(e) => setName(e.target.value)} size="lg" type="text" placeholder="" />
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control required size="lg" onChange={(e) => setEmail(e.target.value)} type="email" placeholder="" />
                </Form.Group>

                <Form.Group controlId="formControlTextarea">
                    <Form.Label>Message</Form.Label>
                    <Form.Control required as="textarea" rows="3" onChange={(e) => setMessage(e.target.value)} />
                </Form.Group>

                <Button id="helpButton" type="submit" block >
                    SEND
                </Button>
            </Form>
            </div>
            <MyVerticallyCenteredModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default HelpForm;