import React, { useEffect, useState, useRef, useCallback } from 'react';
import {useLocation} from 'react-router-dom'

import InfoBanner from '../../components/infobanner/infobanner'
import WordCard from '../../components/wordcard/wordcard'
import BannerSection from '../../components/banner-section/bannersection'
import AlphaNavBar from '../../components/alphanavbar/aplhanavbar'
import $ from 'jquery'
import './homepage.scss'
import axios from 'axios'
import { toast as Toastify } from 'react-toastify';
import NavBar from '../../components/navbar/navbar'
import {SpinnerContainer, SpinnerOverlay} from '../../components/spinner/spinner.styles'
import API_BASE from '../../apiBase';
import MetaDecorator from '../../MetaDecorator'

const HomepageAlphabetical = (props) => {
  const [fetchUrl, setFetchUrl] = useState(`${API_BASE}/words/byAlphabet?alphabet=${props.match.params.alphabet}`)
  const [fetchedWords, setFetchedWords] = useState([])
  const [upperLevelDisplay, setUpperLevelDisplay] = useState(false)
  const [searchFetchUrl, setSearchFetchUrl] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [hasMore, setHasMore] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)

  const observer = useRef()
  const lastBookElementRef = useCallback(node => {
    if(isLoading) return 
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if(entries[0].isIntersecting && hasMore){
        setPageNumber(prevPageNumber => prevPageNumber+1)
      }
    })
    if(node) observer.current.observe(node)
  }, [isLoading, hasMore])

  var URL = useLocation().pathname;

  function screen_resize() {
    var w = parseInt(window.innerWidth);
      
      if(URL.includes("/yoodict") & w <= 768) {
          //max-width 500px
          // actions here...
          $(document).ready(function(e) {
          if ((document.getElementById("normal-alpha-nav") === null) || (document.getElementById("appendHere") === null) ){
              window.location.reload(true)
          } else {
              const node = document.getElementById("normal-alpha-nav");
              const list = document.getElementById("appendHere");
              list.insertBefore(node, list.childNodes[0])
          }
          })
      } else if(URL.includes("/yoodict")) {
          // 850px and beyond
          // actions here...
          $(document).ready(function(e) {
            if ((document.getElementById("normal-alpha-nav") === null) || (document.getElementById("appendHere") === null) ){
                window.location.reload(true)
            } else {
              const node = document.getElementById("normal-alpha-nav");
              const list = document.getElementById("left-pack");
              list.insertBefore(node, list.childNodes[0])
            }
          })
      }

    }

    // if window resize call responsive function
    $(window).resize(function(e) {
      screen_resize();
    });
    // call responsive function on default :)
    $(document).ready(function(e) {
      screen_resize();
    });
    
  useEffect(() => {
      function fetchData(urlToGet) {
      axios.get(urlToGet,{params: {page: pageNumber}}).then(response => {
        const responseData = response.data

        if (responseData.words.length > 0){
            setFetchedWords(responseData.words.map(word => ({
                id:                 word._id,
                trend:              word.trend ? word.trend : false,
                createdAt:          word.createdAt,
                downvoteCount:      word.downvoteCount,
                downvotes:          word.downvotes,
                examples:           word.examples, 
                literalTranslation: word.literalTranslation,
                meaning:            word.meaning,
                origin:             word.origin,
                phonetics:          word.phonetics,
                postedBy:           word.postedBy,
                similarWords:       word.similarWords,
                slug:               word.slug,
                status:             word.status,
                updatedAt:          word.updatedAt,
                upvoteCount:        word.upvoteCount,
                upvotes:            word.upvotes,
                views:              word.views,
                word:               word.word
            })))
        } 
        setHasMore(response.data.words.length > 0)
        setIsLoading(false)
      }).catch(err => {
        console.log(err)
        setIsLoading(false)
      })
      }

      fetchData(fetchUrl);

      }, [fetchUrl, pageNumber])

useEffect(() => {
  function fetchData(urlToGet) {
    axios.get(urlToGet, {params: {page: pageNumber}}).then(response => {
      const responseData = response.data

      if (responseData.words.length > 0){
          setFetchedWords(responseData.words.map(word => ({
              id:                 word._id,
              trend:              word.trend ? word.trend : false,
              createdAt:          word.createdAt,
              downvoteCount:      word.downvoteCount,
              downvotes:          word.downvotes,
              examples:           word.examples,
              literalTranslation: word.literalTranslation,
              meaning:            word.meaning,
              origin:             word.origin,
              phonetics:          word.phonetics,
              postedBy:           word.postedBy,
              similarWords:       word.similarWords,
              slug:               word.slug,
              status:             word.status,
              updatedAt:          word.updatedAt,
              upvoteCount:        word.upvoteCount,
              upvotes:            word.upvotes,
              views:              word.views,
              word:               word.word
          })))
        }
        setIsLoading(false)
        setHasMore(response.data.words.length > 0)
      }).catch(err => {
        console.log(err)
        setIsLoading(false)
      })
      }


  fetchData(searchFetchUrl, pageNumber);

  }, [searchFetchUrl, pageNumber])

  function handleFetchUrlChange(newFetchUrl) {
    setIsLoading(true)
    setFetchUrl(newFetchUrl);
  }

  function handleSearchFetchUrlChange(newFetchUrl) {
    setSearchFetchUrl(newFetchUrl);
  }

  function alternateUpperDisplay(){
    setUpperLevelDisplay(!upperLevelDisplay)
  }
   
  return (
    <div>
          <MetaDecorator title="Yoodict - Buzzwords dictionary" description="Yoodict is a buzzwords dictionary providing you with trending slangs definitions"/>
          <NavBar upperDisplay={upperLevelDisplay} altUpperDisplay={alternateUpperDisplay} onSearch={handleSearchFetchUrlChange} onClick={handleFetchUrlChange} />
          <InfoBanner header={''}/>
          <div id="appendHere">
          {/* <div className="minimized-alpha-nav"> 
            <AlphaNavBar/> 
          </div> */}
          <div className="ydt-fluid">
            <div id="left-pack" className="yoodict-left-pack">
              <div id="normal-alpha-nav" className="normal-alpha-nav">
                <AlphaNavBar fetchUrl={fetchUrl} onClick={handleFetchUrlChange} />
              </div> 
            <React.Fragment>
            {!isLoading ? 
            (
                fetchedWords.map( (item, index) => {
                  if(fetchedWords.length === index+1){
                    return <div key={item.id} ref={lastBookElementRef} className="wordcard">
                    <WordCard 
                      isLoading={isLoading}
                      key={item.slug}
                      slug={item.slug} 
                      trend={item.trend}
                      id={item.id}
                      definition={item.meaning} 
                      word={item.word}
                      origin={item.origin}
                      literalTranslation={item.literalTranslation}
                      phonetics={item.phonetics}
                      examples={item.examples}
                      postedBy={item.postedBy}
                      createdAt={item.createdAt}
                      upvoteCount={item.upvoteCount}
                      downvoteCount={item.downvoteCount}
                      similarWords={item.similarWords}
                      upvotes={item.upvotes}
                      downvotes={item.downvotes}
                    />
                  </div>
                  } else {
                    return <div key={item.id} className="wordcard">
                    <WordCard 
                      isLoading={isLoading}
                      key={item.slug}
                      slug={item.slug} 
                      trend={item.trend}
                      id={item.id}
                      definition={item.meaning} 
                      word={item.word}
                      origin={item.origin}
                      literalTranslation={item.literalTranslation}
                      phonetics={item.phonetics}
                      examples={item.examples}
                      postedBy={item.postedBy}
                      createdAt={item.createdAt}
                      upvoteCount={item.upvoteCount}
                      downvoteCount={item.downvoteCount}
                      similarWords={item.similarWords}
                      upvotes={item.upvotes}
                      downvotes={item.downvotes}
                    />
                  </div>
                  }
                  }
                )
              ) : (
                  <SpinnerOverlay>
                      <SpinnerContainer/>
                  </SpinnerOverlay> 
              )}
          </React.Fragment>
              </div>
            <div className="banner-section">
              <BannerSection isLoading={isLoading}/>
            </div>
          </div>
          </div>
    </div>
  );
}

export default HomepageAlphabetical;