import React from 'react'
import {Link} from 'react-router-dom'

import './TandC.scss'

import Footer from '../../components/footer/footer'
import InfoBanner from '../../components/infobanner/infobanner'
import InfoContent from '../../components/infocontent/infocontent'
import BackButton from '../../icons/right-chevron (1).png'
import NavBar from '../../components/navbar/navbar'
import MetaDecorator from '../../MetaDecorator'

const TandC = () => {

    const renderFunction = (content) => {
        return (
            content
        )
    }
    
    const tcArray =
        [
            {
                title: "TERMS OF SERVICE",
                content: renderFunction(<div>Urbanyooba Inc (the “Company”) offers urbanyooba.com (the “Website”) according to the Terms of Service declared below. The Company reserves the right to revise these terms from time to time. We will post a notice of any material revisions on the Website. Your continued usage of the Website constitutes your acceptance of these terms, available below. Questions about the Terms of Service may be sent to this address: <a href="mailto: info@urbanyooba.com">info@urbanyooba.com</a></div>)
            
            },
            {
                title: "Usage Terms",
                content: renderFunction(
                <div>    
                    The Website is not suitable for all audiences. Its content is frequently presented in a witty and
                    colloquial manner that some may find offensive. If you do not consider yourself an appropriate user
                    or are offended, please do not visit the Website.<br/>
                    The Website is not intended for children under 13. By using the Website, you are representing that
                    you are at least 18, or that you are at least 13 years old and have your parents’ permission to use the
                    Website.<br/>
                    You may not use the Website for any illegal activity or to violate laws in your jurisdiction.
                    You may not exploit the Website to access unauthorized information.
                    The Company reserves the right to modify, suspend, or discontinue the Website for any reason, with
                    or without notice.<br/>
                    The Website is provided “as is” and “as available”. You assume complete responsibility and risk for
                    your use of the Website. The Company does not warrant that (i) the Website will meet your
                    requirements, (ii) you will be satisfied with the Website, (iii) you will at all times be able to use the
                    Website, (iv) the Website will be without errors, (v) or that any errors will be corrected.<br/>
                    The Company is not responsible for any damages or loss resulting from your use of the Website.
                    The Company is not liable for any damages or losses resulting from the Website transmitting
                    information such as personal messages over unencrypted networks such as email or other similar
                    channels.<br/>
                    Any failure of the Company to enforce or exercise a right provided in these terms is not a waiver of
                    the right in question.<br/>
                    Should any provision of this Terms of Service be found invalid or unenforceable, the remaining terms
                    shall still apply.<br/>
                    This Terms of Service constitutes the entire agreement between you and the Company and
                    supersedes any and all previous agreements, written or oral, between you and the Company,
                    including previous versions of the Terms of Service.
                </div>
                )
            },
            {
                title: "User Conduct",
                content: renderFunction(
                    <div>
                        Users may not publish to the Website any words, definitions, or other information (collectively,
                        “Content”) that:<br/>
                        is unlawful, threatening, libelous or defamatory;
                        violates any party’s intellectual property; or
                        is detrimental to the quality or intended spirit of the Website.<br/>
                        Examples of unacceptable Content or behavior on the Website are those which we, in our sole
                        discretion, determine constitute:
                        abuse, harassment, threats of violence, flaming, intimidation of any person or organization, or any
                        other threatening behavior;
                        engaging in or contributing to any illegal activity or activity that violates others’ rights;
                        providing information that is false, misleading or inaccurate;
                        hacking or modifying the Website to falsely imply an association with the Company;
                        implying or pretending to be affiliated with a company or organization with which you are not
                        affiliated, or misrepresenting the extent of your affiliation or role with an affiliated company or
                        organization; or
                        disclosing personal or proprietary information of another user, person or organization. The Company
                        has the right, but not the obligation, to limit or revoke the use privileges of anyone who publishes
                        unacceptable Content.<br/>
                        At any time, the Company may, at its sole discretion, refuse to allow Content to be published, or
                        remove Content that has been published. However, the Company is not obligated to restrict or
                        monitor submissions in any way, or to block users who submit inappropriate content.<br/>
                        By publishing Content, you represent and warrant that such Content:<br/>
                        does not violate the rights of any third party, including, but not limited to, intellectual property and
                        proprietary rights;<br/>
                        is not fraudulent or and does not involve counterfeit or stolen information or items;
                        does not violate any law, statute, ordinance or regulation; and<br/>
                        shall not create any liability for Urbanyooba Inc, or any of its respective parents, subsidiaries,
                        affiliates, successors, assigns, employees, agents, directors, officers and shareholders.<br/>
                        You may not submit Content using unauthorized automated methods (“bots”).<br/>
                        The Company does not and cannot control all Content published by third parties to the Website, and
                        does not guarantee the accuracy, integrity or quality of such Content. You understand that by using
                        the Website you may be exposed to Content that you may find offensive, indecent, incorrect or
                        objectionable, and you agree that under no circumstances will the Company be liable in any way for
                        any Content, including any errors or omissions in any Content, or any loss or damage of any kind
                        incurred as a result of your use of any Content. If you would like to report objectionable materials,
                        you may use the “Flag for review” feature on any topic or reply. Urgent issues can be reported to
                        this address: info@urbanyooba.com<br/>
                        You are solely responsible for any Content you publish on the Website, and the consequences of
                        publishing such Content. You agree to indemnify, defend, and hold harmless the Company, its
                        officers, directors, employees, and agents from and against any and all claims, losses, costs,
                        liabilities, damages, judgments, penalties, interest and expenses (including reasonable attorneys’
                        fees) arising out or relating to (i) any actual or alleged breach of your representations, warranties, or
                        obligations set forth in these Terms of Service, and (ii) any actual or alleged infringement of any
                        intellectual property or proprietary rights by any Content or other information you publish to the
                        Website.<br/>
                        Use of the Website is also governed by our Privacy Policy, a copy of which is currently located
                        at http://urbanyooba.com/pages/privacy
                    </div>)
            },
            {
                title: "Copyright and Ownership",
                content: renderFunction(
                <div>
                    The Company owns intellectual property rights to the Website and all elements thereof, including
                    but not limited to the design, artwork, functionality, and documentation. You may not copy, modify,
                    or reverse engineer any part of the Website owned by the Company.<br/>
                    To the extent you own rights in any Content you publish on the Website, such rights shall remain
                    yours solely and exclusively.<br/>
                    When you publish Content on the Website, you grant the Company an irrevocable, perpetual,
                    worldwide, royalty-free, fully sublicenseable, non-exclusive license to copy, distribute, sell, publicly
                    display, publicly perform and make derivative works of your Content on the Website, and on services
                    affiliated with the Website and elsewhere (including but not limited to print, video, audio or
                    computer media), regardless of the form of media used or of whether such media or services now
                    exist or are developed in the future. By publishing Content to the Website, you hereby represent and
                    warrant that you have all rights necessary to publish that Content and to grant the foregoing license
                    to the Company. The Company respects the intellectual property of others. It may, in appropriate
                    circumstances and at its discretion, disable and/or terminate the accounts of users who repeatedly
                    infringe others’ rights.<br/>
                    If you believe that your work has been copied in a way that constitutes copyright infringement, or
                    your intellectual property rights have been otherwise violated, please provide the following
                    information to the Company:
                    an electronic or physical signature of the person authorized to act on behalf of the owner of the
                    copyright or other intellectual property interest;
                    a description of the copyrighted work or other intellectual property that you claim has been
                    infringed;
                    a description of where the material that you claim is infringing is located on the site;
                    your address, telephone number, and email address;
                    a statement by you that you have a good faith belief that the disputed use is not authorized by the
                    copyright owner, its agent, or the law; and
                    a statement by you, made under penalty of perjury, that the above information in your notice is
                    accurate and that you are the copyright or intellectual property owner or are authorized to act on
                    the copyright or intellectual property owner’s behalf.<br/>
                    The Company’s agent for notice of claims of copyright or other intellectual property infringement
                    can be reached as follows:<br/>
                    By email: info@urbanyooba.com
                    </div>)
            },
            {
                title: "Participation Disclaimer",
                content: renderFunction(
                <div>
                    The Company does not and cannot review all Content published to the Website or created by users
                    accessing the Website, and is not in any manner responsible for the content of any Content or the
                    activities of any such users.<br/>
                    You acknowledge that by providing you with the ability to view and distribute user-generated
                    content through the Website, the Company is merely acting as a passive conduit for the distribution
                    of such information and is not undertaking any obligation or liability relating to the Content or the
                    Website users’ activities.<br/>
                    The Company and its respective parents, subsidiaries, affiliates, successors, assigns, employees,
                    agents, directors, officers and shareholders do not undertake or assume any duty to monitor the
                    Website for inappropriate Content.<br/>
                    The Company and its respective parents, subsidiaries, affiliates, successors, assigns, employees,
                    agents, directors, officers and shareholders assume no responsibility or liability which may arise
                    from the Content thereof, including, but not limited to, claims for defamation, libel, slander,
                    infringement, invasion of privacy and publicity rights, obscenity, pornography, profanity, fraud, or
                    misrepresentation.<br/>
                    “Yoodict” and “Yootrends” are registered trademarks of Urbanyooba Inc, all rights reserved. Yoodict
                    and Yootrends logos are trademarks of Urbanyooba Inc.<br/>
                    Access to the Yoodict of Yootrends API is available only with the express permission of Urbanyooba
                    Inc and is governed by the Urbanyooba Inc Terms of Service.
                </div>)
            },
            {
                title: "Unsolicited Idea Policy",
                content: `Urbanyoba Inc or any of its employees do not accept or consider unsolicited ideas, including ideas
                for new advertising campaigns, new promotions, new or improved products or technologies,
                product enhancements, processes, materials, marketing plans or new product names. Please do not
                submit any unsolicited ideas, original creative artwork, suggestions or other works (“submissions”) in
                any form to Urbanyooba Inc or any of its employees. The sole purpose of this policy is to avoid
                potential misunderstandings or disputes when Urbanyooba Inc’s products or marketing strategies
                might seem similar to ideas submitted to Urbanyooba Inc. If, despite our request that you not send
                us your ideas, you still submit them, then regardless of what your letter says, the following terms
                shall apply to your submissions.`
            },
            {
                title: "TERMS OF IDEA SUBMISSION",
                content: `You agree that: (1) your submissions and their contents will automatically become the property of
                Urbanyooba Inc, without any compensation to you; (2) Urbanyooba Inc may use or redistribute the
                submissions and their contents for any purpose and in any way; (3) there is no obligation for
                Urbanyooba Inc to review the submission; and (4) there is no obligation to keep any submissions
                confidential.`
            },
            {
                title: "Feedback and Information",
                content: `Any feedback you provide at this site shall be deemed to be non-confidential. Urbanyooba Inc shall
                be free to use such information on an unrestricted basis.`
            },
            {
                title: "Advertising",
                content: `We use third-party advertising companies to serve ads when you visit our Web site. These
                companies may use aggregated information (not including any personally identifiable information
                like your name, address, email address or telephone number) about your visits to this and other
                Web sites in order to provide advertisements about goods and services of interest to you`
            }
        ];

    const header = "TERMS OF SERVICE"
    
    const dormantFunction = () => {
        return
    }
    return (
        <>
            <MetaDecorator title="Terms and Conditions" description="Urbanyooba Term and Conditions"/>
            <NavBar altUpperDisplay={dormantFunction} onSearch={dormantFunction} onClick={dormantFunction}/>
            <InfoBanner header={header}/>
            <div className="privacy">
            <div className="nav-back-tc">
                <Link to="/yoodict">
                    <img src={BackButton} alt="back to yoodict" className="back-button-1" />
                BACK TO DICTIONARY </Link>
            </div>
                {
                    tcArray.map( el => <InfoContent key={ el.title } title={ el.title } content={ el.content } /> )
                }
                <div className="tc-intro">
                Updated: December 2020
                </div>
            </div>
            <Footer/>
        </>
    )
}

export default TandC;