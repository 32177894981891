import React from 'react'

import Footer from '../../components/footer/footer'
import NewWordForm from '../../components/newwordform/newwordform'
import NavBar from '../../components/navbar/navbar'

const NewWordPage = (props) => {

    const dormantFunction = () => {
        return
    }

    return (
        <>
            <NavBar altUpperDisplay={dormantFunction} onSearch={dormantFunction} onClick={dormantFunction}/>
            <NewWordForm props={props}/>
            <Footer/>
        </>
    )
}

export default NewWordPage;