
import React from 'react'

import './profileinfo.scss'
import DownArrow from '../../icons/down-chevron.png'

const WordDefCard = ({ title, content, cardNumber}) => {
    const [ ShowDropDown, setShowDropDown ] = React.useState( false );

    const toggleShowDropDown = () =>
    {
        setShowDropDown( prevState => !prevState );
    };
        return(
            <div className="wordsdef">
            <div className="wordsdef-dropDown">
                <div className="wordsdef-content-header" onClick={ toggleShowDropDown }>
                    <h4 className="wordsdef-title-text">
                        <span className="wordsdef-number">{cardNumber}</span>{title}
                    </h4>
                    <img style={ { display: ShowDropDown ? 'none' : 'block' } } alt="show info" className="wordsdef-up-arrow" src={DownArrow} />
                    <img style={ { display: ShowDropDown ? 'block' : 'none' } } alt="hide info" className="wordsdef-down-arrow" src={DownArrow} />
                </div> 
                <div style={ { display: ShowDropDown ? 'block' : 'none' } } className="wordsdef-content">
                    <p>{content}</p>
                </div>
            </div>
            </div>
        )
}

export default WordDefCard;