import React from 'react'

import ForgotForm from '../../components/forgotpassword/forgotform'
import Footer from '../../components/footer/footer'
import NavBar from '../../components/navbar/navbar'

const ForgotPassword = () => {

    const dormantFunction = () => {
        return
    }

    return (
        <>
            <NavBar altUpperDisplay={dormantFunction} onSearch={dormantFunction} onClick={dormantFunction}/>
            <ForgotForm/>
            <Footer></Footer>
        </>
    )
}

export default ForgotPassword