import React, { useLayoutEffect } from 'react'
import { ErrorAlert, SuccessAlert } from '../../App'
import Axios from 'axios'
import API_BASE from '../../apiBase'
import { useHistory } from 'react-router-dom'
import MetaDecorator from '../../MetaDecorator'

const VerifyMail = (props) => {
  const token = new URLSearchParams(props.location.search).get('token');
  const history = useHistory()
  useLayoutEffect(() => {
    if(!token.trim()){
      ErrorAlert('Invalid link')
    }
    Axios.put(`${API_BASE}/auth/mail`, {
      token
    })
    .then(response => {
      SuccessAlert(`Hi ${response.data.user}, ${response.data.message}`)
      setTimeout(() => history.push('/yoodict') , 1200)
    })
    .catch(err => {
      console.log(err)
      ErrorAlert(err.response.data.error)
      setTimeout(() => history.push('/yoodict') , 1200)
    })
  }, [token, history])
  return (
    
    <div>
      <MetaDecorator title="Verify Email" description=""/>
    </div>
  )
}

export default VerifyMail