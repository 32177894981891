import React from 'react'
import './loginpage.scss'

import LoginForm from '../../components/login/login'
import Footer from '../../components/footer/footer'
import NavBar from '../../components/navbar/navbar'
import MetaDecorator from '../../MetaDecorator'

const LoginPage = () => {

    const dormantFunction = () => {
        return
    }

    return (
        <>
            <MetaDecorator title="Login" description="Login to contribute to the yoodict dictionary"/>
            <NavBar altUpperDisplay={dormantFunction} onSearch={dormantFunction} onClick={dormantFunction}/>
            <div className="loginandsignup">
                <LoginForm/>
                <Footer/>
            </div>
        </>
    )
}

export default LoginPage;