import React, { useEffect, useState, useRef, useCallback  } from 'react';
import {Link, useHistory} from 'react-router-dom'

import InfoBanner from '../../components/infobanner/infobanner'
import WordCard from '../../components/wordcard/wordcard'
import BannerSection from '../../components/banner-section/bannersection'
import './homepage-slug.scss'
import axios from 'axios'
import { toast as Toastify } from 'react-toastify';
import NavBar from '../../components/navbar/navbar'
import {SpinnerContainer, SpinnerOverlay} from '../../components/spinner/spinner.styles'
import BackButton from '../../icons/right-chevron (1).png'
import API_BASE from '../../apiBase';
import MetaDecorator from '../../MetaDecorator'

const HomepageSlug = (props) => {
  const [fetchUrl, setFetchUrl] = useState(`${API_BASE}/word/${props.match.params.slug}`)
  const [fetchedWords, setFetchedWords] = useState([])
  const [upperLevelDisplay, setUpperLevelDisplay] = useState(false)
  const [searchFetchUrl, setSearchFetchUrl] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [hasMore, setHasMore] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const history = useHistory();

  const observer = useRef()
  const lastBookElementRef = useCallback(node => {
    if(isLoading) return 
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if(entries[0].isIntersecting && hasMore){
        setPageNumber(prevPageNumber => prevPageNumber+1)
      }
    })
    if(node) observer.current.observe(node)
  }, [isLoading, hasMore])
  
  const InfoAlert = (message) => Toastify(message, {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });
    
  useEffect(() => {
    function fetchData(urlToGet) {
      axios.get(urlToGet).then(response => {
          const word = response.data.word
              setFetchedWords([{
                  id:                 word._id,
                  trend:              word.trend ? word.trend : false ,
                  createdAt:          word.createdAt,
                  downvoteCount:      word.downvoteCount,
                  downvotes:          word.downvotes,
                  examples:           word.examples, 
                  literalTranslation: word.literalTranslation,
                  meaning:            word.meaning,
                  origin:             word.origin,
                  phonetics:          word.phonetics,
                  postedBy:           word.postedBy,
                  similarWords:       word.similarWords,
                  slug:               word.slug,
                  status:             word.status,
                  updatedAt:          word.updatedAt,
                  upvoteCount:        word.upvoteCount,
                  upvotes:            word.upvotes,
                  views:              word.views,
                  word:               word.word
              }])
          }).catch(err => {
              console.log(err)
              InfoAlert('Word not found');

          })
          setIsLoading(false)
      }

      fetchData(fetchUrl);

      }, [fetchUrl])


  useEffect(() => {
    function fetchData(urlToGet) {
      axios.get(urlToGet, {page: pageNumber}).then(response => {
        const responseData = response.data
  
        if (responseData.words.length > 0){
            setFetchedWords(fetchedWords => fetchedWords.concat(responseData.words.map(word => ({
                id:                 word._id,
                createdAt:          word.createdAt,
                trend:              word.trend ? word.trend : false ,
                downvoteCount:      word.downvoteCount,
                downvotes:          word.downvotes,
                examples:           word.examples,
                literalTranslation: word.literalTranslation,
                meaning:            word.meaning,
                origin:             word.origin,
                phonetics:          word.phonetics,
                postedBy:           word.postedBy,
                similarWords:       word.similarWords,
                slug:               word.slug,
                status:             word.status,
                updatedAt:          word.updatedAt,
                upvoteCount:        word.upvoteCount,
                upvotes:            word.upvotes,
                views:              word.views,
                word:               word.word
            }))))
          }
          setHasMore(response.data.words.length > 0)
        }).catch(err => console.log(err))
  
            setIsLoading(false)
        }
  
  
    fetchData(searchFetchUrl);
  
    }, [searchFetchUrl, pageNumber])

  function handleFetchUrlChange(newFetchUrl) {
    setIsLoading(true)
    setFetchUrl(newFetchUrl);
  }

  function handleSearchFetchUrlChange(newFetchUrl) {
    setSearchFetchUrl(newFetchUrl);
  }

  function alternateUpperDisplay(){
    setUpperLevelDisplay(!upperLevelDisplay)
  }
   
  return (
    <div>
          <MetaDecorator title="Yoodict - Buzzwords dictionary" description="Yoodict is a buzzwords dictionary providing you with trending slangs definitions"/>
          <NavBar upperDisplay={upperLevelDisplay} altUpperDisplay={alternateUpperDisplay} onSearch={handleSearchFetchUrlChange} onFocus={() => history.push('/yoodict')} />
          <InfoBanner header={''}/>
          <div>
          <div className="ydt-fluid">
            <div id="left-pack" className="slug-left-pack">
            <div className="nav-back">
                <Link to="/yoodict"><img alt="back to yoodict" src={BackButton} className="back-button" />BACK TO DICTIONARY </Link>
            </div>
            <React.Fragment>
            {!isLoading ? 
            (
                fetchedWords.map( (item, index) => {
                  if(fetchedWords.length === index+1){
                    return <div key={item.id} ref={lastBookElementRef} className="wordcard">
                    <WordCard 
                      isLoading={isLoading}
                      trend={item.trend}
                      key={item.slug} 
                      slug={item.slug} 
                      id={item.id}
                      definition={item.meaning} 
                      word={item.word}
                      origin={item.origin}
                      literalTranslation={item.literalTranslation}
                      phonetics={item.phonetics}
                      examples={item.examples}
                      postedBy={item.postedBy}
                      createdAt={item.createdAt}
                      upvoteCount={item.upvoteCount}
                      downvoteCount={item.downvoteCount}
                      similarWords={item.similarWords}
                      upvotes={item.upvotes}
                      downvotes={item.downvotes}
                    />
                  </div>
                  } else {
                    return <div key={item.id} className="wordcard">
                    <WordCard 
                      isLoading={isLoading}
                      key={item.slug}
                      trend={item.trend}
                      slug={item.slug} 
                      id={item.id}
                      definition={item.meaning} 
                      word={item.word}
                      origin={item.origin}
                      literalTranslation={item.literalTranslation}
                      phonetics={item.phonetics}
                      examples={item.examples}
                      postedBy={item.postedBy}
                      createdAt={item.createdAt}
                      upvoteCount={item.upvoteCount}
                      downvoteCount={item.downvoteCount}
                      similarWords={item.similarWords}
                      upvotes={item.upvotes}
                      downvotes={item.downvotes}
                    />
                  </div>
                  }
                  }
                )
              ) : (
                  <SpinnerOverlay>
                      <SpinnerContainer/>
                  </SpinnerOverlay> 
              )}
          </React.Fragment>
              </div>
            <div className="hp-slug-banner-section">
              <BannerSection isLoading={isLoading}/>
            </div>
          </div>
          </div>
    </div>
  );
}

export default HomepageSlug;