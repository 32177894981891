import React from 'react'

import './profileinfo.scss'
import Card from 'react-bootstrap/Card'

import WordDefCard from './worddefcard'

const WordsDef = ({wordsDefined, cardNumber}) => {

    return (
        <>
            <div className="">
                <Card className="worddefbottom">
                <Card.Body>
                    <Card.Title className="wordsdefbyuser">
                        <div className="wordsdef-row-2"> 
                        {
                            wordsDefined.map( el => <WordDefCard key={ el.title } cardNumber={cardNumber++} title={ el.title } content={ el.content } /> )
                        }
                    </div>
                    </Card.Title>
                </Card.Body>
                </Card>
            </div>
        </>
    )
}

export default WordsDef;