import React from 'react'

import './button.scss'
import Button from 'react-bootstrap/Button'

const SpecButton = ({name, ...rest}) => {
    return (
        <>
            <Button className="spec-button" {...rest}>{name}</Button>
        </>
    )
} 

export const ViewMoreButton = ({name, ...rest}) => {
    return (
        <>
            <Button className="viewmore-button" {...rest}>{name}</Button>
        </>
    )
} 

export const PageButton = ({name, ...rest}) => {
    return (
        <>
            <Button className="page-button" {...rest}>{name}</Button>
        </>
    )
} 

export default SpecButton