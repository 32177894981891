import React, { useEffect, useState, useCallback } from 'react'
import {useLocation} from 'react-router-dom'
import Table from 'react-bootstrap/Table'
import './adminintbar.scss'
import Pagination from "./pagenation"
import {ViewMoreButton} from '../../pages/adminpage/button' 
import ApprovedContent from './ApprovedContent'
import UnapprovedContent from './UnapprovedContent'
import TrendPageContent from './TrendPageContent'
import './wordupdate.scss'
import WordUpdateModal from './wordupdate'
import Modal from 'react-bootstrap/Modal'

const PendingContent = ({ pageName, fetchData, numberOfPages, setFetchPageNumber, isLoading, sortState, contentHeaderArray, contentObject, pageNumber }) => {
    const [modalShow, setModalShow] = useState(false);
    
    let total = numberOfPages;
    const [active, setActive] = useState(1);
    const [pages, setPages] = useState([]);
    
    useEffect(() => {
        if (total <= 7) {
        let arr = [];
        for (let i = 1; i <= total; i++) {
            arr.push(i);
        }
        setPages(arr);
        return;
        }
        if (active <= 4) {
        setPages([1, 2, 3, 4, 5, "...", total]);
        }
        if (active > 4 && active < total - 3) {
        setPages([1, "...", active - 1, active, active + 1, "...", total]);
        }
        if (active > total - 4) {
        setPages([1, "...", total - 4, total - 3, total - 2, total - 1, total]);
        }
    }, [active, total]);

    const handleClick = useCallback(e => {
        if (e.target.value === 0) {
        return;
        }
        setActive(e.target.value);
        setFetchPageNumber(e.target.value)
    }, [setFetchPageNumber]);

    var URL = useLocation().pathname;

    const filteredArray = contentObject.sort((a,b) => {
        if (sortState === 'Alphabetically' && !URL.includes('/createtrend')){
            return 1 * a.word.localeCompare(b.word)
        } else if (sortState === 'Reverse' && !URL.includes('/createtrend')){
            return -1 * a.word.localeCompare(b.word)
        } else if (sortState === 'Date'){
            return -1 * a.createdAt.toLocaleString().localeCompare(b.createdAt.toLocaleString())
        } else if (sortState === 'UpVote' && URL.includes('/approved')){
            return -1 * (a.upvoteCount).toString().localeCompare(b.upvoteCount.toString())
        } else if (sortState === 'DownVote' && URL.includes('/approved')){
            return -1 * a.downvoteCount.toString().localeCompare(b.downvoteCount.toString())
        } else if (sortState === 'Reverse' && URL.includes('/createtrend')){
            return -1 * a.title.localeCompare(b.title)
        } else if (sortState === 'Alphabetically' && URL.includes('/createtrend')){
            return 1 * a.title.localeCompare(b.title)
        }
    })

    const closeModal = () => {
        setModalShow(false)
    }

    const [WordDetailsObject, setWordDetailsObject] = useState({    
        word:     '',
        meaning:  '',
        origin:   '',
        examples: '',
        similarWords: '',
        literalTranslation: '',
        phonetics: ''
    })
    
    const handlePopUp = (id, word, meaning, origin, examples, similarWords, literalTranslation, phonetics) => {
        setWordDetailsObject({    
            id: id,
            word: word,
            meaning: meaning,
            origin: origin,
            examples: examples,
            similarWords: similarWords,
            literalTranslation: literalTranslation,
            phonetics: phonetics
        })
        
        setModalShow(true)
    }

    function WordObjectModal(props) {
        return (
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="wordupdate-modal"
          >
            <WordUpdateModal  CloseModal={closeModal} fetchData={fetchData} WordDetailsObject={WordDetailsObject} popUpTitle="Pending Word" button1="APPROVE" button2="UNAPPROVE" button3="UPDATE" />
          </Modal>
        );
      }
        if (filteredArray.length > 0 && pageName === 'Pending Words') {
            return (
                <>
                    <div className="words-table">
                        <Table responsive>
                            <thead>
                                <tr>
                                    {
                                        contentHeaderArray.map((header, index) => (
                                            <th key={index}>{header}</th>
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                    {filteredArray.map((word, index) => (
                                        <tr key={word.id}>
                                            <td>{index+1}</td>
                                            <td>{(new Date(word.createdAt)).toLocaleDateString()}</td>
                                            <td style={{wordBreak: word.postedByName.length > 15 ? 'unset' : 'break-all'}}>{word.postedByName}</td>
                                            <td style={{wordBreak: word.postedByEmail.length > 15 ? 'unset' : 'break-all'}}>{word.postedByEmail}</td>
                                            <td className="split-view">{word.word} 
                                            <ViewMoreButton 
                                            onClick={() => 
                                                handlePopUp(
                                                    word.id, 
                                                    word.word, 
                                                    word.meaning, 
                                                    word.origin, 
                                                    word.examples, 
                                                    word.similarWords, 
                                                    word.literalTranslation, 
                                                    word.phonetics)} name="VIEW MORE" /></td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                        <Pagination
                            handleClick={handleClick}
                            pages={pages}
                            active={active}
                        />
                      </div>
            <WordObjectModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            />
                </>

            )
        } else if (contentObject.length > 0 && pageName === 'Approved Words') {
            return <ApprovedContent fetchData={fetchData} isLoading={isLoading} numberOfPages={numberOfPages} setFetchPageNumber={setFetchPageNumber}  contentObject={filteredArray} pageNumber={pageNumber} contentHeaderArray={contentHeaderArray} />
         } else if (contentObject.length > 0 && pageName === 'Unapproved Words') {
            return <UnapprovedContent fetchData={fetchData} isLoading={isLoading} numberOfPages={numberOfPages} setFetchPageNumber={setFetchPageNumber} contentObject={filteredArray} pageNumber={pageNumber} contentHeaderArray={contentHeaderArray} />
          } else if (contentObject.length > 0 && pageName === 'Trends Page') {
            return <TrendPageContent fetchData={fetchData} isLoading={isLoading}  numberOfPages={numberOfPages} setFetchPageNumber={setFetchPageNumber} contentObject={filteredArray} pageNumber={pageNumber} contentHeaderArray={contentHeaderArray} />
          }else return (
            <div style={{textAlign: 'center'}}> No words to show </div>
        )
}

export default PendingContent