import React, { useState, useRef, useEffect } from "react";
import useWordSearch from "../navbar/useWordSearch";
import "./suggesttags.scss";

const SuggestTags = (props) => {
  const [query, setQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [display, setDisplay] = useState(false);
  const [slug, setSlug] = useState(props.slug);

  const { words, hasMore, loading, error } = useWordSearch(query, pageNumber);

  const setSearchField = (text) => {
    setQuery(text.word);
    handleKeyDown(text.word, text._id, text.slug);
  };

  const removeTags = (indexToRemove) => {
    props.setTags([...props.tags.filter((_, index) => index !== indexToRemove)]);
  };
  const addTags = (input, uuid, slugg) => {
    props.setTags([...props.tags, { _id: uuid, word: input }]);
    setSlug([...slug, slugg]);
    props.selectedSlug([...slug, slugg]);
  }; 
  const handleKeyDown = (text, uid, slg) => {
    addTags(text, uid, slg);
    setQuery("");
  };

  useEffect(() => {
    props.setTags(props.tags)
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (e) => {
    // const {current: wrap} = wrapperRef;
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      // e.preventDefault();
      setDisplay(false);
    }
  };

  const wrapperRef = useRef(null);

  return (
    <div ref={wrapperRef} className="search-autocomplete">
      <div className="tags-input">
        <ul id="tags">
          {props.tags.map((tag, index) => (
            <li key={index} className="tag">
              <span className="tag-title">{tag.word}</span>
              <span
                className="tag-close-icon"
                onClick={() => removeTags(index)}
              >
                x
              </span>
            </li>
          ))}
        </ul>
        <input
          type="text"
          placeholder="Select word to tag"
          onFocus={() => setDisplay(true)}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
      </div>
      {display && (
        <div className="suggestions">
          {words.map((word) => {
            return (
              <div
                onClick={() => setSearchField(word)}
                tabIndex="0"
                className="option"
                key={word._id}
              >
                <span>{word.word}</span>
              </div>
            );
          })}
          <div>{loading && "Word not found.."}</div>
        </div>
      )}
    </div>
  );
};

export default SuggestTags;
