import React from 'react'

import Footer from '../../components/footer/footer'
import EditProfileForm from '../../components/editprofileform/editprofileform'
import NavBar from '../../components/navbar/navbar'
import MetaDecorator from '../../MetaDecorator'

const EditProfilePage = () => {

    const dormantFunction = () => {
        return
    }
    return (
        <>
            <MetaDecorator title="Edit Profile" description="Edit user profile"/>
            <NavBar altUpperDisplay={dormantFunction} onSearch={dormantFunction} onClick={dormantFunction}/>
            <EditProfileForm/>
            <Footer/>
        </>
    )
}

export default EditProfilePage;