import React,{useState, useContext} from 'react'

import './forgotform.scss'
import ForgotAlert from './forgotalert'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {AuthContext} from '../../App'

const ForgotForm = () => {
    const AppContext = useContext(AuthContext)

    const [ ShowAlert, setShowAlert ] = useState( false );
    const [user, setUser] = useState('')
    const resetLinkBase = process.env.REACT_APP_RESET_LINK_BASE;

    const toggleShowAlert = () =>
    {
        setShowAlert( prevState => !prevState );
    };
    
    const handleSubmit = (event) => {
        event.preventDefault();
        AppContext.passwordreset(user, resetLinkBase);
        setUser('')
    }

    return (
        <>
            <div style={ { display: ShowAlert ? 'none' : 'flex' } } className="forgot-form">
            <Form id="forgotForm" onSubmit={(e) => handleSubmit(e)}>
            <div className="form-header">Forgot Password ?</div>
            <div className="form-header-info">
                Enter the email address you used when you registered and we will send the instructions on how to retrieve your password.
            </div>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control size="lg" required onChange={e => {setUser(e.target.value)}} type="text" placeholder="" />
                </Form.Group>
                <Button id="forgotButton" type="submit" block>
                    Reset Password
                </Button>
            </Form>
            </div>
            <div style={ { display: ShowAlert ? 'flex' : 'none' } } className="forgot-alert">
                <ForgotAlert/>
            </div>
        </>
    )
}

export default ForgotForm;