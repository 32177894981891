import React, {useState, useEffect} from 'react'

import './wordupdate.scss'
import {useLocation} from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import {ViewMoreButton} from '../../pages/adminpage/button'
import {newAxios, SuccessAlert, ErrorAlert} from '../../App'
import TagInput from '../taginput/taginput'
import SuggestTags from '../suggetstags/suggesttags'
import API_BASE from '../../apiBase'

const WordUpdateModal = ({CloseModal,  fetchData, WordDetailsObject,popUpTitle,button1,button2,button3,button4}) => {
    const [similarTags, setSimilarTags] = useState(WordDetailsObject.similarWords)
    const [exampleTags, setExampleTags] = useState(WordDetailsObject.examples)
    const [slug, setSlug] = useState([])

    var URL = useLocation();

    const [newWordObject, setNewWordObject] = useState({
        id : WordDetailsObject.id,
        meaning:                WordDetailsObject.meaning,
        origin:                 WordDetailsObject.origin,
        examples:               exampleTags,
        similarWords:           similarTags,
        literalTranslation:     WordDetailsObject.literalTranslation,
        phonetics:              WordDetailsObject.phonetics
    })

    const validateWordUpdate = () => {
        if (
            newWordObject.meaning.trim() === ""
        ) {
            ErrorAlert("Please fill word meaning");
            return false;
        } else if (
            newWordObject.examples.length === 0
        ) {
            ErrorAlert("Please fill at least one example");
            return false;
        } else if (
            newWordObject.origin === ""
        ) {
            ErrorAlert("Please select an origin");
            return false;
        } 
        if (
            newWordObject.examples.length > 0 && JSON.stringify(newWordObject.examples) === JSON.stringify(WordDetailsObject.examples)
        ){
            delete newWordObject.examples
        }
        if (
            newWordObject.similarWords.length > 0 && JSON.stringify(newWordObject.similarWords) === JSON.stringify(WordDetailsObject.similarWords)
        ){
            delete newWordObject.similarWords
        }
        if (
            newWordObject.literalTranslation?.trim() === WordDetailsObject.literalTranslation?.trim()
        ){
            delete newWordObject.literalTranslation
        }
        if (
            newWordObject.phonetics?.trim() === WordDetailsObject.phonetics?.trim()
        ){
            delete newWordObject.phonetics
        }
        if (
            newWordObject.meaning.trim() === WordDetailsObject.meaning?.trim()
        ){
            delete newWordObject.meaning
        }
        if (
            newWordObject.origin.trim() === WordDetailsObject.origin?.trim()
        ){
            delete newWordObject.origin
        }
        return true;
    }

    useEffect(() => {
      setNewWordObject({
        ...newWordObject,
        examples: exampleTags,
      });
  
    }, [exampleTags]);
  
    useEffect(() => {
      setNewWordObject({
        ...newWordObject,
        similarWords: similarTags,
      });
    }, [similarTags]);

    const selectedSlug = (slug) => {
        setSlug(slug)
    }

    const handleRejectWord = () => {
        newAxios.put(`${API_BASE}/word/reject`, {word: newWordObject.id})
        .then(res => {
            SuccessAlert(res.data.message)
             fetchData()
             CloseModal()
            }
        ).catch(err => {console.log(err); ErrorAlert('Error updating word')})
    }

    const deleteWord = () => {
        newAxios.delete(`${API_BASE}/word/${newWordObject.id}`)
        .then(res => {
            SuccessAlert(res.data.message)
             fetchData()
             CloseModal()
            }
        ).catch(err => {console.log(err); ErrorAlert('Error deleting word')})
    }

    const handleApproval = () => {
        newAxios.put(`${API_BASE}/word/approve`, {word: newWordObject.id})
        .then(res => {
            SuccessAlert(res.data.message)
             fetchData()
             CloseModal()
        }).catch(err => {console.log(err); ErrorAlert('Error updating word')})
    }

    const handleUpdate = () => {
        if(validateWordUpdate()) {
            newAxios.put(`${API_BASE}/word/${WordDetailsObject.id}`, 
                newWordObject
            )
            .then(res => {
                SuccessAlert(res.data.message)
                if (URL.pathname === '/admin/approved' || URL.pathname === '/admin/unapproved'  || URL.pathname === '/admin/pending' ){
                     fetchData()
                     CloseModal()
                }
            }).catch(err => {console.log(err.response); ErrorAlert('Error updating word')})
        }
    }
    
    return (
            <Form id="wordupdateForm">
            <div className="wordupdateform-header">{popUpTitle}</div>
            <div className="wordupdate row1">
                <Form.Group as={Col} md="8" controlId="formBasicWord">
                    <Form.Label>Enter word or short phrase <span className="req">*</span></Form.Label>
                    <Form.Control value={WordDetailsObject.word} readOnly={true} required size="lg" type="text" placeholder="e.g Duduke, E be thingz" />
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="formBasicOrigin">
                    <Form.Label>Origin of word <span className="req">*</span></Form.Label>
                    <Form.Control defaultValue={newWordObject.origin} onChange={e => {newWordObject.origin = e.target.value}} as="select" required size="lg" type="text" placeholder="">
                        <option value="0">Origin...</option>
                        <option value="Unknown">Unknown</option>
                        <option value="Hausa">Hausa</option>
                        <option value="Igbo">Igbo</option>
                        <option value="Pidgin">Pidgin</option>
                        <option value="Yoruba">Yoruba</option>
                    </Form.Control> 
                </Form.Group>
            </div>
            <div className="wordupdate row2">
                <Form.Group as={Col} md="8" controlId="formBasicMeaning">
                    <Form.Label>Meaning <span className="req">*</span></Form.Label>
                    <Form.Control defaultValue={newWordObject.meaning} onChange={e => {newWordObject.meaning = e.target.value.trim()}} as="textarea" rows="5" />
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="formBasicExample">
                    <Form.Label>Short example <span className="req">*</span></Form.Label>
                    <TagInput setTags={setExampleTags} tags={exampleTags} tagBoxId='wordupdate'/>
                    <Form.Label>Similar words <span className="req"></span></Form.Label>
                    <SuggestTags selectedSlug={selectedSlug} slug={slug} setTags={setSimilarTags} tags={similarTags}/>
                </Form.Group>
            </div>
            <div className="wordupdate row3">
                <Form.Group as={Col} md="6" controlId="formBasicLitTrans">
                    <Form.Label>Literal Translation</Form.Label>
                    <Form.Control defaultValue={newWordObject.literalTranslation} onChange={e => {newWordObject.literalTranslation = e.target.value.trim()}}size="lg" type="text" placeholder="" />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="formBasicPhonics">
                    <Form.Label>Phonetics Translation</Form.Label>
                    <Form.Control defaultValue={newWordObject.phonetics} onChange={e => {newWordObject.phonetics = e.target.value.trim()}} size="lg" type="text" placeholder="e.g Du-du-keh" />
                </Form.Group>
            </div>
            <div className="dual-buttons">
                {button3 ? <ViewMoreButton onClick={handleUpdate} name={button3}/> : <></>}
                {button1 ? <ViewMoreButton onClick={handleApproval} name={button1}/> : <></>}
                {button2 ? <ViewMoreButton onClick={handleRejectWord} name={button2}/> : <></>}
                {button4 ? <ViewMoreButton onClick={deleteWord} name={button4}/> : <></>}
                <Button id="cancelWordButton" onClick={CloseModal} >
                    <span className="cancel-button">CANCEL</span>
                </Button>
            </div>
            </Form>
    )
}

export default WordUpdateModal