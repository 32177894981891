import React from 'react'
import './signuppage.scss'

import SignUpForm from '../../components/signup/signup'
import Footer from '../../components/footer/footer'
import NavBar from '../../components/navbar/navbar'
import MetaDecorator from '../../MetaDecorator'

const SignUpPage = () => {

    const dormantFunction = () => {
        return
    }

    return (
        <>
            <MetaDecorator title="Login" description="Login to contribute to the yoodict dictionary"/>
            <NavBar altUpperDisplay={dormantFunction} onSearch={dormantFunction} onClick={dormantFunction}/>
            <div className="loginandsignup">
                <SignUpForm/>
                <Footer/>
            </div>
        </>
    )
}

export default SignUpPage;