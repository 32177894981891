import React from 'react'

import './footer.scss'
import {Link} from 'react-router-dom'

const Footer = () => {
    return (
        <>
            <div className="pagewide-footer">
                <Link to="/termsandconditions">Terms and Conditions  &#124; </Link><Link to="/help"> Help &#124; </Link><Link to="/privacypolicy"> Privacy Policy</Link>
            </div>
        </>
    )
}

export default Footer;