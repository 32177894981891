import React from 'react'

import './helpbanner.scss'

import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'

const HelpBanner = ({header}) => {
    return (
        <>
            <Jumbotron fluid className="help-banner">
                <Container>
                    <div className="help-header">{header}</div>
                </Container>
            </Jumbotron>
        </>
    )
}

export default HelpBanner;