import React from 'react'
import {Link} from 'react-router-dom'
import './alphanavbar.scss'
import $ from 'jquery'
import RightIcon from '../../icons/right-chevron.png'
import LeftIcon from '../../icons/right-chevron.png'

const AlphaNavBar = () => {

    const alphabets = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z']

    function LetterLeftScroll () { 
        $(document).ready(function(){
            document.getElementById('letter-scroll').scrollLeft -= 30;
        });
    };
    function LetterRightScroll () {
        $(document).ready(function(){
            document.getElementById('letter-scroll').scrollLeft += 30;
        });
    };

    return (
        <>
            <div className="horizontal-nav">
                <div className="scroll-left" id="scroll-left" onClick={() => LetterLeftScroll()}>
                    <img src={LeftIcon} alt="left-cion" className="left-icon"/>
                </div>
                    <div className="letter-scroll" id="letter-scroll">
                        {
                            alphabets.map( (el, i) => <div key={i} className="letter"><Link to={`/yoodict/alphabet/${el}`}>{el}</Link></div> )
                        }
                    </div>
                <div className="scroll-right" id="scroll-right" onClick={() => LetterRightScroll()}>
                     <img src={RightIcon} alt="right-cion" className="right-icon"/>
                </div>
            </div>
        </>
    )
}

export default AlphaNavBar;