import React from 'react'
import { Route, Redirect } from "react-router-dom";

const AdminRoute = (props) => {

    const userDets =  localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {role: 'none'}
    
    const { component: Component, ...rest } = props;

    return (
    <Route 
        {...rest}
        render={props =>
            userDets.role === 'admin' || userDets.role === 'superadmin' ? (
                <Component {...props} />
        ) : (
            <Redirect to={{ 
                pathname: "/login", 
                state: { from: props.location } 
            }}/>
        )
        }
    />
    );
}

export default AdminRoute