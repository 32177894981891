import React from 'react'

import {Link} from 'react-router-dom'
import './forgotform.scss'
import Form from 'react-bootstrap/Form'

const ForgotAlert = () => {
    return (
        <>
            <div className="forgot-form">
            <Form id="forgotForm-alert">
            <div className="form-header">Check your inbox</div>
                
                <Form.Text className="text-muted"><br/>
                    <Link to="/login">Login</Link><br/>
                </Form.Text>
            </Form>
            </div>
        </>
    )
}

export default ForgotAlert;