import React from 'react'

import ResetPasswordForm from '../../components/resetpassword/resetform'

import Footer from '../../components/footer/footer'
import NavBar from '../../components/navbar/navbar'

const ResetPassword = () => {
    return (
        <>
            <NavBar/>
            <ResetPasswordForm/>
            <Footer></Footer>
        </>
    )
}

export default ResetPassword