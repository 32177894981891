import {useEffect, useState} from 'react'
import axios from 'axios'
import API_BASE from '../../apiBase'

export default function useTrendSearch (query, pageNumber){
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [trends, setTrends] = useState([])
    const [hasMore, setHasMore] = useState(false)

    useEffect(() => {
        setTrends([])
    }, [query])

    useEffect(() => {
        if(!query.trim()) return;
        setLoading(true)
        setError(false)
        let cancel
        axios({
            method: 'GET',
            url: `${API_BASE}/trends/search`,
            params: {q: query},
            cancelToken: new axios.CancelToken((c) => cancel = c)
        }).then(res => {
            setTrends( prevTrends => {
                return [...prevTrends, ...res.data.trends]
            })
            setHasMore(res.data.trends.length > 0)
            setLoading(false)
        }).catch(err => {
            if (axios.isCancel(err)) return
            setError(true)
        })
        return () => cancel()
    }, [query, pageNumber])

    return {loading, error, trends, hasMore}
}