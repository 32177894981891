import React from 'react'
import {Link} from 'react-router-dom'

const NotFound = () => {
    return (
        <div style={{textAlign: 'center', marginTop: '3em', fontSize: 22+'px'}}>
            <p>Error 404: Page not found</p>
            <p>Go to <Link to="/yoodict">Yoodict</Link></p>
            <p>Go to <Link to="/yootrends">Yootrends</Link></p>
        </div>
    )
}

export default NotFound