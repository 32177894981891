import React from 'react';
import {Link} from 'react-router-dom'

import './privacy.scss'

import Footer from '../../components/footer/footer';
import InfoBanner from '../../components/infobanner/infobanner';
import InfoContent from '../../components/infocontent/infocontent'
import BackButton from '../../icons/right-chevron (1).png'
import NavBar from '../../components/navbar/navbar'
import MetaDecorator from '../../MetaDecorator'

const PrivacyPolicyPage = () => {
    
    const renderFunction = (content) => {
        return (
            content
        )
    }

    const privacyArray =
    [
        {
            title: "Information Gathering and Usage",
            content: `When you submit a posting to urbanyooba.com we ask for information such as your email address
            and name. Urbanyooba.com uses collected information for the following general purposes: products
            and services provision, identification and authentication, services improvement, contact, and
            research.`
        },
        {
            title: "Cookies and Web Beacons",
            content: renderFunction(
                <div>
                    A cookie is a small amount of data, which often includes an anonymous unique identifier, which is
                    sent to your browser from a website’s computers and stored on your computer’s hard drive. Cookies
                    are required to use some urbanyooba.com services.<br/>
                    Urbanyooba.com and its ad management partners (“Ad Partners”) use cookies to record current
                    session information.<br/>
                    Our Ad Partners may also from time to time use web beacons (also known as Internet tags, pixel tags
                    and clear GIFs). These web beacons are provided by our Ad Partners and allow Ad Partners to obtain
                    information such as the IP address of the computer that downloaded the page on which the beacon
                    appears, the URL of the page on which the beacon appears, the time the page containing the beacon
                    was viewed, the type of browser used to view the page and the information in cookies set by the Ad
                    Partners. Web beacons enable our Ad Partners to recognize a unique cookie on your web browser,
                    which in turn enables us to learn which advertisements bring users to our Website.<br/>
                    The Company reserves the right to modify, suspend, or discontinue the Website for any reason, with or without notice.
                    The Website is provided “as is” and “as available”. You assume complete responsibility and risk for
                    your use of the Website. The Company does not warrant that (i) the Website will meet your
                    requirements, (ii) you will be satisfied with the Website, (iii) you will at all times be able to use the
                    Website, (iv) the Website will be without errors, (v) or that any errors will be corrected.<br/>
                    With both cookies and web beacon technology, the information that we collect and share is
                    anonymous and not personally identifiable. It does not contain your name, address, telephone
                    number, or email address.<br/>
                    You can opt-out of Google Analytics data collection with the Google Analytics Opt-out Browser Add-
                    on.
                </div>)
        },
        {
            title: "Data Storage",
            content: `Urbanyooba.com uses third party vendors and hosting partners to provide the necessary hardware,
            software, networking, storage, and related technology required to run the Yoodict and Yootrend
            platforms. Urbanyooba.com owns the code, databases, and all rights to the Yoodict and Yootrend
            applications.`
        },
        {
            title: "Security",
            content: renderFunction(
                <div>
                    We take precautions to ensure the security of your personal information. However, we cannot
                    guarantee that hackers or unauthorized personnel may gain access to your personal information
                    despite our efforts. You should note that in using the Urbanyooba service, your information will
                    travel through third party infrastructures which are not under our control.<br/>
                    We cannot protect, nor does this Privacy Policy apply to, any information that you transmit to other
                    users. You should never transmit personal or identifying information to other users.
                    <div><br/>
                        <div>Children</div>
                        The urbanyooba.com service is not intended for children under 13, and we do not knowingly collect
                        information from children under the age of 13.<br/>
                        Children aged 13 or older should not submit any personal information without the permission of
                        their parents or guardians. By using the urbanyooba.com service, you are representing that you are
                        at least 18, or that you are at least 13 years old and have your parents’ or guardians’ permission to
                        use the service.
                    </div>
                    <div><br/>
                        <div>Changes</div>
                        <div>
                            Urbanyooba.com may periodically update this policy. We may notify you about significant changes in
                            the way we treat personal information by placing a prominent notice on our site.
                        </div>
                    </div>
                </div>
            )
        },
        // {
        //     title: "Children",
        //     content: renderFunction(<div>The urbanyooba.com service is not intended for children under 13, and we do not knowingly collect
        //     information from children under the age of 13.<br/>
        //     Children aged 13 or older should not submit any personal information without the permission of
        //     their parents or guardians. By using the urbanyooba.com service, you are representing that you are
        //     at least 18, or that you are at least 13 years old and have your parents’ or guardians’ permission to
        //     use the service.</div>)
        // },
        // {
        //     title: "Changes",
        //     content: `Urbanyooba.com may periodically update this policy. We may notify you about significant changes in
        //     the way we treat personal information by placing a prominent notice on our site.`
        // },
        {
            title: "Questions",
            content: `Any questions about this Privacy Policy should be addressed to this address: info@urbanyooba.com
            `
        }

    ];

    const header = "PRIVACY POLICY"

    const dormantFunction = () => {
        return
    }
    return (
        <> 
            <MetaDecorator title="Privacy Policy" description="Urbanyooba Privacy Policy"/>
            <NavBar altUpperDisplay={dormantFunction} onSearch={dormantFunction} onClick={dormantFunction}/>
            <InfoBanner header={header}/>
            <div className="privacy">
            <div className="nav-back-pp">
                <Link to="/yoodict"><img src={BackButton} alt="back to yoodict" className="back-button-pp" />BACK TO DICTIONARY </Link>
            </div>

            <div className="privacy-intro">
    {`Urbanyooba Inc (“urbanyooba.com”, “we” or “us”) collects (a) the e-mail addresses of those who
    communicate with us via e-mail, (b) aggregate information concerning what pages users access or
    visit, (c) information volunteered by the user (such as survey information and/or site registrations)
    and (d) information related to your use of the site, including IP address, geographic location and
    date and time of your request. The information we collect is used to improve the content of our
    Website and the quality of our service, and is not shared with or sold to other organizations for
    commercial purposes, except to provide products or services you’ve requested, when we have your
    permission, or under the following circumstances:
    `}
    <ul>
        <li>
        It is necessary to share information in order to investigate, prevent, or take action regarding
        illegal activities, suspected fraud, situations involving potential threats to the physical safety
        of any person, violations of Terms of Service, or as otherwise required by law.
        </li>
        <li>
        We transfer information about you if urbanyooba.com is acquired by or merged with
        another company. In this event, urbanyooba.com will notify you before information about
        you is transferred and becomes subject to a different privacy policy.
        </li>
        <li>
        We provide such information to trusted businesses or persons for the sole purpose of
        processing personally identifying information on our behalf. When this is done, it is subject
        to agreements that oblige those parties to process such information only on our instructions
        and in compliance with this Privacy Policy and appropriate confidentiality and security
        measures.
        </li>
        <li>
        We provide such information to third parties who have entered into non-disclosure
        agreements with us.
        </li>
        <li>
        We provide such information to a company controlled by, or under common control with,
        urbanyooba.com for any purpose permitted by this Privacy Policy.
        </li>
    </ul>
    
            </div>
                {
                    privacyArray.map( el => <InfoContent key={ el.title } title={ el.title } content={ el.content } /> )
                }
        <div className="privacy-intro">
            Updated: December 2020
        </div>
            </div>
            <Footer/>
        </>
    )
}

export default PrivacyPolicyPage;